    import React from 'react';
import './App.css';
import Menu from "./components/menu/Menu";
import {observer} from "mobx-react";
import StoreGame from "./store/StoreGame";

import {
    cancelPerkMutation, connectExistedGame, remoteRequest, setTimeoutTokenRefresh, subscribeForSelfOnlineStatus,
} from "./common/utils";
import Game from "./components/Game";

import {
    BOARD_MODE__MOVES, FOCUS_LAYER__BADGES_BOARD,
    FOCUS_LAYER__GAME, FOCUS_LAYER__GAME_TIMES_POPUP, FOCUS_LAYER__HOW_TO_PLAY,
    FOCUS_LAYER__MENU, FOCUS_LAYER__MESSAGE_SELECTOR, FOCUS_LAYER__NMENU, FOCUS_LAYER__VIDEO_PLAYER, FUNCS_REGION,
    MENU_MODE__COLD_START, MENU_MODE__IN_GAME,
    USE_EMULATOR
} from "./common/const";
import firebase from "firebase/app";
import "firebase/functions";
import StoreFocus from "./focus/StoreFocus";
import SplashScreen from "./components/SplashScreen";
import StoreSounds from "./store/StoreSounds";
import PopupMessage, {showAlert, showExitConfirm} from "./components/popupmessage/PopupMessage";
import HowToPlay from "./components/how-to-play/HowToPlay";
import StoreApp from "./store/StoreApp";
import {FOCUS_LAYER_DEFAULT_FOCUS, MOVE_FOCUS_DIRECTION} from "./focus/StoreFocusBase";
import StoreNewWord from "./store/StoreNewWord";
import StoreHowToPlay from "./store/StoreHowToPlay";
import Loader from "./components/Loader";
import { version }  from '../package.json'
import i18n from "./common/i18n";
import LoaderBig from "./components/LoaderBig";
import StorePerkBlock from "./store/StorePerkBlock";
import StorePerkCapture from "./store/StorePerkCapture";
import StorePerkMutation from "./store/StorePerkMutation";
import StoreUser from "./store/StoreUser";
import BadgesBoard from "./components/badges/BadgesBoard";
import BadgePopupMessage from "./components/badges/BadgePopupMessage";
import StoreBadgeBoard from "./store/StoreBadgeBoard";
import VideoPlayer from "./components/player/VideoPlayer";
import MenuButton from "./components/MenuButton";
import StoreVideoPlayer from "./store/StoreVideoPlayer";
import VideoPlayerOpenBtn from "./components/player/VideoPlayerOpenBtn";
import MessageSelector from "./components/chat/MessageSelector";
import StoreChat from "./store/StoreChat";

import GameTimeLimitsPopupMessage from "./components/gametimelimitspopup/GameTimeLimitsPopupMessage";
    import StoreGameTimeLimitsPopup from "./store/StoreGameTimeLimitsPopup";

firebase.initializeApp({
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId
});


if(USE_EMULATOR) {
    firebase.app().functions(FUNCS_REGION).useFunctionsEmulator('http://localhost:5001');
}

console.log(
    `
       Version: ${version}
       Build: ${process.env.REACT_APP_TIMESTAMP}
       Env: ${process.env.REACT_APP_ENV}
       Emulator: ${USE_EMULATOR}     
      `);





const App = observer(class App extends React.Component {

    _keyBuffer = [];

    constructor(props) {
        super(props);

        //if (window.innerWidth > 1300) document.body.style.zoom = "150%";

        this.__updateZoom();

        window.addEventListener("resize", () => {
            this.__updateZoom();

        });

    }

    /**
     * функция пересчитывает zoom так чтобы игра была в размерах окна. Вызываетяс при старте и при window resize
     * @private
     */
    __updateZoom() {
        let kw = window.innerWidth / 1280,
            kh = window.innerHeight / 720;



        if(Math.abs(kw - kh) < 0.01) {
            // если пропорции экрана совпадают с 1280/720 - зумим под обрез
            //document.body.style.zoom = 100 * Math.min(kw, kh) + "%";
            document.documentElement.style.fontSize = Math.min(kw, kh)+"px";
            document.body.style.marginTop  = 0;
            document.body.style.marginLeft = 0;
        } else {
            // иначе - зумим с отступами по 5% от меньшей по пропорции стороны

            /*let sss = "("+window.innerWidth+","+window.innerHeight+")";
            alert(sss);*/
            if(kw < kh) {
                const scale = 1 * kw;
                document.documentElement.style.fontSize = scale + "px";
                document.body.style.marginTop  = (window.innerHeight - scale * 720)  / 2 + "px";
                document.body.style.marginLeft = 0;
            } else {
                const scale = 1 * kh;
                document.documentElement.style.fontSize = scale + "px";
                document.body.style.marginTop = 0;
                document.body.style.marginLeft = (window.innerWidth  - scale * 1280) / 2 + "px";
            }
        }
    }

    componentDidCatch(err) {
        if(process.env.REACT_APP_ENV === 'prod')
            StoreApp.stvAppConnector.sendError('window.onerror', err);


    }

    /**
     * Функция пишет буфер нажатий кнопок (пропуская стрелки, ок и бек) и в ней можно назначать действия на
     * определенную комбинацию клавишь
     * @param keyCode
     * @private
     */
    _updateKeyBuffer(keyCode) {
        let keys = StoreApp.stvAppConnector.parentAppData.keys;
        if(keyCode === keys.UP || keyCode === keys.RIGHT || keyCode === keys.DOWN || keyCode === keys.LEFT ||
            keyCode === keys.ENTER || keyCode === keys.RETURN || keyCode === keys.HID_BACK)
            return;


        // добавляем нажатую кнопку
        this._keyBuffer.push(keyCode);
        // убираем первую из буфера, чтобы всегда было не более 4х
        if(this._keyBuffer.length > 4)  this._keyBuffer.splice(0, 1);

        let str = this._keyBuffer.toString();

        // ..... НАЗНАЧЕННЫЕ КОМБИНАЦИИ .....

        // 4862 - алерт с дебаг-инфо
        if(str === [keys.NUMERIC_FOUR, keys.NUMERIC_EIGHT, keys.NUMERIC_SIX, keys.NUMERIC_TWO].toString() ||
            str === [keys.FOUR, keys.EIGHT, keys.SIX, keys.TWO].toString()) {//4862
            let data = {
                Version: version,
                Build: process.env.REACT_APP_TIMESTAMP,
                Env: process.env.REACT_APP_ENV,
                Emulator: USE_EMULATOR,
                Did: StoreApp.did,
                GameId: StoreGame.gameId,
                UserID: StoreUser.id,
                UserName: StoreUser.user ? StoreUser.user.name : ''

            };
            showAlert('<div class="debug">'+Object.keys(data).map(key => key+': '+data[key]+'<br/>').join('')+'</div>');
        } else if(str === [keys.NUMERIC_ZERO, keys.NUMERIC_ZERO, keys.NUMERIC_ZERO, keys.NUMERIC_ZERO].toString() ||
            str === [keys.ZERO, keys.ZERO, keys.ZERO, keys.ZERO].toString()) {


        } else if(str === [keys.NUMERIC_TWO, keys.NUMERIC_TWO, keys.NUMERIC_TWO, keys.NUMERIC_TWO].toString() ||
            str === [keys.TWO, keys.TWO, keys.TWO, keys.TWO].toString()) {
            StoreVideoPlayer.setVideoPlayerVisible(!StoreVideoPlayer.videoPlayerVisible);
        }
    }

    _keysListeners = (e) => {

        if(!StoreApp.clicksEnabled) return;

        if(e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.UP) {
            StoreFocus.moveFocus(MOVE_FOCUS_DIRECTION.UP);
        }
        else if(e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.RIGHT) {
            StoreFocus.moveFocus(MOVE_FOCUS_DIRECTION.RIGHT);
        }
        else if(e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.DOWN) {
            StoreFocus.moveFocus(MOVE_FOCUS_DIRECTION.DOWN);
        }
        else if(e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.LEFT) {
            StoreFocus.moveFocus(MOVE_FOCUS_DIRECTION.LEFT);
        }
        else if(e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.ENTER) {
            StoreFocus.click();
        }

        if( e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.RETURN ||
            e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.HID_BACK) {

            // сперва проверяем не устанавливаем ли мы свейчас перк
            if(StorePerkBlock.trying) {
                StorePerkBlock.clear();
                StoreGame.setBoardMode(BOARD_MODE__MOVES);
            } else if(StorePerkCapture.trying) {
                StorePerkCapture.clear();
                StoreGame.setBoardMode(BOARD_MODE__MOVES);
            } else if(StorePerkMutation.trying) {
                cancelPerkMutation();
                StoreGame.setBoardMode(BOARD_MODE__MOVES);
            }
            // идем дальше - если есть набранное слово
            else if(StoreFocus.currentFocusLayer === FOCUS_LAYER__GAME) {
                if(StoreNewWord.word.length > 0)
                    StoreNewWord.removeLastLetter();
                else {
                    StoreApp.setMenuMode(MENU_MODE__IN_GAME);
                    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MENU);
                }
            } else if(StoreFocus.currentFocusLayer === FOCUS_LAYER__MENU) {
                showExitConfirm();
            } else if(StoreFocus.currentFocusLayer === FOCUS_LAYER__NMENU) {
                StoreApp.setMenuMode();
                setTimeout(() => StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MENU, FOCUS_LAYER_DEFAULT_FOCUS.SAVED), 100);
            } else if(StoreFocus.currentFocusLayer === FOCUS_LAYER__HOW_TO_PLAY) {
                StoreHowToPlay.hide();
                StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MENU);
            } else if(StoreFocus.currentFocusLayer === FOCUS_LAYER__BADGES_BOARD) {
                StoreBadgeBoard.hide();
                StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
            } else if(StoreFocus.currentFocusLayer === FOCUS_LAYER__VIDEO_PLAYER) {
                StoreVideoPlayer.setControlsVisible(false);
                setTimeout(() => StoreFocus.setCurrentFocusLayer(null), 300);
            } else if(StoreFocus.currentFocusLayer === FOCUS_LAYER__GAME_TIMES_POPUP) {
                StoreGameTimeLimitsPopup.hide();
                StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
            }

        }

        this._updateKeyBuffer(e.keyCode);
    }

    __coldStart() {
        StoreGame.setGame(null);
        StoreApp.setMenuMode(MENU_MODE__COLD_START);
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MENU);
    }

    __startWithGame(gameId) {
        connectExistedGame(gameId);
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
    }

    componentDidMount() {




        document.addEventListener('keydown', this._keysListeners, true);
        StoreApp.stvAppConnector.send('loaded');


        // ждем 2 секунды прежде чем идти на бек за юзером. На случае запуска НЕ из лендинга, а по ссылке
        let startTimer = setTimeout(() => {
            StoreApp.loadPlayerFromFirebase(StoreApp).then(result => {
                subscribeForSelfOnlineStatus();
                setTimeoutTokenRefresh();
                if(result)  this.__coldStart();
                else    showAlert(i18n.gameErrors.authError);
            })
        }, 2000);

        StoreApp.stvAppConnector.on('init', data => {
            // удаляем таймаут - это запуск из лендинга
            clearTimeout(startTimer);
            StoreApp.stvAppConnector.setParentAppData(data);

            StoreApp.loadPlayerFromFirebase(StoreApp).then(result => {
                subscribeForSelfOnlineStatus();
                setTimeoutTokenRefresh();
                if(result) {
                    if(StoreApp.stvAppConnector.startWithGame)  this.__startWithGame(StoreApp.stvAppConnector.startWithGame);
                    else this.__coldStart();
                }
                else    showAlert(i18n.gameErrors.authError);
            })

            StoreApp.stvAppConnector.send('start');
            StoreApp.stvAppConnector.send('getTvChannels');

        });

        StoreApp.stvAppConnector.on('sendTvChannels', data => {
            console.log("sendTvChannels", data);
            StoreVideoPlayer.setChannels(data);

        });

        StoreApp.stvAppConnector.on('sendStreamData', data => {
            StoreVideoPlayer.setNewStreamFromApp(data.stream);
        });

        ////////////////

    }

    render() {
        this.__updateZoom();
        //console.log('render');
        return (
            <div id="wrapper" className="App">
                {StoreApp.popupMessage && <PopupMessage/>}
                {StoreApp.badgePopupMessage && <BadgePopupMessage/>}
                {StoreGameTimeLimitsPopup.visible && <GameTimeLimitsPopupMessage/>}
                {StoreHowToPlay.visible && <HowToPlay/>}
                {StoreBadgeBoard.visible && <BadgesBoard/>}
                <SplashScreen/>
                {StoreApp.loaderBigVisible && <LoaderBig/>}
                <Menu lang={StoreApp.dictionarySetting}/>
                {StoreGame.game && <Game/>}
                {StoreApp.loaderVisible && <Loader/>}

                {StoreChat.messageSelectorVisible && <MessageSelector/>}

                {StoreVideoPlayer.channels.length > 0 && StoreVideoPlayer.videoPlayerVisible && <VideoPlayer/>}
                {StoreVideoPlayer.videoPlayerVisible && StoreVideoPlayer.controlsVisible && <div className={"video-player-dark-bg"}></div>}
                {StoreVideoPlayer.channels.length > 0 &&!StoreVideoPlayer.videoPlayerVisible && <VideoPlayerOpenBtn focusStore={StoreFocus}  focusLayers={[FOCUS_LAYER__GAME, FOCUS_LAYER__MENU, FOCUS_LAYER__NMENU]}/>}
            </div>
        );
    }
})

export default App;
