import {extendObservable} from "mobx";
import {GAME_MODE} from "../common/const";


class StoreGameTimeLimitsPopup {

    constructor() {
        extendObservable(this, {
            visible: false,
        });

        this.gameMode = GAME_MODE.LOCAL;
        this.continueCallback = null;
    }


    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    setGameMode(gameMode) {
        this.gameMode = gameMode;
    }

    askUserTimeLimitsAndContinue(gameMode, continueCallback) {
        this.gameMode = gameMode;
        this.continueCallback = continueCallback;
        this.visible = true;
    }



}

export default new StoreGameTimeLimitsPopup();
