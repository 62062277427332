import {extendObservable, reaction} from "mobx";

import {BOARD_MODE__MOVES, PERK__BLOCK} from "../common/const";
import {BOARD_MODE__PERK} from "../common/const";
import * as utils from "../common/utils";
import StoreGame from './StoreGame'
import StoreApp from "./StoreApp";
import {getRightFieldI, getRightPlayerI} from "../common/utils";

class StorePerkBlock {

    constructor() {
        extendObservable(this, {
            trying: false,
            fieldI: null
        });

        this.needField = reaction(
            () => this.trying,
            trying => {
                if(trying)
                    StoreGame.setBoardMode(BOARD_MODE__PERK);
                else {
                    StoreGame.setBoardMode(BOARD_MODE__MOVES);
                }
            }
        );

        this.needSendPerk = reaction(
            () => this.fieldI,
            field => {
                if(this.fieldI !== null) {
                    StoreApp.setLoaderVisible(true);
                    utils.usePerk(StoreGame.gameId, getRightPlayerI(StoreGame.currentPlayerI), {
                        id: PERK__BLOCK,
                        field: getRightFieldI(this.fieldI)
                    }, this);
                }
            }
        );
    }

    toggleTrying() {
        let temp = !this.trying;
        StoreGame.clearAllPerks();
        this.trying = temp;
    }

    setFieldI(f) {
        this.fieldI = f;
    }


    clear() {
        this.trying = false;
        this.fieldI = null;
    }
}

export default new StorePerkBlock();