import React from "react";
import StoreGame from "../../store/StoreGame";
import {observer} from "mobx-react";
import "./index.css";
import {
    FOCUS_LAYER__MENU,
    MENU_MODE__COLD_START,
    MENU_MODE__HIDE,
    MENU_MODE__IN_GAME, MENU_MODE__NETWORK
} from "../../common/const";
import ItemLocalGame from "./ItemLocalGame";
import ItemRules from "./ItemRules";
import ItemExit from "./ItemExit";
import ItemDictionary from "./ItemDictionary";
import MenuCloseBtn from "./MenuCloseBtn";
import StoreApp from "../../store/StoreApp";
import NMenu from "./nmenu/NMenu";
import ItemNetworkGames from "./ItemNetwrokGames";
import StoreFocus from "../../focus/StoreFocus";
import UserBlock from "./UserBlock";
import i18n from "../../common/i18n";

const Menu = observer(class Menu extends React.Component {

    render() {

        return (
            <div className="menu">
                {StoreApp.menuMode === MENU_MODE__IN_GAME && <div className="smog"></div>}
                {StoreApp.menuMode === MENU_MODE__COLD_START && <div className="right-img"></div>}
                {StoreApp.menuMode === MENU_MODE__NETWORK && <NMenu/>}
                <div className={"body enable-hardware-acceleration" + (StoreApp.menuMode !== MENU_MODE__HIDE ? " show" : "")}>
                    {StoreApp.menuMode === MENU_MODE__IN_GAME && <MenuCloseBtn focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__MENU]}/>}
                    {StoreApp.menuMode === MENU_MODE__NETWORK && <UserBlock/>}
                    <ul>
                        <ItemLocalGame lang={StoreApp.dictionarySetting} menuMode={StoreApp.menuMode} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__MENU]}/>
                        <ItemNetworkGames lang={StoreApp.dictionarySetting} menuMode={StoreApp.menuMode} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__MENU]}/>
                        <ItemRules lang={StoreApp.dictionarySetting} menuMode={StoreApp.menuMode} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__MENU]}/>
                        <ItemDictionary lang={StoreApp.dictionarySetting} menuMode={StoreApp.menuMode} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__MENU]}/>
                        <ItemExit lang={StoreApp.dictionarySetting} menuMode={StoreApp.menuMode} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__MENU]}/>
                    </ul>
                    <div className="support">{i18n.mainMenu.support} t.me/poleslov</div>
                    <div className="version">v{StoreApp.version}</div>
                    {/*<div className="fake"><a href="#" onClick={createFriendNetworkGame}>создать</a> &nbsp;&nbsp;&nbsp; <a href="#" onClick={acceptToFriendNetworkGame}>подключиться</a></div>*/}
                </div>

            </div>
        );
    }
})

export default Menu;
