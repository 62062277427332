// ES6 module syntax
import LocalizedStrings from 'react-localization';
import {
    DICTIONARY, GAME_MODE, GAME_TIME_LIMITS_TYPES,
    PERK__BLOCK,
    PERK__CAPTURE, PERK__MORE_TIME,
    PERK__MUTATION,
    RESPONSE__ERROR_CODES
} from "../common/const";

let i18n = new LocalizedStrings({
    "ru": {
        "playerName": ["Полислов", "Словоглот"],
        "responseErrors": {
            [RESPONSE__ERROR_CODES.UNKNOWN]: "Неизвестная ошибка. Попробуйте переоткрыть игру",
            [RESPONSE__ERROR_CODES.GAME_NOT_CREATED]: "Ошибка создания игры",
            [RESPONSE__ERROR_CODES.WRONG_GAME]: "Неверный код игры",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_TURN]: "Сейчас не ваш ход",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_LETTERS]: "Неверные буквы в слове",
            [RESPONSE__ERROR_CODES.GAME_ERROR__USED_WORD]: "Такое слово уже было :(",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_WORD]: "Этого слова нет в словаре :(",
            [RESPONSE__ERROR_CODES.GAME_ERROR__PERK_VALIDATION]: "Ошибка использования перка!",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_NOT_CREATED]: "Ошибка, сетевая игра не была создана. Попробуйте позже.",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_NOT_ACCEPTED_OR_CREATED]: "Ошибка сетевой игры. Попробуйте еще раз.",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_WRONG_CODE]: "Ошибка, игры с таким кодом не существует. Вы ошиблись при наборе или ваш друг отменил игру"
        },

        "timeLimits": {
            titles: {
                [GAME_MODE.LOCAL]: 'Игра вдвоем на одном ТВ',
                [GAME_MODE.NETWORK__RANDOM]: 'Случайный соперник',
                [GAME_MODE.NETWORK__WITH_FRIEND]: 'Игра с другом',
            },
            subtitles: {
                default: "Выберите учет времени для игры"
            },
            networkGameLabel: {
                [GAME_TIME_LIMITS_TYPES.MOVE]: ", {minutes} мин. на ход.",
                [GAME_TIME_LIMITS_TYPES.GAME]: ", {minutes} мин. на игру.",
                [GAME_TIME_LIMITS_TYPES.NONE]: ".",
            },
            variants: {
                [GAME_TIME_LIMITS_TYPES.MOVE]: "{minutes} мин. на ХОД",
                [GAME_TIME_LIMITS_TYPES.GAME]: "{minutes} мин. на ИГРУ",
                [GAME_TIME_LIMITS_TYPES.NONE]: "Без учета времени",
            }
        },

        "gameErrors": {
            gameNotExisted: "Такой игры не существует. Начните новую.",
            authError: "Ошибка авторизации. Попробуйте позже",
        },

        "mainMenu": {
            "newGame": "БЫСТРАЯ ИГРА",
            "continue": "ПРОДОЛЖИТЬ",
            "networkGames": "СЕТЕВАЯ ИГРА",
            "rules": "ПРАВИЛА",
            "dictionary": "СЛОВАРЬ",
            "dictionaryRu": "RU",
            "dictionaryUa": "UA",
            "dictionaryEn": "EN",
            "exit": "ВЫХОД",
            "support": "Обратная связь: "
        },

        "howToPlay": {
            "page0": "Стратегическая игра в слова для двоих.<br /><br />Собирайте слова из доступных на поле букв и продвигайтесь вперед, пока не займете одну из <b>Базовых букв</b> соперника.",
            "page1": {
                "title": "Правила игры на поле слов",
                "text": "Собирать слова нужно из доступных вам букв. В слове можно использовать <b>ваши буквы</b> и <b>соседние</b> к ним. Занимайте свободные буквы для продвижения вперед. Отбирайте буквы соперника, чтобы ухудшить его положение."
            },
            "page2": "За каждое слово вы получаете <b>сумму очков</b> всех использованных в нем букв.<br/><br/>После каждого использования буквы ее ценность повышается на <b>1 очко</b>.<br/><br/>После освобождения цена буквы становится <b>равной 1</b>.<br/><br/>Выигрывает тот, кто первым займет <b>Базовую букву</b> соперника (левая колонка — Базовые буквы <span class=\"c0\">Полислова</span>, правая — Базовые буквы <span class=\"c1\">Словоглота</span>).",
            "page3": "Этого достаточно, чтобы играть в <b>ПОЛЕ&nbsp;СЛОВ</b>. А вот чтобы красиво побеждать&nbsp;— нужно знать кое-какие тонкости!",
            "page4": [
                "<b>Очки</b> в игре нужны для покупки <b>бонусов</b>.",
                "Бонус <b>«Защита»</b> вводит запрет на использование выбранной буквы на 2 хода (один ваш и один соперника).",
                "Используйте бонус <b>«Мутация»</b> для замены любой буквы поля на другую. Так вы сможете упростить жизнь себе или наоборот — усложнить сопернику."

            ],
            "page5": [
                "С бонусом <b>«Атака»</b> вы можете занять недоступную ранее букву на поле. После атаки свободная буква станет вашей, а буква соперника — свободной.",
                "Бонус <b>«+30 секунд»</b> добавит вам времени, когда оно почти на исходе.",
                "Вы можете использовать бонусы на <b>любых</b> буквах, <b>кроме Базовых</b> букв соперника.",
                "Каждый бонус имеет цену, которая повышается <b>в 2 раза</b> после использования."

            ],
            page6: {
                title: 'СЛОВАРЬ',
                text: 'В игре использован ',
                dic: {
                    [DICTIONARY.UA]: '"Великий електронний словник української мови" (ВЕСУМ), <b>github.com/brown-uk/dict_uk</b>',
                    [DICTIONARY.RU]: '- использованный ресурс <b>dict.ruslang.ru/freq.php</b><br/>' +
                    '- первоисточник: О. Н. Ляшевская, С. А. Шаров, Частотный словарь современного русского языка (на материалах Национального корпуса русского языка). М.: Азбуковник, 2009.<br/><br/>' +
                    '© О. Н. Ляшевская, С. А. Шаров, 2009-2011.<br/>' +
                    '© Национальный корпус русского языка, 2009-2011.<br/>' +
                    '© Издательство "Азбуковник" (печатная версия словаря), 2009.<br/>' +
                    '(с) А. В. Санников (создание сайта dict.ruslang.ru, техническая поддержка), 2008–2011.',
                    [DICTIONARY.EN]: 'В словаре есть все самые употребляемые слова английского языка, и даже больше… ' +
                    'в нем более 200 тысяч слов! В игре вы можете использовать существительные, глаголы, ' +
                    'прилагательные, междометия и т. д. Однако есть одно ограничение — вы не можете использовать ' +
                    'существительные во множественном числе (legs вместо leg), за исключением случаев, когда ' +
                    'существительное обычно используется вместе  (pants вместо pant, barracks вместо barrack). ' +
                    'Есть некоторые абстрактные собирательные существительные, такие как earnings или statistics - ' +
                    'в этом случае вы также можете использовать их свободно.'
                }

            }
        },

        "buttonHint": {
            [PERK__CAPTURE]: {
                "title": "Захват",
                "text": "Захватите свободную букву или отберите букву соперника"
            },
            [PERK__MUTATION]: {
                "title": "Мутация",
                "text": "Не хватает буквы? Замените букву на любую другую букву алфавита"
            },
            [PERK__BLOCK]: {
                "title": "Блок",
                "text": "Заблокированная буква будет недоступна для использования в слове на один ход соперника и один ваш"
            },
            [PERK__MORE_TIME]: {
                "title": "+30 секунд",
                "text": "К вашему времени будет добавлено 30 секунд"
            },
            "surrender": {
                "title": "Сдаться",
                "text": "Закончились слова - признайте поражение"
            },
            "send-message": {
                "title": "Чат",
                "text": "Отправьте сообщение сопернику"
            }
        },

        "gameJustStartedHint": "Вперед! Выиграет тот, кто первым<br>захватит Базовую букву соперника",

        "chat_messages": [
            "Привет!",
            "Да ладно...",
            "Ты серъезно?",
            "Ну и буквы...",
            "Что это за слово?",
            "Тащи!",
            "Как тебе такое, Илон?",
            "Ну держись!",
            "Ходи давай!",
            "Ну долго еще?..",
            "Соберись, тряпка :)",
            "Похоже это конец :(",
            "Та я поддаюсь :)",
            "Смотри, что сейчас будет!",
            "Мощно!"
        ],

        "exitConfirm": {
            "message": "Вы действительно хотите выйти из игры?",
            "exit": "ВЫЙТИ",
            "cancel": "ОТМЕНА"
        },

        "dictionaryConfirm": {
            "message": "Текущая игра будет сброшена, создастся новая с <b style=\"color: #0FB986\">{lang}</b> словарем и алфавитом. Продолжить?",
            [DICTIONARY.RU]: "русским",
            [DICTIONARY.UA]: "украинским",
            [DICTIONARY.EN]: "английским",
            "yes": "ДА",
            "no": "ОТМЕНА"
        },

        "changeDictionaryAlert": {
            "message": "Теперь все новые игры вы будете создавать с <b style=\"color: #0FB986\">{lang}</b> словарем и алфавитом",
            [DICTIONARY.RU]: "русским",
            [DICTIONARY.UA]: "украинским",
            [DICTIONARY.EN]: "английским",
        },

        "ok": "OK",
        "cancel": "ОТМЕНА",
        "more": "ЕЩЕ",

        "alertConnectionError": "Ошибка сети. Попробуйте еще раз.",
        "alertGameCrateError": "Ошибка создания новой игры. Попробуйте еще раз.",

        "networkChallenge": {
            "showCode": "Передайте этот код другу и дождитесь его подключения в игру. Время на подключение - 5 минут <div class='code'>{code}</div>",
            "waitRandomOpponent": "Идет поиск случайного соперника для игры с <b style=\"color: #0FB986\">{dictionary}</b> словарем, ожидайте...",
            "expiredMessage": "К сожалению, отведенное на подключение к игре время истекло, игра была закрыта",
            "enterCode": "Введите код игры, который вам передал соперник",
            "rejected": "Игра была закрыта",
            "cancel": "ОТМЕНА",
            [DICTIONARY.RU]: "русским",
            [DICTIONARY.UA]: "украинским",
            [DICTIONARY.EN]: "английским",
        },

        "networkButtonsDesc": {
            "withFriend": 'Играть с<br/>другом',
            "connect": 'Подключиться <br/>в игру',
            "random": 'Случайный соперник'
        },

        "networkGamesTitle": "Недавние игры",
        "networkGamesText": "Первый ход в сетевой игре&nbsp;&mdash; на скорость. Успеете первым&nbsp;&mdash; получите стратегическое преимущество.<br><br>Не успели походить – победу празднует соперник.<br><br>Список ваших неоконченных сетевых игр будет доступен здесь, вместо этой подсказки.",

        "networkGameDesc": {
            "against": "Против {user}",
            "yourTurn": " <b class='c0'>Ваш ход</b>",
            "timeLeft": " Еще {text}",
            "youLose": "Вы проиграли {user} :(",
            "youWin": "Вы выиграли у {user}!",
        },

        "playerMoves": "Ходит",
        "playerWins": "Победил",
        "your": "Ваш",
        "move": "ход",
        "you": "Вы",
        "win": "победили!",
        "lose": "проиграли :(",
        "bothMoves": "Первый ход - на скорость!",

        "surrenderConfirm": "Признать поражение?",

        "hours": {
            "1": "час",
            "2": "часа",
            "5": "часов"
        },
        "minutes": {
            "1": "минута",
            "2": "минуты",
            "5": "минут"
        },

        "badges": {
            "pervoe_slovo": {title: "Первое слово", description: "Первое созданное в игре слово"},
            "pervaya_pobeda": {title: "Первая победа", description: "Первая победа в игре"},
            "sprinter": {title: "Спринтер", description: "Первым ходом в игре занял весь второй ряд"},
            "znatok": {title: "Знаток", description: "Слово на 8 и более букв"},
            "umnik": {title: "Умник", description: "Слово на 10 и более букв"},
            "genii": {title: "Гений", description: "Слово на 15 и более букв"},
            "combo": {title: "Комбо", description: "Использовал в слове 5 и более букв соперника"},
            "super_combo": {title: "Супер Комбо", description: "Использовал в слове 7 и более букв соперника"},
            "bez_shansov": {title: "Без шансов", description: "Отобрал все буквы соперника"},
            "urojai": {title: "Урожай", description: "Набрал за ход 50 и более очков"},
            "super_urojai": {title: "Супер Урожай", description: "Набрал за ход 100 и более очков"},
            "skriaga": {title: "Скряга", description: "Окончил игру с более чем 200 очками"},
            "zolotoj_schet": {title: "Золотой счет", description: "Окончил игру с более чем 500 очками"},
            "marafonets": {title: "Марафонец", description: "Составил за игру 30 и более слов"},
            "bronzovaya_pobeda": {title: "Бронзовая победа", description: "Победил заняв 3 базовых буквы соперника"},
            "serebryanaya_pobeda": {title: "Серебряная победа", description: "Победил заняв 4 базовых буквы соперника"},
            "zolotaya_pobeda": {title: "Золотая победа", description: "Победил заняв 5 базовых буквы соперника"},
            "samurai": {title: "Самурай", description: "Победил без использования бонусов"},
            "strateg": {title: "Стратег", description: "Победил с меньшим количеством букв, чем у соперника"},
            "desperado": {title: "Десперадо", description: "Победил с открытой своей базовой буквой"},
            "chempion": {title: "Чемпион", description: "Победил 5 раз подряд"},
            "triumfator": {title: "Триумфатор", description: "Победил 10 раз подряд"},
        },

        "badges_popup_new": {
            title: "У вас новое достижение!",
            text: "<span>\"{title}\"</span> - {description}",
        },

        "badges_popup_more": {
            title: "+1 \"{title}\"",
            text: "{description}",
        },

        "nmenu_badges": {
            title: "Достижения",
            value: "{exists} из {total}"
        },
        "badges_exists_from_total": "открыто {exists} из {total}",

    },


    "uk": {
        "playerName": {"0": "Поліслов", "1": "Словоглот"},
        "responseErrors": {
            [RESPONSE__ERROR_CODES.UNKNOWN]: "Невідома помилка. Спробуйте перевідкрити гру",
            [RESPONSE__ERROR_CODES.GAME_NOT_CREATED]: "Помилка створення гри",
            [RESPONSE__ERROR_CODES.WRONG_GAME]: "Невірний код гри",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_TURN]: "Зараз не ваш хід",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_LETTERS]: "Невірні літери у слові",
            [RESPONSE__ERROR_CODES.GAME_ERROR__USED_WORD]: "Таке слово вже було :(",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_WORD]: "Цього слова немає в словнику :(",
            [RESPONSE__ERROR_CODES.GAME_ERROR__PERK_VALIDATION]: "Помилка використання бонусу",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_NOT_CREATED]: "Помилка, гра не була створена. Спробуйте пізніше.",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_NOT_ACCEPTED_OR_CREATED]: "Помилка гри по мережі. Спробуйте ще раз.",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_WRONG_CODE]: "Помилка, гри з таким кодом не існує. Ви помилились при наборі або ваш друг видалив гру."
        },

        "timeLimits": {
            titles: {
                [GAME_MODE.LOCAL]: 'Гра вдвох на однму ТВ',
                [GAME_MODE.NETWORK__RANDOM]: 'Випадковий суперник',
                [GAME_MODE.NETWORK__WITH_FRIEND]: 'Гра з другом',
            },
            subtitles: {
                default: "Оберіть обмеження часу для гри"
            },
            networkGameLabel: {
                [GAME_TIME_LIMITS_TYPES.MOVE]: ", {minutes} хв. на хід.",
                [GAME_TIME_LIMITS_TYPES.GAME]: ", {minutes} хв. на гру.",
                [GAME_TIME_LIMITS_TYPES.NONE]: ".",
            },
            variants: {
                [GAME_TIME_LIMITS_TYPES.MOVE]: "{minutes} хв. на ХІД",
                [GAME_TIME_LIMITS_TYPES.GAME]: "{minutes} хв. на ГРУ",
                [GAME_TIME_LIMITS_TYPES.NONE]: "Без обмеження часу",
            }
        },

        "gameErrors": {
            gameNotExisted: "Такої гри не існує. Почніть нову.",
            authError: "Помилка авторизації. Спробуйте пізніше",
        },

        "mainMenu": {
            "newGame": "ШВИДКА ГРА",
            "continue": "ПРОДОВЖИТИ",
            "networkGames": "ГРА ПО МЕРЕЖІ",
            "rules": "ПРАВИЛА",
            "dictionary": "СЛОВНИК",
            "dictionaryRu": "RU",
            "dictionaryUa": "UA",
            "exit": "ВИХІД",
            "support": "Зворотній звя'зок: "
        },

        "howToPlay": {
            "page0": "Стратегічна гра для двох.<br /><br />Збирайте слова з букв, що доступні на полі, та просувайтесь вперед, поки не займете одну з <b>Базових букв</b> супротивника.",
            "page1": {
                "title": "Правила гри",
                "text": "Збирати слова потрібно з доступних вам літер. У слові можна використовувати <b>ваші літери</b> та <b>сусідні</b> до них. Займайте вільні літери для просування вперед. Відбирайте літери суперника, щоб погіршити його становище."
            },
            "page2": "За кожне слово ви отримуєте <b>суму очок</b> усіх використаних у ньому літер. <br/> <br/> Після кожного використання літери її цінність підвищується на <b>1 очко</b>. <br/> <br/> Після звільнення ціна літери стає рівною <b>1</b>. <br/> <br/> Виграє той, хто першим займе <b> Базову літеру </b> суперника (ліва колонка — Базові літери <span class=\"c0\"> Поліслова </span>, права — Базові літери <span class=\"c1\">Словоглота </span>).",
            "page3": "Цього досить, щоб грати в <b>ПОЛЕ&nbsp;СЛІВ</b>. А ось щоб красиво перемагати&nbsp;— потрібно знати деякі тонкощі!",
            "page4": [
                "<b>Очки</b> в грі потрібні для покупки <b> бонусів </b>.",
                "Бонус <b>«Захист»</b> вводить заборону на використання вибраної літери на 2 ходи (один ваш та один суперника).",
                "Використовуйте бонус <b>«Мутація»</b> для заміни будь-якої літери поля на іншу. Так ви зможете спростити життя собі або навпаки — ускладнити супернику."

            ],
            "page5": [
                "З бонусом <b>«Атака»</b> ви можете зайняти недоступну раніше літеру на полі. Після атаки вільна літера стане вашою, а літера суперника — вільною.",
                "Бонус <b>«+30 секунд»</b> додасть вам часу, коли він майже вийшов.",
                "Ви можете використовувати бонуси на <b> будь-яких </b> літерах, <b> крім Базових </b> літер суперника.",
                "Кожен бонус має ціну, яка підвищується <b> удвічі </b> після використання."
            ],
            page6: {
                title: 'СЛОВНИК',
                text: 'У грі використаний ',
                dic: {
                    [DICTIONARY.UA]: '"Великий електронний словник української мови" (ВЕСУМ), <b>github.com/brown-uk/dict_uk</b>',
                    [DICTIONARY.RU]: '- использованный ресурс <b>dict.ruslang.ru/freq.php</b><br/>' +
                    '- первоисточник: О. Н. Ляшевская, С. А. Шаров, Частотный словарь современного русского языка (на материалах Национального корпуса русского языка). М.: Азбуковник, 2009.<br/><br/>' +
                    '© О. Н. Ляшевская, С. А. Шаров, 2009-2011.<br/>' +
                    '© Национальный корпус русского языка, 2009-2011.<br/>' +
                    '© Издательство "Азбуковник" (печатная версия словаря), 2009.<br/>' +
                    '(с) А. В. Санников (создание сайта dict.ruslang.ru, техническая поддержка), 2008–2011.',
                    [DICTIONARY.EN]: 'У словнику є всі найуживаніші слова англійської мови, і навіть більше… він ' +
                    'набрав понад 200 тисяч слів! У грі ви можете використовувати іменники, дієслова, прикметники, ' +
                    'вставні слова тощо. Однак є одне обмеження — ви не можете використовувати іменники у множині ' +
                    '(legs замість leg)), за винятком випадків, коли іменник зазвичай використовується разом (pants ' +
                    'замість pant, barracks замість barrack) . Існують деякі абстрактні збірні іменники, такі як ' +
                    'earnings чи statistics - у цьому випадку ви також можете використовувати їх вільно.'
                }

            }
        },

        "buttonHint": {
            [PERK__CAPTURE]: {
                "title": "Захоплення",
                "text": "Захопіть вільну літеру або відберіть літеру суперника"
            },
            [PERK__MUTATION]: {
                "title": "Мутація",
                "text": "Не вистачає літери? Замініть літеру на будь-яку іншу літеру алфавіту"
            },
            [PERK__BLOCK]: {
                "title": "Блок",
                "text": "Заблокована літера буде недоступна для використання в словах на один ваш хід та один хід суперника"
            },
            [PERK__MORE_TIME]: {
                "title": "+30 секунд",
                "text": "До вашого часу буде додано 30 секунд"
            },
            "surrender": {
                "title": "Здатися",
                "text": "Скінчились слова - час визнати поразку!"
            },
            "send-message": {
                "title": "Чат",
                "text": "Відправте повідомлення супернику"
            }
        },

        "gameJustStartedHint": "Уперед! Виграє той, хто першим<br>захопить Базову літеру суперника",

        "chat_messages": [
            "Привіт!",
            "Та ладно...",
            "Ти серйозно?",
            "Ох і літери...",
            "Що це за слово?",
            "Тягни!",
            "Як тобі таке, Ілон?",
            "Тримайся!",
            "Давай ходи!",
            "Ну ще довго?..",
            "Зберись, ганчірка :)",
            "Схоже це кінець :(",
            "Та я піддаюся :)",
            "Дивись, що зараз буде!",
            "Мощно!"
        ],

        "exitConfirm": {
            "message": "Ви дійсно бажаєте вийти з гри?",
            "exit": "ВИЙТИ",
            "cancel": "СКАСУВАТИ"
        },

        "dictionaryConfirm": {
            "message": "Поточна гра буде завершена, створиться нова з <b style=\"color: #0FB986\">{lang}</b> словником та абеткою. Продовжити?",
            [DICTIONARY.RU]: "російським",
            [DICTIONARY.UA]: "українським",
            [DICTIONARY.UA]: "англійським",
            "yes": "ТАК",
            "no": "СКАСУВАТИ"
        },

        "changeDictionaryAlert": {
            "message": "Тепер всі нові ігри ви будете створювати з <b style=\"color: #0FB986\">{lang}</b> словником і абеткою",
            [DICTIONARY.RU]: "російським",
            [DICTIONARY.UA]: "українським",
            [DICTIONARY.EN]: "англійським",
        },

        "ok": "OK",
        "cancel": "СКАСУВАТИ",
        "more": "ЩЕ",

        "alertConnectionError": "Помилка мережі. Спробуйте ще раз.",
        "alertGameCrateError": "Помилка створення нової гри. Спробуйте ще раз.",

        "networkChallenge": {
            "showCode": "Передайте другу цей код і дочекайтесь його підключення в гру. Час на підключення - 5 хвилин <div class='code'>{code}</div>",
            "waitRandomOpponent": "Іде пошук випадкового суперника для гри з <b style=\"color: #0FB986\">{dictionary}</b> словником, зачекайте...",
            "expiredMessage": "Нажаль, відведений на підключення до гри час сплив, гра була закрита",
            "enterCode": "Введіть код гри, який вам передав суперник",
            "rejected": "Гра була закрита",
            "cancel": "ВІДМОВА",
            [DICTIONARY.RU]: "російським",
            [DICTIONARY.UA]: "українським",
            [DICTIONARY.EN]: "англійським",
        },

        "networkButtonsDesc": {
            "withFriend": 'Грати з<br/>другом',
            "connect": 'Приєднатися <br/>до гри',
            "random": 'Випадковий суперник'
        },

        "networkGamesTitle": "Останні ігри",
        "networkGamesText": "Перший хід у грі по мережі - на швидкість. Встигли першим&nbsp;&mdash; отримали стратегічну перевагу<br><br>Не встигли зробити хід&nbsp;&mdash; перемогу святкує суперник.<br><br>Список ваш незакінчених ігор по мережі буде доступний тут, замість цієї підказки.",

        "networkGameDesc": {
            "against": "Проти {user}",
            "yourTurn": " <b class='c0'>Ваш хід</b>",
            "timeLeft": " Ще {text}",
            "youLose": "Ви програли {user} :(",
            "youWin": "Ви виграли у {user}!",
        },

        "playerMoves": "Ходить",
        "playerWins": "Переміг",
        "your": "Ваш",
        "move": "хід",
        "you": "Ви",
        "win": "перемогли!",
        "lose": "програли :(",
        "bothMoves": "Перший хід - на швидкість!",

        "surrenderConfirm": "Визнати поразку?",

        "hours": {
            "1": "година",
            "2": "години",
            "5": "годин"
        },
        "minutes": {
            "1": "хвилина",
            "2": "хвилини",
            "5": "хвилин"
        },

        "badges": {
            "pervoe_slovo": {title: "Перше слово", description: "Перше, створене у грі, слово"},
            "pervaya_pobeda": {title: "Перша перемога", description: "Перша перемога у грі"},
            "sprinter": {title: "Спрінтер", description: "Першим ходом у грі зайнято увесь другий ряд"},
            "znatok": {title: "Знаток", description: "Слово на 8 і більше літер"},
            "umnik": {title: "Розумник", description: "Слово на 10 і більше літер"},
            "genii": {title: "Геній", description: "Слово на 15 і більше літер"},
            "combo": {title: "Комбо", description: "Використано 5 і більше літер суперника"},
            "super_combo": {title: "Супер Комбо", description: "Використано 7 і більше літер суперника"},
            "bez_shansov": {title: "Без шансів", description: "Відібрав усі літери суперника"},
            "urojai": {title: "Врожай", description: "За хід набрано 50 і більше очок"},
            "super_urojai": {title: "Супер Врожай", description: "За хід набрано 100 і більше очок"},
            "skriaga": {title: "Скнара", description: "Закінчив гру з більше ніж 200 очками"},
            "zolotoj_schet": {title: "Золотий рахунок", description: "Закінчив гру з більше ніж 500 очками"},
            "marafonets": {title: "Марафонець", description: "За гру створив більше 30 очок"},
            "bronzovaya_pobeda": {title: "Бронзова перемога", description: "Переміг, зайнявши 3 базових літери суперника"},
            "serebryanaya_pobeda": {title: "Срібна перемога", description: "Переміг, зайнявши 4 базових літери суперника"},
            "zolotaya_pobeda": {title: "Золота перемога", description: "Переміг, зайнявши 5 базових літери суперника"},
            "samurai": {title: "Самурай", description: "Переміг без використання бонусів"},
            "strateg": {title: "Стратег", description: "Переміг з меньшою кількістю літер, ніж у суперника"},
            "desperado": {title: "Десперадо", description: "Переміг з відкритою своєю базовою літерою"},
            "chempion": {title: "Чемпіон", description: "Переміг 5 разів підряд"},
            "triumfator": {title: "Тріумфатор", description: "Переміг 10 разів підряд"},
        },

        "badges_popup_new": {
            title: "У вас нове досягнення!",
            text: "<span>\"{title}\"</span> - {description}",
        },

        "badges_popup_more": {
            title: "+1 \"{title}\"",
            text: "{description}",
        },

        "nmenu_badges": {
            title: "Досягнення",
            value: "{exists} з {total}"
        },
        "badges_exists_from_total": "відкрито {exists} з {total}",

    },
    en: {
        "playerName": ["Polislov", "Slovoglot"],
        "responseErrors": {
            [RESPONSE__ERROR_CODES.UNKNOWN]: "There is no such game. Start a new one.",
            [RESPONSE__ERROR_CODES.GAME_NOT_CREATED]: "Game creation error",
            [RESPONSE__ERROR_CODES.WRONG_GAME]: "Game creation error",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_TURN]: "It is not your turn",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_LETTERS]: "Wrong letters in the word",
            [RESPONSE__ERROR_CODES.GAME_ERROR__USED_WORD]: "This word already used :(",
            [RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_WORD]: "This word is not in the dictionary :(",
            [RESPONSE__ERROR_CODES.GAME_ERROR__PERK_VALIDATION]: "Failed to use perk!",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_NOT_CREATED]: "Failed to create a network game. Try later.",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_NOT_ACCEPTED_OR_CREATED]: "Network game error. Try again",
            [RESPONSE__ERROR_CODES.GAME_ERROR__NETWORK_CHALLENGE_WRONG_CODE]: "Error the game with this code does not exist. You've made a typing mistake or your friend canceled the game"
        },

        "timeLimits": {
            titles: {
                [GAME_MODE.LOCAL]: 'Game for two on the same TV',
                [GAME_MODE.NETWORK__RANDOM]: 'Random opponent',
                [GAME_MODE.NETWORK__WITH_FRIEND]: 'Game with a friend',
            },
            subtitles: {
                default: "Select the time count for the game"
            },
            networkGameLabel: {
                [GAME_TIME_LIMITS_TYPES.MOVE]: ", {minutes} min. per move.",
                [GAME_TIME_LIMITS_TYPES.GAME]: ", {minutes} min. per game.",
                [GAME_TIME_LIMITS_TYPES.NONE]: ".",
            },
            variants: {
                [GAME_TIME_LIMITS_TYPES.MOVE]: "{minutes} min. per MOVE",
                [GAME_TIME_LIMITS_TYPES.GAME]: "{minutes} min. per GAME",
                [GAME_TIME_LIMITS_TYPES.NONE]: "Without time limit",
            }
        },

        "gameErrors": {
            gameNotExisted: "There is no such game. Start a new one.",
            authError: "Authorization error. Try again later.",
        },

        "mainMenu": {
            "newGame": "FAST GAME",
            "continue": "CONTINUE",
            "networkGames": "ONLINE GAME",
            "rules": "RULES",
            "dictionary": "DICTIONARY",
            "dictionaryRu": "RU",
            "dictionaryUa": "UA",
            "dictionaryEn": "EN",
            "exit": "QUIT",
            "support": "Contact us: "
        },

        "howToPlay": {
            "page0": "Strategic word game for two.<br /><br />Collect words using the available letters on the field and move forward until you reach one of your opponent's <b>Basic Letters</b>.",
            "page1": {
                "title": "Rules",
                "text": "You have to compose the words using the letters available to you. You may use <b>your letters</b> and <b>the nearest ones</b> in the word. Take free letters to move forward. Take away your opponent's letters to worsen their position."
            },
            "page2": "For each word, you get <b>the sum of the points</b> of all the letters used in it.<br/><br/>After each use of a letter, its value increases by <b>1 point</b>.<br/><br/>After releasing, the value of the letter <b>becomes 1</b>.<br/><br/>The winner is the one who first takes the opponent's <b>Basic Letter</b> (in the left column are <span class=\"c0\">Poleslov</span>, Basic Letters, in the right one are <span class=\"c1\">Slovoglot</span>).",
            "page3": "It's enough to play <b>POLESLOV</b>. But to get a beautiful victory, &nbsp;— you need to know some subtleties!",
            "page4": [
                "<b>Points</b> in the game are needed to buy <b>bonuses</b>",
                "The <b>\"Defense\"</b> bonus restricts the use of the selected letter for 2 turns (one is yours and one of your opponent's).",
                "Use the <b>\"Mutation\"</b> bonus to replace any letter of the field with another. In this way, you can simplify your life or make it difficult for your opponent."

            ],
            "page5": [
                "With the <b>\"Attack\"</b> bonus, you can capture a previously unavailable letter on the field. After attacking, the unavailable letter will become yours, and the opponent's letter will become unavailable.",
                "The <b>\"+30 seconds\"</b> bonus will add time when it's about to run out.",
                "You may use bonuses on <b>any</b> letter except the opponent's <b>Basic Letters</b>",
                "Each bonus has a price, which increases <b>twice</b> after usage."

            ],
            page6: {
                title: 'DICTIONARY',
                text: 'Used in the game ',
                dic: {
                    [DICTIONARY.UA]: '"Large Electronic Dictionary of the Ukrainian Language "(VESUM), <b>github.com/brown-uk/dict_uk</b>',
                    [DICTIONARY.RU]: '- used resource <b>dict.ruslang.ru/freq.php</b><br/>' +
                    '- original source: O. N. Lyashevskaya, S. A. Sharov, Frequency Dictionary of the Modern Russian Language (based on the materials of the National Corpus of the Russian Language). M.: Azbukovnik, 2009.<br/><br/>' +
                    '© O. N. Lyashevskaya, S. A. Sharov, 2009-2011.<br/>' +
                    '© National Corpus of the Russian Language, 2009-2011.<br/>' +
                    '© Publishing house "Azbukovnik" (printed version of the dictionary), 2009.<br/>' +
                    '(c) A. V. Sannikov (creation of the site dict.ruslang.ru, technical support), 2008–2011.',
                    [DICTIONARY.EN]: 'The dictionary has all the most used words in the English language, and even ' +
                    'more…it got more than 200 thousand words! In the game you can use nouns, verbs, adjectives, ' +
                    'interjections etc. However, there is one limitation - you cannot use nouns in plural (legs ' +
                    'versus leg) except where the noun is commonly used collectively (pants versus pant, barracks ' +
                    'versus barrack). There are some abstract collective nouns like earnings or statistics - in ' +
                    'this case you also can use them freely.'
                }

            }
        },

        "buttonHint": {
            [PERK__CAPTURE]: {
                "title": "Capture",
                "text": "Capture a free letter or take away an opponent's letter"
            },
            [PERK__MUTATION]: {
                "title": "Mutation",
                "text": "Go for it! The winner is the one who first captures the opponent's Basic Letter"
            },
            [PERK__BLOCK]: {
                "title": "Block",
                "text": "The blocked letter will be unavailable for use in the word for one opponent's turn and one of yours."
            },
            [PERK__MORE_TIME]: {
                "title": "+30 sec.",
                "text": "30 seconds will be added to your time"
            },
            "surrender": {
                "title": "Give Up",
                "text": "Run out of words - admit defeat"
            },
            "send-message": {
                "title": "Chat",
                "text": "Send a message to an opponent"
            }
        },

        "gameJustStartedHint": "Go for it! The winner is the one who first<br>captures the opponent's Basic Letter",

        "chat_messages": [
            "Hi!",
            "Oh come on...",
            "Are you serious?",
            "What a letter...",
            "What's that word?",
            "Get it!",
            "How about that Elon?",
            "Hold on!",
            "Make a move!",
            "How much longer?...",
            "Pull yourself together :)",
            "I think it's over :(",
            "I'm just throwing the game :)",
            "Look what's about to happen!",
            "WOW!"
        ],

        "exitConfirm": {
            "message": "Are you sure you want to quit the game?",
            "exit": "QUIT",
            "cancel": "CANCEL"
        },

        "dictionaryConfirm": {
            "message": "The current game will be reset a new game will be created with <b style=\color: #0FB986\">{lang}</b> dictionary and alphabet. Continue?",
            [DICTIONARY.RU]: "Russian",
            [DICTIONARY.UA]: "Ukrainian",
            [DICTIONARY.EN]: "English",
            "yes": "Yes",
            "no": "Cancel"
        },

        "changeDictionaryAlert": {
            "message": "Now you will create all new games with <b style=\"color: #0FB986\">{lang}</b> dictionary and alphabet",
            [DICTIONARY.RU]: "Russian",
            [DICTIONARY.UA]: "Ukrainian",
            [DICTIONARY.EN]: "English",
        },

        "ok": "OK",
        "cancel": "CANCEL",
        "more": "MORE",

        "alertConnectionError": "Network error. Try again.",
        "alertGameCrateError": "Failed to create a new game. Try again.",

        "networkChallenge": {
            "showCode": "Share this code with your friend and wait for them to connect to the game. Time to connect is 5 minutes <div class='code'>{code}</div>",
            "waitRandomOpponent": "Searching for a random opponent to play with <b style=\"color: #0FB986\">{dictionary}</b> dictionary, wait...",
            "expiredMessage": "Unfortunately the time allotted to connect to the game has expired the game was closed",
            "enterCode": "Enter the game code your opponent gave you",
            "rejected": "The game has been closed",
            "cancel": "CANCEL",
            [DICTIONARY.RU]: "Russian",
            [DICTIONARY.UA]: "Ukrainian",
            [DICTIONARY.EN]: "English",
        },

        "networkButtonsDesc": {
            "withFriend": "Play with <br/>a Friend",
            "connect": "Join <br/>the Game",
            "random": "Random Opponent"
        },

        "networkGamesTitle": "Recent games",
        "networkGamesText": " If you succeed first you gain a strategic advantage.<br><br>If you don't make the first move your opponent wins.<br><br>A list of your unfinished online games will be available here instead of this tip.",

        "networkGameDesc": {
            "against": "VS {user}",
            "yourTurn": " <b class='c0'>Your turn</b>",
            "timeLeft": " More {text}",
            "youLose": "You lost to {user} :(",
            "youWin": "You won under {user}!",
        },

        "playerMoves": "Making a move",
        "playerWins": "Won",
        "your": "You",
        "move": "Turn",
        "you": "You",
        "win": "Won!",
        "lose": "lost :(",
        "bothMoves": "Make the move first",

        "surrenderConfirm": "Admit defeat?",

        "hours": {
            "1": "hour",
            "2": "hours",
            "5": "hours"
        },
        "minutes": {
            "1": "minute",
            "2": "minutes",
            "5": "minutes"
        },

    "badges": {
        "pervoe_slovo": {title: "First Word", description: "First word created in the game"},
        "pervaya_pobeda": {title: "First Won", description: "First win in the game"},
        "sprinter": {title: "Sprinter", description: "Took the entire second row with the first move in the game"},
        "znatok": {title: "Smart One", description: "A word with 8 or more letters"},
        "umnik": {title: "Nerd", description: "A word with 10 or more letters"},
        "genii": {title: "Genius", description: "A word with 15 or more letters"},
        "combo": {title: "Combo", description: "Used 5 or more letters of the opponent in the word"},
        "super_combo": {title: "Super Combo", description: "Used 7 or more letters of the opponent in the word"},
        "bez_shansov": {title: "No Chance", description: "Took away all the opponent's letters"},
        "urojai": {title: "Harvest", description: "Scored 50 or more points in one turn"},
        "super_urojai": {title: "Super Harvest", description: "Scored 100 or more points in one turn"},
        "skriaga": {title: "Miser", description: "Finished the game with more than 200 points"},
        "zolotoj_schet": {title: "Golden boy", description: "Finished the game with more than 500 points"},
            "marafonets": {title: "Marathoner", description: "Composed 30 or more words per game"},
            "bronzovaya_pobeda": {title: "Bronze victory", description: "Won by taking 3 basic letters of an opponent"},
            "serebryanaya_pobeda": {title: "Silver victory", description: "Won by taking 4 basic letters of an opponent"},
            "zolotaya_pobeda": {title: "Golden victory", description: "Won by taking 5 basic letters of an opponent"},
            "samurai": {title: "Samurai", description: "Won without using bonuses"},
            "strateg": {title: "Strategist", description: "Won with fewer letters than the opponent"},
            "desperado": {title: "Desperado", description: "Won with an open basic letter"},
            "chempion": {title: "Champion", description: "Won 5 times in a row"},
            "triumfator": {title: "Triumphant", description: "Won 10 times in a row"},
        },

        "badges_popup_new": {
            title: "You have a new achievement!",
            text: "<span>\"{title}\"</span> - {description}",
        },

        "badges_popup_more": {
            title: "+1 \"{title}\"",
            text: "{description}",
        },

        "nmenu_badges": {
            title: "achievement",
            value: "{exists} of {total}"
        },
        "badges_exists_from_total": "opened {exists} of {total}",

    }

});


export default  i18n;
