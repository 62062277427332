import React from "react";
import StoreGame from "../store/StoreGame";
import {
    GAME_MODE,
    GAME_STATUS__FINISHED,
    GAME_STATUS__WAITING_MOVE_0,
    GAME_STATUS__WAITING_MOVE_1, GAME_STATUS__WAITING_MOVE_BOTH
} from "../common/const";
import {observer} from "mobx-react";
import StoreNewWord from "../store/StoreNewWord";
import i18n from "../common/i18n";

const TopMessage = observer(class TopMessage extends React.Component {

    render() {
        //console.log('render TopMessage');

        if(StoreGame.game === null)
            return null;

        let string = null, className = 'top-message';
        if((StoreGame.game.status === GAME_STATUS__WAITING_MOVE_0 ||
            StoreGame.game.status === GAME_STATUS__WAITING_MOVE_1 ||
            StoreGame.game.status === GAME_STATUS__WAITING_MOVE_BOTH)
            && StoreNewWord.word.length === 0) {

            if(StoreGame.gameJustStarted) {
                className += ' doublestring';
                string = i18n.gameJustStartedHint;
            } else {
                className += ' monostring';
                if(StoreGame.game.mode === GAME_MODE.LOCAL) {
                    string = i18n.playerMoves + " <b class='c" + StoreGame.currentPlayerI + "'>" + i18n.playerName[StoreGame.currentPlayerI] + "</b>";
                } else {
                    if(StoreGame.game.status === GAME_STATUS__WAITING_MOVE_BOTH) {
                        string = i18n.bothMoves;
                    } else {
                        if(StoreGame.isMyTurn)
                            string = "<b class='c"+StoreGame.currentPlayerI+"'>" + i18n.your + "</b> " + i18n.move;
                        else {
                            string = i18n.playerMoves + " <b class='c" + StoreGame.otherPlayerI + "'>" + StoreGame.getPlayerName(StoreGame.otherPlayerI) + "</b>";
                            className += ' opponent-move';
                        }
                    }


                    if(StoreGame.wrongTurn) className += ' wrong';
                }
            }

        }
        else if(StoreGame.game.status === GAME_STATUS__FINISHED) {
            let winnerI = StoreGame.game.victory.player;
            className += ' doublestring-winner';
            let winnerName = '';
            if(StoreGame.game.mode === GAME_MODE.LOCAL) {
                string = i18n.playerWins + " <br> <b class='c"+winnerI+"'>" + i18n.playerName[winnerI] + "</b>";
            }
            else {
                if(winnerI === StoreGame.currentPlayerI) {
                    string = "<b class='c0'>" + i18n.you + "</b> <br/>"+i18n.win;
                } else {
                    string = "<b class='c0'>" + i18n.you + "</b> <br/>"+i18n.lose;
                }
            }

            //string = i18n.playerWins + " <br> <b class='c"+winnerI+"'>" + winnerName + "</b>";
        }
        else {
            return null;
        }

        return (
            <div className={className} dangerouslySetInnerHTML={{__html: string}}></div>
        );
    }
})

export default TopMessage;