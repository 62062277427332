import React from 'react';
import {observer} from "mobx-react";
import StorePerkMutation from "../../store/StorePerkMutation";
import KboardBtn from "./KboardBtn";
import StoreGame from "../../store/StoreGame";
import {ALPHABET, FOCUS_LAYER__KBOARD, FOCUS_LAYER__MENU} from "../../common/const";
import StoreFocus from "../../focus/StoreFocus";
import MenuCloseBtn from "../menu/MenuCloseBtn";

const Kboard = observer(class Kboard extends React.Component {

    render() {
        //console.log('render Kboard');

        if(StoreGame.game === null) return null;

        let alphabet = ALPHABET[StoreGame.getGameDictionaryLang()].ORIGINAL,
            letters = alphabet.split(''),
            middle = Math.ceil(letters.length / 2),
            row1 = letters.slice(0, middle),
            row2 = letters.slice(middle);

        return (
            <div id="kboard" className={StorePerkMutation.showMutationKeyboard ? " show" : ""}>
                <div className="kboard-row0">
                    {row1.map(letter => <KboardBtn key={letter} letter={letter} defaultFocused={letter === 'а'} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__KBOARD]}/>)}
                </div>
                <div className="kboard-row1">
                    {row2.map(letter => <KboardBtn key={letter} letter={letter} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__KBOARD]}/>)}
                </div>
                <div className="down"></div>
            </div>

        );
    }
})

export default Kboard;