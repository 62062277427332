import {extendObservable, reaction} from "mobx";

import {SoundSource} from "../common/soundEffect";

class StoreSounds {

    constructor() {
        extendObservable(this, {
            audioContext: null,
            gainNode: null,
            source: null,
            sounds: {},
            soundsLoaded: false,
            smth: null,

            tooEarlyPlayBuffer: [],

        });

        this.playTooEarlyPlayBuffer = reaction(
            () => this.soundsLoaded,
            soundsLoaded => {
                this.tooEarlyPlayBuffer.map(act => this.playPls(act));
            }
        );

        var sounds = [
            {act: "hover",              url: "/sounds/hover.mp3"},
            {act: "click",              url: "/sounds/click.mp3"},
            {act: "word-is-ok",         url: "/sounds/word-added.mp3"},
            {act: "word-is-not-ok",     url: "/sounds/word-no.mp3"},
            {act: "victory",            url: "/sounds/winner.mp3"},
            {act: "lose",               url: "/sounds/lose.mp3"},
            {act: "incoming-message",   url: "/sounds/incoming.mp3"},
            {act: "new-badge",          url: "/sounds/badge.mp3"},
            //{act: "background",            url: "/sounds/background_1.mp3"}
        ];


        const AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = new AudioContext();


        this.source = new SoundSource(this.audioContext, sounds, () => {
            console.log("Finished loading sounds!");
            this.soundsLoaded = true;
            //this.playPls("background-music");

        });

        /*this.gainNode = this.audioContext.createGain();
        this.gainNode.connect(this.audioContext.destination);

        this.gainNode.gain.value = -1;*/

        console.log('Sounds Stored!!!');

    }

    playPls(act) {
        if(this.soundsLoaded) {

            if (this.audioContext.state === 'suspended') {
                let audio = this.source;
                this.audioContext.resume().then(function () {
                    audio.start(act);
                });
            }
            else
                this.source.start(act);
        } else {
            console.log('!@! too early');
            this.tooEarlyPlayBuffer.push(act);
        }
    }

    setSmth(f) {
        this.smth = f;
    }


}

export default new StoreSounds();