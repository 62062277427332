import React from "react";
import Field from "./Field";
import StoreGame from "../store/StoreGame";
import {observer} from "mobx-react";
import StorePerkCapture from "../store/StorePerkCapture";
import StorePerkMutation from "../store/StorePerkMutation";
import StorePerkBlock from "../store/StorePerkBlock";
import StoreFocus from "../focus/StoreFocus";
import {FOCUS_LAYER__GAME, FOCUS_LAYER__MENU} from "../common/const";
import MenuCloseBtn from "./menu/MenuCloseBtn";

const Board = observer(class Board extends React.Component {

    render() {
        //console.log('render Board');

        if(StoreGame.game === null)
            return null;

        let boardClassName = "";
        if(StorePerkBlock.trying) boardClassName = "perk-selection-block";
        if(StorePerkMutation.trying) boardClassName = "perk-selection-mutation";
        if(StorePerkCapture.trying) boardClassName = "perk-selection-capture";

        return (
            <div id="board" className={boardClassName}>
                <div className="letters">
                    {
                        StoreGame.game.fields.map((field, index) => <Field fieldI={index} key={index} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>)
                    }
                </div>
            </div>
        );
    }
})

export default Board;