import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../store/StoreGame";
import {
    FOCUS_LAYER__GAME,
    GAME_MODE,
    PERK__BLOCK,
    PERK__CAPTURE,
    PERK__MORE_TIME,
    PERK__MUTATION
} from "../common/const";
import StorePerkMutation from "../store/StorePerkMutation";
import StoreFocus from "../focus/StoreFocus";
import StorePerkBlock from "../store/StorePerkBlock";
import StorePerkCapture from "../store/StorePerkCapture";
import StoreSounds from "../store/StoreSounds";
import focusable from "../focus/Focusable";
import ButtonHint from "./ButtonHint";
import StoreApp from "../store/StoreApp";
import StorePerkMoreTime from "../store/StorePerkMoreTime";

const PerkButton = focusable(observer(class Mutation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false
        }
    }

    getCurrentPerkStore() {
        if(this.props.perkType === PERK__BLOCK) {
            return StorePerkBlock;
        } else if(this.props.perkType === PERK__MUTATION) {
            return StorePerkMutation;
        } else if(this.props.perkType === PERK__CAPTURE) {
            return StorePerkCapture;
        } else if(this.props.perkType === PERK__MORE_TIME) {
            return StorePerkMoreTime;
        }
    }

    onClick = () => {

        // если это сетевая игра и ход соперника - клик ничего не делает
        if(StoreGame.game.mode !== GAME_MODE.LOCAL && !StoreGame.isMyTurn) {
            StoreGame.setWrongTurn(true);
            setTimeout(() => StoreGame.setWrongTurn(false), 500);
            return;
        }

        if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__GAME) return;

        if(!StoreApp.clicksEnabled)    return;

        if(!this.focusable())   return;

        StoreSounds.playPls("click");

        this.getCurrentPerkStore().toggleTrying();

        this.setState({clicked: true});
        setTimeout(() => this.setState({clicked: false}), 300);
    }

    focusable() {
        if(StoreGame.game === null) return false;
        let player = StoreGame.game.players[this.props.playerI];
        return StoreGame.currentPlayerI === this.props.playerI && player.score >= player.perks[this.props.perkType].price
    }

    render() {
        //console.log('render PerkButton');

        if(StoreGame.game === null) return null;

        let perkStore = this.getCurrentPerkStore(), perkClassName = '';

        if(this.props.perkType === PERK__BLOCK) {
            perkClassName = ' block';
        } else if(this.props.perkType === PERK__MUTATION) {
            perkClassName = ' mutation';
        } else if(this.props.perkType === PERK__CAPTURE) {
            perkClassName = ' capture';
        } else if(this.props.perkType === PERK__MORE_TIME) {
            perkClassName = ' moretime';
        }

        let player = StoreGame.game.players[this.props.playerI];
        let isEnoughScoreForPerk = StoreGame.isEnoughScoreForPerk(this.props.playerI, this.props.perkType);

        let className = 'perk' + perkClassName;
        if(isEnoughScoreForPerk) className += ' available';
        if(StoreGame.currentPlayerI === this.props.playerI && isEnoughScoreForPerk) className += ' focusable';
        if(StoreGame.currentPlayerI === this.props.playerI && perkStore.trying) className += ' pressed';
        if(this.props.focused)  className += ' focused';

        if(this.state.clicked)  className += " clicked";


        return (
            <div className={className} ref={this.setRef} onClick={this.onClick}>
                {this.props.focused && <ButtonHint buttonType={this.props.perkType}/>}
                <div className="price">{player.perks[this.props.perkType].price}</div>
            </div>
        );
    }
}))

export default PerkButton;
