import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../store/StoreGame";
import StoreApp from "../store/StoreApp";

const SplashScreen = observer(class SplashScreen extends React.Component {

    render() {
        if(StoreGame.game !== undefined)
            return null;

        return (
            <div id="splash-screen">
                <div className={"logo-big " + StoreApp.dictionarySetting}></div>
            </div>
        );
    }
})

export default SplashScreen;