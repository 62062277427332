import {extendObservable} from "mobx";

import {
    DICTIONARY,
    DICTIONARY_URL,
    DICTIONARY_URL_WEBOS,
    GAME_MODE,
    GAME_STATUS__WAITING_MOVE_0,
    GAME_STATUS__WAITING_MOVE_1,
    GAME_STATUS__WAITING_MOVE_BOTH,
    KIBANA_EVENT_TYPE, NETWORK_OPPONENT_STATUS,
} from "../common/const";
import {BOARD_MODE__MOVES} from "../common/const";
import {computeAdditionalFieldsData, gameReversePreparationIfNeed} from "../common/utils"
import StorePerkBlock from "./StorePerkBlock";
import StorePerkMutation from "./StorePerkMutation";
import StorePerkCapture from "./StorePerkCapture";
import i18n from "../common/i18n";
import StoreApp from "./StoreApp";
import StorePerkMoreTime from "./StorePerkMoreTime";

class StoreGame {

    constructor() {
        extendObservable(this, {

            gameId: null,
            game: undefined,
            currentPlayerI: null,
            reversed: false,

            gameJustStarted: false,
            showUsedWords: true,
            boardMode: BOARD_MODE__MOVES,
            showMutationKeyboard: false,

            networkOpponentStatus: NETWORK_OPPONENT_STATUS.OFFLINE,

            wrongTurn: false,

            // флаг используется для сигнала, что при обновлении игры по подписке - нельзя передавать фокус игровому слою
            // используется в случаях, когда на экране попап и фокус должен остаться на попапе, хотя игра на фоне поменялась
            temporaryDoNotFocusGame: false,

            firebaseLastUpdateTimestamp: null,
        });

        this.dictionary = {
            lang: null,
                data: null
        }

        this.networkOpponentStatusSubscriptionPath = null;

        this.setMessage();
    }

    getGameDictionaryLang() {
        return this.game !== null && this.game.settings.dictionaryLang ?
            this.game.settings.dictionaryLang : DICTIONARY.RU;
    }

    isEnoughScoreForPerk(playerI, perkType) {
        let player = this.game.players[playerI];
        if(!!player.perks[perkType]) {
            return player.score >= player.perks[perkType].price;
        }
        return false;
    }

    isPlayerTurn(playerI) {
        //return this.currentPlayerI === playerI;
        /*console.log(this.game.status, this.currentPlayerI, playerI, this.game.status === GAME_STATUS__WAITING_MOVE_BOTH ||
            this.game.status === GAME_STATUS__WAITING_MOVE_0 && this.currentPlayerI === playerI ||
            this.game.status === GAME_STATUS__WAITING_MOVE_1 && this.currentPlayerI === playerI);*/
        return  this.game.status === GAME_STATUS__WAITING_MOVE_BOTH ||
            this.game.status === GAME_STATUS__WAITING_MOVE_0 && playerI === 0 ||
            this.game.status === GAME_STATUS__WAITING_MOVE_1 && playerI === 1;
    }

    setReversed(r) {
        this.reversed = r;
    }

    setTemporaryDoNotFocusGame(f) {
        console.log('setTemporaryDoNotFocusGame', f);
        this.temporaryDoNotFocusGame = f;
    }

    get isMyTurn() {
        return  this.game.status === GAME_STATUS__WAITING_MOVE_BOTH ||
                this.game.status === GAME_STATUS__WAITING_MOVE_0 && this.currentPlayerI === 0 ||
                this.game.status === GAME_STATUS__WAITING_MOVE_1 && this.currentPlayerI === 1;
    }

    get otherPlayerI() {
        return (this.currentPlayerI + 1) % 2;
    }

    setWrongTurn(f) {
        this.wrongTurn = f;
    }

    getPlayerName(playerI) {
        let user = this.game.players[playerI].user;
        return user ? user.name : i18n.playerName[playerI]
    }

    /**
     * инициализация словаря для игры
     */
    initDictionary(lang) {
        let store = this;

        delete this.dictionary.data;
        let url = StoreApp.stvAppConnector.parentAppData.info && StoreApp.stvAppConnector.parentAppData.info.vendor === 'webos' ? DICTIONARY_URL_WEBOS[lang] : DICTIONARY_URL[lang];
        fetch(url)
            .then((response) => {
                if(response.status !== 200) {
                    StoreApp.stvAppConnector.sendKibana(KIBANA_EVENT_TYPE.GAME_ERROR, {
                        responseStatus: response.status,
                        info: 'Dictionary fetch error'
                    });
                }
                return response.json();
            })
            .then(function(data) {
                store.dictionary = {
                    lang: lang,
                    data: data
                };
            })
    }

    setCurrentPlayerI(index) {
        this.currentPlayerI = index;
    }

    setBoardMode(mode) {
        this.boardMode = mode;
    }

    setGameId(id) {
        this.gameId = id;
    }


    setMessage(message=0) {
       if(message === 0)
           this.message = {type:'success', text:''}
       else
           this.message = message;
    }

    setNetworkOpponentStatus(s) {
        this.networkOpponentStatus = s;
    }


    setGame(game) {

        // отписываемся от слежения за статусом предыдущего оппонента, если явно это нужно (предыдущая игра - сетевая и есть подписка)
        if(this.networkOpponentStatusSubscriptionPath !== null && (this.game.mode === GAME_MODE.NETWORK__RANDOM || this.game.mode === GAME_MODE.NETWORK__WITH_FRIEND)) {
            StoreApp.firestoreUnsubscribe(this.networkOpponentStatusSubscriptionPath);
            this.networkOpponentStatusSubscriptionPath = null;
            this.setNetworkOpponentStatus(NETWORK_OPPONENT_STATUS.OFFLINE);
        }

        this.game = null;

        if(game !== null) {
            game.fields = computeAdditionalFieldsData(game.fields, this.currentPlayerI);

            // для совместимости со старыми играми
            if(!game.mode) game.mode = GAME_MODE.LOCAL;

            // если есть необходимость - подписываемся на онлайн статус нового опонента
            if(game.mode === GAME_MODE.NETWORK__RANDOM || game.mode === GAME_MODE.NETWORK__WITH_FRIEND) {
                const path = 'userStatuses/'+game.players[1].user.id;
                if(game.players[1].user.id && !StoreApp.checkFirestoreSubscription(path)) {
                    this.networkOpponentStatusSubscriptionPath = path;
                    StoreApp.firestoreSubscribe(path,  docSnapshot => {
                        if(docSnapshot && docSnapshot.data())
                            this.setNetworkOpponentStatus(docSnapshot.data().status);
                    });
                }
            }

            this.game = game;

            if(this.dictionary.lang !== this.game.settings.dictionaryLang) {
                this.initDictionary(this.game.settings.dictionaryLang);
            }
        }
    }


    setFieldPressed(fieldI) {
        this.game.fields[fieldI].pressed = true;
    }

    setFieldUnPressed(fieldI) {
        this.game.fields[fieldI].pressed = false;
    }

    setFieldClicked(fieldI) {
        this.game.fields[fieldI].clicked = true;
        setTimeout(() => this.game.fields[fieldI].clicked = false, 300);
    }


    clearFieldsPressed() {
        this.game.fields.map(f => f.pressed = false);
    }


    clearAllPerks() {
        StorePerkBlock.clear();
        StorePerkMutation.clear();
        StorePerkCapture.clear();
        StorePerkMoreTime.clear();
    }



    setGameJustStarted() {
        this.gameJustStarted = true;
        setTimeout(() => this.gameJustStarted = false, 7000);
    }

    setFirebaseLastUpdateTimestamp(timestamp) {
        this.firebaseLastUpdateTimestamp = timestamp;
    }

}

export default new StoreGame();
