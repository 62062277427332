import React from "react";
import {observer} from "mobx-react";
import {getRandomInt} from "../common/utils";

const Confeti = observer(class Confeti extends React.Component {
    constructor(props) {
        super(props);

        this.state = {go: false};
    }

    _generateItems() {
        let items = [];
        for(let i=0;i<14;i++) {
            let left = 580 + getRandomInt(700);
            let top = -100-getRandomInt(720*2);
            let c = 1 + i % 7;
            items.push(<div className={"confeti c"+c} style={{left: left, top: top}}></div>);
        }

        return items;
    }

    componentDidMount() {
        setTimeout(() => this.setState({go: true}), 500);
    }

    render() {
        //console.log('render Confeti');

        return (
            <div className={"confeti-wr" + (this.state.go ? " go" : "")}>
                {this._generateItems()}
            </div>

        );
    }
})

export default Confeti;