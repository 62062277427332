import React from "react";
import {observer} from "mobx-react";
import "../index.css";
import {
    DICTIONARY,
    FOCUS_LAYER__GAME,
    GAME_STATUS__FINISHED, GAME_TIME_LIMITS_TYPES
} from "../../../common/const";
import focusable from "../../../focus/_Focusable";
import StoreSounds from "../../../store/StoreSounds";
import StoreFocus from "../../../focus/StoreFocus";
import {__, ___, betweenDates, connectExistedGame} from "../../../common/utils";
import i18n from "../../../common/i18n";
import StoreApp from "../../../store/StoreApp";

const NMenuGameItem = focusable(observer(class NMenuGameItem extends React.Component {

    onCLick = () => {

        StoreSounds.playPls('click');

        StoreApp.setLoaderBigVisible(true);
        connectExistedGame(this.props.networkGame.id);
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);

    }

    componentDidUpdate() {

    }

    focusable() {
        // айтем не должен фокусится, если выходит за пределы области видимости
        return !this.props.unfocusable;
    }


    getSnapshotBeforeUpdate(prevProps, prevState) {
        if(this.props.focused && !prevProps.focused) {
            this.props.onFocused(this.props.index);
        }
        return null;
    }

    render() {

        //console.log('render NMenuGameItem');

        let text = '';
        const networkGame = this.props.networkGame.data;

        if(networkGame.status === GAME_STATUS__FINISHED) {
            const key = networkGame.user.player === networkGame.victory.player ? i18n.networkGameDesc.youLose : i18n.networkGameDesc.youWin;
            text = "<span class='info game-finished'>" + __(key, {user: "<b class='c1'>"+ networkGame.user.name + "</b>"}) + "</span>";
        } else {
            let bt = betweenDates(new Date(networkGame.expiredTime.seconds*1000));

            let leftTimeText = '';
            if(bt.h > 0)    leftTimeText = bt.h + ' ' + ___('hours', bt.h);
            else            leftTimeText = bt.m + ' ' +  ___('minutes', bt.m);

            const lang = networkGame.lang === DICTIONARY.UA ? "ua" : networkGame.lang;

            let limitsLabel = '';

            if(typeof networkGame.limits !== 'undefined') {
                let minutes = typeof networkGame.limits.value !== 'undefined' ? Math.round(networkGame.limits.value / 60 / 1000) : '';
                limitsLabel = __(i18n.timeLimits.networkGameLabel[networkGame.limits.type], {minutes: minutes});
            }

            text = "<div class='info'>" + __(i18n.networkGameDesc.against, {user: "<b class='c1'>"+ networkGame.user.name + "</b>"}) +
                ", <span class='lang'>" + lang + "</span>" + limitsLabel +
                (this.props.networkGame.youTurn ? i18n.networkGameDesc.yourTurn : "") +
                 "</div>";
        }






        return (
            <li className={"btn focusable "+(this.props.focused ? " focused" : "") + (networkGame.status === GAME_STATUS__FINISHED ? " game-finished" : "")} dangerouslySetInnerHTML={{__html: text}} onClick={this.onCLick}>

            </li>
        );
    }
}))

export default NMenuGameItem;
