import React from "react";
import {observer} from "mobx-react";
import StoreNewWord from "../../store/StoreNewWord";
import WordClearBtn from "./WordClearBtn";
import WordOkBtn from "./WordOkBtn";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__GAME, FOCUS_LAYER__MENU} from "../../common/const";
import MenuCloseBtn from "../menu/MenuCloseBtn";

const WordBar = observer(class WordBar extends React.Component {

    render() {
        //console.log('render WordBar');


        let className = "word" + (StoreNewWord.word.length >= 11 ? " long" : "");

        return (
            <div className="word-bar">
                <div className="message">{StoreNewWord.message}</div>
                {StoreNewWord.word.length > 0 &&
                    <div className={"word-and-buttons" + (StoreNewWord.message !== '' ? " wrong" : "")}>
                        <WordClearBtn focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>
                        <div className={className}>
                            {StoreNewWord.word.map(e =>
                                <div key={e.fieldI} className={"letter bc" + e.player}>
                                    <b>{e.letter}</b><i>{e.score}</i>
                                </div>
                            )}
                        </div>
                        <WordOkBtn focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>
                        <div className="score">+{StoreNewWord.score}</div>
                    </div>
                }
            </div>
        );
    }
})

export default WordBar;