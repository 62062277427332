import {extendObservable} from "mobx";



class StoreUser {

    constructor() {
        extendObservable(this, {
            id: null,
            user: null,
            megogoUserName: null,
            token: null
        });


    }



    setId(id) {
        this.id = id;
    }

    setUser(user) {
        this.user = null;

        if(user !== null)
            this.user = user;
    }

    setMegogoUserName(s) {
        this.megogoUserName = s;
    }

    setToken(token) {
        this.token = token;
    }

    searchForNewBadges(oldUserData, newUserData) {
        console.log(oldUserData, newUserData);
        let newBadges = [];
        if(!newUserData || !newUserData.badges)   return newBadges;

        Object.keys(newUserData.badges).forEach(badgeKey => {

            if(!oldUserData.badges || !oldUserData.badges[badgeKey])
                newBadges.push({id: badgeKey, newCount: newUserData.badges[badgeKey], count: newUserData.badges[badgeKey]});
            else if(oldUserData.badges[badgeKey] < newUserData.badges[badgeKey])
                newBadges.push({id: badgeKey, newCount: newUserData.badges[badgeKey] - oldUserData.badges[badgeKey], count: newUserData.badges[badgeKey]});
        })
        return newBadges;
    }

}

export default new StoreUser();