//======== локальная ==========
import {StoreFocusBase, FOCUS_LAYER_DEFAULT_FOCUS} from "./StoreFocusBase";
//=========== npm ==============
//import {StoreFocusBase, FOCUS_LAYER_DEFAULT_FOCUS} from "cursor-focus";

import {
    FOCUS_LAYER__POPUP_MESSAGE,
    FOCUS_LAYER__GAME,
    FOCUS_LAYER__HOW_TO_PLAY,
    FOCUS_LAYER__KBOARD,
    FOCUS_LAYER__MENU,
    GAME_STATUS__FINISHED,
    MENU_MODE__HIDE,
    FOCUS_LAYER__NMENU,
    MENU_MODE__IN_GAME,
    FOCUS_LAYER__BADGES_BOARD,
    FOCUS_LAYER__MESSAGE_SELECTOR, FOCUS_LAYER__VIDEO_PLAYER, FOCUS_LAYER__GAME_TIMES_POPUP
} from "../common/const";
import StoreGame from "../store/StoreGame";
import StoreHowToPlay from "../store/StoreHowToPlay";
import StoreSounds from "../store/StoreSounds";
import {cancelPerkMutation} from "../common/utils";
import StoreApp from "../store/StoreApp";
import StoreVideoPlayer from "../store/StoreVideoPlayer";

class StoreFocus extends StoreFocusBase{

    constructor() {
        super([
            FOCUS_LAYER__MENU,
            FOCUS_LAYER__GAME,
            FOCUS_LAYER__KBOARD,
            FOCUS_LAYER__POPUP_MESSAGE,
            FOCUS_LAYER__HOW_TO_PLAY,
            FOCUS_LAYER__NMENU,
            FOCUS_LAYER__BADGES_BOARD,
            FOCUS_LAYER__MESSAGE_SELECTOR,
            FOCUS_LAYER__VIDEO_PLAYER,
            FOCUS_LAYER__GAME_TIMES_POPUP
        ]);
    }

    moveFocus(direction) {
        if(super.moveFocus(direction)) {
            StoreSounds.playPls('hover');
            return true;
        }

        this.emptyFocusDirectionAction(direction);
        return false;

    }


    /**
     * Здесь можно определить действия при клике на стрелку, когда в текущем слое нет подходящих кандидатов
     * @param direction
     */
    emptyFocusDirectionAction(direction) {

        if(this.currentFocusLayer === FOCUS_LAYER__MENU) {
            if(StoreGame.game && StoreGame.game.status !== GAME_STATUS__FINISHED) {
                //разрешаем уходить всегда кроме экрана победы
                if (direction === 1) {//right
                    StoreApp.setMenuMode(MENU_MODE__HIDE)
                    this.setCurrentFocusLayer(FOCUS_LAYER__GAME, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
                }
            }
        } /*else if(this.currentFocusLayer === FOCUS_LAYER__GAME) {
            if(direction === 3) {//left
                StoreApp.setMenuMode(MENU_MODE__IN_GAME);
                this.setCurrentFocusLayer(FOCUS_LAYER__MENU);
            }
        }*/ else if(this.currentFocusLayer === FOCUS_LAYER__KBOARD) {
            if(direction === 2) {//down
                cancelPerkMutation();
            }
        } else if(this.currentFocusLayer === FOCUS_LAYER__HOW_TO_PLAY) {
            if(direction === 2) {//down
                StoreHowToPlay.nextPage();
            } else if(direction === 0) {//up
                StoreHowToPlay.prevPage();
            }
        } else if(this.currentFocusLayer === FOCUS_LAYER__NMENU) {
            if(direction === 3) {//left
                StoreApp.setMenuMode();
                setTimeout(() => this.setCurrentFocusLayer(FOCUS_LAYER__MENU, FOCUS_LAYER_DEFAULT_FOCUS.SAVED), 100);
            }
        }  else if(this.currentFocusLayer === FOCUS_LAYER__VIDEO_PLAYER) {
            if( direction === 0 ||//up
                direction === 1 && StoreVideoPlayer.moveLeft ||
                direction === 3 && !StoreVideoPlayer.moveLeft) {

                StoreVideoPlayer.setControlsVisible(false);
                StoreGame.setTemporaryDoNotFocusGame(false);
                setTimeout(() => this.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED), 300);
            }
        }
    }
}

export default new StoreFocus();
