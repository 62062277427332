import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__MENU, FOCUS_LAYER__NMENU, MENU_MODE__NETWORK} from "../../common/const";
import focusable from "../../focus/Focusable";
import i18n from "../../common/i18n";
import StoreSounds from "../../store/StoreSounds";
import StoreApp from "../../store/StoreApp";
import {showExitConfirm} from "../popupmessage/PopupMessage";
import {FOCUS_LAYER_DEFAULT_FOCUS} from "../../focus/StoreFocusBase";

const ItemNetworkGames = focusable(observer(class ItemNetworkGames extends React.Component {


    onCLick = () => {

        StoreSounds.playPls('click');

        if(!StoreApp.clicksEnabled)    return;

        // этот пункт меню остается кликабельным когда открыто сетевое меню чтобы с мышь можно было его закрыть
        if(StoreApp.menuMode !== MENU_MODE__NETWORK) {
            StoreApp.setMenuMode(MENU_MODE__NETWORK);
            setTimeout(() => StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__NMENU), 100);
        } else {
            StoreApp.setMenuMode();
            setTimeout(() => StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MENU, FOCUS_LAYER_DEFAULT_FOCUS.SAVED), 100);
        }
    }

    render() {
        //console.log('render ItemNetworkGames');

        let className = StoreApp.menuMode === MENU_MODE__NETWORK ? '' : 'focusable ';

        if(this.props.focused) className += ' focused';
        if(StoreApp.menuMode === MENU_MODE__NETWORK)    className += ' hoverable';

        return (<li className={className} onClick={this.onCLick}>{i18n.mainMenu.networkGames}</li>);
    }
}))

export default ItemNetworkGames;