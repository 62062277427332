import StoreGame from "../store/StoreGame";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import {
    ALPHABET,
    BOARD_MODE__MOVES,
    FOCUS_LAYER__GAME,
    FOCUS_LAYER__MENU,
    FUNCS_REGION, GAME_MODE,
    GAME_STATUS__FINISHED,
    GAME_STATUS__WAITING_MOVE_0,
    GAME_STATUS__WAITING_MOVE_1,
    KIBANA_EVENT_TYPE, LOCAL_STORAGE__LOCAL_GAME_ID,
    MENU_MODE__COLD_START,
    MENU_MODE__HIDE,
    MENU_MODE__VICTORY,
    NETWORK_CHALLENGE__STATUS,
    RESPONSE__ERROR, RESPONSE__ERROR_CODES,
    RESPONSE__SUCCESS
} from "./const";
import StoreFocus from "../focus/StoreFocus";
import StoreSounds from "../store/StoreSounds";
import StorePerkMutation from "../store/StorePerkMutation";
import {FOCUS_LAYER_DEFAULT_FOCUS} from "../focus/StoreFocusBase";
import StoreApp from "../store/StoreApp";
import StoreNewWord from "../store/StoreNewWord";
import i18n from "./i18n";
import StorePerkCapture from "../store/StorePerkCapture";
import StorePerkBlock from "../store/StorePerkBlock";
import {showAlert} from "../components/popupmessage/PopupMessage";
import StoreUser from "../store/StoreUser";
import StoreHowToPlay from "../store/StoreHowToPlay";
import StoreChat from "../store/StoreChat";
import StoreVideoPlayer from "../store/StoreVideoPlayer";

function remoteRequest(method, data) {
    let f = firebase.app().functions(FUNCS_REGION).httpsCallable(method);
    data.token = StoreUser.token;
    return f(data);
}

function usePerk(gameId, playerI, data, perkStore) {

    console.log(gameId, playerI, data);
    remoteRequest('usePerk',
        {
            game: gameId,
            player: playerI,
            perk: data
    }).then((res) => {
        if (res.data.status === RESPONSE__ERROR) {
            StoreNewWord.setMessage(i18n.responseErrors[res.data.code]);
        } else if (res.data.status === RESPONSE__SUCCESS) {
        }
    })
        .catch(() => {
            showAlert(i18n.alertConnectionError);
        })
        .finally( () => {
            StoreApp.setLoaderVisible(false);
            StoreGame.setBoardMode(BOARD_MODE__MOVES);
            perkStore.clear();
        });
}

function setTimeoutTokenRefresh() {
    let timeouts = [1000 * 60 * 45, 1000 * 60 * 10, 1000 * 60 * 2]; // 45 мин, 10 мин, 2 мин
    // каждые несколько минут выполняем полный цикл загрузки данных юзера чтобы обновить custom token, срок жизни которого 1 час
    setInterval(async () => {
        // первая попытка, если успех - вызываем рекурсивно эту же функцию
        if(!await StoreApp.loadPlayerFromFirebase(StoreApp)) // если ошибка - делаем таймаут перед второй попыткой
            setTimeout(async () => {
                // вторая попытка, если успех - вызываем рекурсивно эту же функцию
                if(!await StoreApp.loadPlayerFromFirebase(StoreApp))   // если ошибка - делаем таймаут перед третьей и последней попыткой
                    setTimeout( () => {
                        StoreApp.loadPlayerFromFirebase(StoreApp);
                    }, timeouts[2]);
            }, timeouts[1]);
    }, timeouts[0]);

}

function cancelPerkMutation() {
    StoreGame.setBoardMode(BOARD_MODE__MOVES);
    StorePerkMutation.setShowMutationKeyboard(false);
    StorePerkMutation.clear();
    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
}

function connectExistedGame(gameId) {

    // сперва отписываемся от прдыдущей игры
    console.log('connectExistedGame');
    if(StoreGame.gameId) {
        StoreApp.firestoreUnsubscribe("games/" + StoreGame.gameId);
        if(StoreGame.game && StoreGame.game.chat) {
            StoreApp.firestoreUnsubscribe("gameChats/" + StoreGame.game.chat);
            StoreChat.clear();
        }

    }


    StoreGame.setGameId(gameId);

    clearAdditionalGameData();

    // флаг для проверки, первый апдейт получен или нет
    let firstUpdateReceived = true;

    //отслеживаем изменения игры в базе и полностю перезаписываем объект игры при изменениях
    StoreApp.firestoreSubscribe("games/"+gameId, docSnapshot => {
        console.log('=== gameUpdatedFromFirebase ===' + gameId);


        if(docSnapshot && docSnapshot.data()) {
            StoreGame.setFirebaseLastUpdateTimestamp(Date.now());

            let game = docSnapshot.data();
            // для совместимости со старыми играми
            if(!game.mode)  game.mode = GAME_MODE.LOCAL;

            // если нужно - реверсим данные в игре
            game = gameReversePreparationIfNeed(game);

            //фиксируем индекс активного игрока - который сейчас ходит
            //в режиме сетевой игры - он фиксирован на всю игру, а в режиме игры вдвоем -
            //должен меняться каждый ход
            if (game.mode === GAME_MODE.LOCAL) {
                if (game.status === GAME_STATUS__WAITING_MOVE_0) StoreGame.setCurrentPlayerI(0);
                else StoreGame.setCurrentPlayerI(1);

            } else {
                // в этом режиме игры, текущие плейер - всегда 0й
                StoreGame.setCurrentPlayerI(0);
            }

            // при обновлении игры - издаем звук перехода хода
            if(game.status !== GAME_STATUS__FINISHED)
                StoreSounds.playPls("word-is-ok");

            StoreApp.changeLang(game.settings.dictionaryLang, true, false);
            //i18n.setLanguage(game.settings.dictionaryLang);

            // если открыты правила - скрываем
            StoreHowToPlay.hide();

            StoreApp.setMenuMode(MENU_MODE__HIDE);
            StoreGame.setGame(game);
            if(StoreGame.game.status === GAME_STATUS__FINISHED) {

                // шлем в кибану лог об окончании игры
                if(!firstUpdateReceived)
                    try {
                        // 1. шлем окончание игры всегда если это локальная игра
                        // 2. если сетевая - шлем только если этот игрок - побежитель (победа была после его хода). Так мы
                        // исключим дубли от отправки ивента обоими игроками, когда оба онлайн. При этом ВАЖНО!!! что
                        // окончания игр после признания поражения в случаях, когда победитель не онлайн - не будут учтены
                        if(StoreGame.game.mode === GAME_MODE.LOCAL || StoreGame.game.victory.player === StoreGame.currentPlayerI) {
                            let sendData = {
                                gameId: StoreGame.gameId,
                                lang: game.settings.dictionaryLang,
                                game_mode: StoreGame.game.mode,
                                usedWords: StoreGame.game.usedWords.length,
                                durationHours: (new Date().getTime() / 1000 - StoreGame.game.timeCreated.seconds) / 3600
                            };
                            if(!!StoreGame.game.limits) {
                                sendData.game_limits_type = StoreGame.game.limits.type;
                                if(!!StoreGame.game.limits.value)   sendData.game_limits_value = StoreGame.game.limits.value;
                            }
                            StoreApp.stvAppConnector.sendKibana(KIBANA_EVENT_TYPE.GAME_FINISH, sendData);
                        }

                    } catch(e) {}

                // играем правильную музыку
                if(StoreGame.game.mode === GAME_MODE.LOCAL ||
                    StoreGame.game.victory.player === StoreGame.currentPlayerI)
                    StoreSounds.playPls("victory");
                else
                    StoreSounds.playPls("lose");

                // если игра окончена - забываем о ней
                if(StoreGame.game.mode === GAME_MODE.LOCAL)
                    StoreApp.removeLocalGameId();

                // устанавливаем правильный режим меню и фокус
                StoreApp.setMenuMode(MENU_MODE__VICTORY);

                // чистим все что может быть лишнего на экране
                StorePerkMutation.clear();
                StoreNewWord.clear();

                // см. комментарий ниже!!!
                if(StoreGame.temporaryDoNotFocusGame) {
                    StoreFocus.changePreviousFocusLayer(FOCUS_LAYER__MENU);
                }
                else
                    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MENU);

            } else {
                // если сейчас на экране висит попап, или есть другая причина, когда после обновления игры нельзя вернуть
                // фокус игре - не отдаем фокус но подменяем предыдущий фокусный лейер, чтобы после бека или ОТМЕНЫ
                // на попапе фокус переходил на игру
                if(StoreGame.temporaryDoNotFocusGame)
                    StoreFocus.changePreviousFocusLayer(FOCUS_LAYER__GAME);
                else
                    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
            }


            //подписываемся на чат
            // подписываемся на чат, если он есть
            if(StoreGame.game.chat) {
                let firstChatUpdateReceived = true;
                StoreApp.firestoreSubscribe("gameChats/" + StoreGame.game.chat, docSnapshot => {
                    console.log('=== chat updated ==='+ StoreGame.game.chat);

                    let chat = docSnapshot.data();

                    for(let i=0;i<2;i++) {

                        if(firstChatUpdateReceived) {
                            if(chat.lastMessages[i].date !== null)  StoreChat.lastViwedMessagesDate[i] = chat.lastMessages[i].date.seconds;
                        }
                        else if(chat.lastMessages[i].date && (StoreChat.lastViwedMessagesDate[i] === null || chat.lastMessages[i].date.seconds > StoreChat.lastViwedMessagesDate[i])) {
                            StoreChat.lastViwedMessagesDate[i] = chat.lastMessages[i].date.seconds;
                            StoreChat.lastMessages[i] = chat.lastMessages[i];
                            StoreChat.showLastMessage(i);
                            if(getRightPlayerI(StoreGame.currentPlayerI) !== i)
                                StoreSounds.playPls("incoming-message");
                        }
                    }

                    firstChatUpdateReceived = false;
                });
            }


        } else {
            if(StoreApp.readLocalGameId() === StoreGame.gameId)
                StoreApp.removeLocalGameId();

            StoreGame.setGameId(null);
            StoreGame.setGame(null);

            StoreApp.setMenuMode(MENU_MODE__COLD_START);
            StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MENU);

            showAlert(i18n.gameErrors.gameNotExisted);
        }

        StoreApp.setLoaderBigVisible(false);

        firstUpdateReceived = false;
    });
}

async function createNewLocalGame(limits) {
    console.log('createNewLocalGame');
    let newGameId = null;

    try {

        let result = await remoteRequest('createGame', {
            dictionaryLang: StoreApp.dictionarySetting,
            limits: limits
        });
        if(result.data.status === RESPONSE__ERROR) {
            showAlert(i18n.alertGameCrateError);
        } else if(result.data.status === RESPONSE__SUCCESS) {
            newGameId = result.data.message;

            StoreApp.stvAppConnector.sendKibana(KIBANA_EVENT_TYPE.GAME_START, {
                lang: StoreApp.dictionarySetting,
                game_mode: GAME_MODE.LOCAL
            });
        }

        return newGameId;
    }catch (e) {
        showAlert(i18n.alertConnectionError);
        return null;
    }
}


function eventFire(el, etype){
    if (el.fireEvent) {
        el.fireEvent('on' + etype);
    } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
    }
}

function __(key, params={}) {
    let string = [key].toString();

    Object.keys(params).forEach(key => {
        string = string.replace("{"+key+"}", params[key]);
    });

    return string;
}

function ___(key, num) {
    if(num >= 10 && num <= 20)  return i18n[key][5];
    switch (num % 10) {
        case 1:     return i18n[key][1];
        case 2:
        case 3:
        case 4:     return i18n[key][2];
        default:    return i18n[key][5];
    }
}


function clearAdditionalGameData() {
    StoreNewWord.clear();
    StorePerkMutation.clear();
    StorePerkCapture.clear();
    StorePerkBlock.clear();
}

function getRightFieldI(i) {
    return StoreGame.reversed ? 24 - i : i;
}

function getRightPlayerI(i) {
    let res = StoreGame.reversed ? (i + 1) % 2 : i;
    return res;
}

function gameReversePreparationIfNeed(game) {

    let reversed = false;

    if(game.mode !== GAME_MODE.LOCAL && game.players[1].user.id === StoreUser.id) {

        reversed = true;

        // реверсим поля
        game.fields = game.fields.reverse();
        game.fields.forEach(field => {
            if(field.player <= 1)   field.player = (field.player + 1) % 2;
        });

        // реверсим плееров
        game.players = game.players.reverse();

        // реверсим использованные слова
        game.usedWords.forEach(word => word.playerI = (word.playerI + 1) % 2);

        // переименовываем статус игры
        if(game.status === GAME_STATUS__WAITING_MOVE_0)         game.status = GAME_STATUS__WAITING_MOVE_1;
        else if(game.status === GAME_STATUS__WAITING_MOVE_1)    game.status = GAME_STATUS__WAITING_MOVE_0;

        // реверсим победителя, если он есть в игре
        if(game.victory) game.victory.player = (game.victory.player + 1) % 2;
    }

    StoreGame.setReversed(reversed);

    return game;
}

function computeAdditionalFieldsData(fields, playerI) {
    let newFields = Object.assign([], fields);

    newFields.forEach(f => {f.pressed = false; f.focusable = true;});

    _addAvailableFieldsFlag(newFields, playerI);
    _setFieldsNeighbours(newFields);
    _setRoundCornersData(newFields);

    _addPressed(newFields);


    return newFields;
}

function getErrorText(code) {
    return i18n.responseErrors[code] ? i18n.responseErrors[code] : i18n.responseErrors[RESPONSE__ERROR_CODES.UNKNOWN] + '('+code+')';
}

function _addPressed(fields) {
    StoreNewWord.word.forEach(letter => {
        fields[letter.fieldI].pressed = true;
    });
}

function _addAvailableFieldsFlag(fields, playerI) {
    fields.forEach(field => {
        field.availableForMove = {
            available: false,
            safeSideAvailable: [],
            unavailableCorners: []
        };
        field.availableForPerk = {
            available: true,
            safeSideAvailable: [],
            unavailableCorners: []
        }
    });


    for(let i=0;i<fields.length;i++) {
        let ow = i % 5;
        let oh = parseInt(i / 5);
        let f = fields[i];

        //устанавливаем доступность для хода
        if(f.player === playerI) {
            for(let w=-1;w<=1;w++) {
                for(let h=-1;h<=1;h++) {
                    let nw = ow+w, nh = oh+h;
                    if(nw >= 0 && nw <5 && nh >= 0 && nh <5) {
                        //если сосед заблокирован перком - он недоступен
                        if(fields[nh * 5 + nw].perkBlocked > 0) continue;

                        fields[nh * 5 + nw].availableForMove.available = true;
                    }
                }
            }
        }

        //устанавливаем доступность для перка
        if((playerI === 1 && ow === 0) || f.perkBlocked)              f.availableForPerk.available = false;
        else if((playerI === 0 && ow === 4)  || f.perkBlocked)        f.availableForPerk.available = false;
    }


}

function _setFieldsNeighbours(fields) {
    for(let i=0;i<fields.length;i++) {
        let ow = i % 5;
        let oh = parseInt(i / 5);
        let f = fields[i];

        f.neighbours = [null, null, null, null, null, null, null, null];
        for(let w=-1;w<=1;w++) {
            for(let h=-1;h<=1;h++) {
                let nw = ow+w, nh = oh+h;
                if(nw >= 0 && nw < 5 &&
                    nh >= 0 && nh < 5) {
                    let index = nh * 5 + nw

                    if(h === -1 && w === -1)    f.neighbours[0] = index;
                    else if(h === -1 && w === 0)    f.neighbours[1] = index;
                    else if(h === -1 && w === 1)    f.neighbours[2] = index;
                    else if(h === 0  && w === 1)    f.neighbours[3] = index;
                    else if(h === 1  && w === 1)    f.neighbours[4] = index;
                    else if(h === 1  && w === 0)    f.neighbours[5] = index;
                    else if(h === 1  && w === -1)    f.neighbours[6] = index;
                    else if(h === 0 && w === -1)    f.neighbours[7] = index;

                }
            }
        }
    }
}


function _setRoundCornersData(fields) {
    for(let i=0;i<fields.length;i++) {
        let f = fields[i];

        _setFieldSafeSides(fields, f);

        f.playerCorners = [];

        _checkInnerCornerExists(fields, f, 0, f.neighbours[7], f.neighbours[0], f.neighbours[1]);
        _checkInnerCornerExists(fields, f, 1, f.neighbours[1], f.neighbours[2], f.neighbours[3]);
        _checkInnerCornerExists(fields, f, 2, f.neighbours[3], f.neighbours[4], f.neighbours[5]);
        _checkInnerCornerExists(fields, f, 3, f.neighbours[5], f.neighbours[6], f.neighbours[7]);
    }
}


function _setFieldSafeSides(fields, f) {

    //Safe Sides для закраски полей икгроков
    let safeSide = [false, false, false, false];
    if(f.neighbours[1] !== null && f.player === fields[f.neighbours[1]].player) safeSide[0] = true;
    if(f.neighbours[3] !== null && f.player === fields[f.neighbours[3]].player) safeSide[1] = true;
    if(f.neighbours[5] !== null && f.player === fields[f.neighbours[5]].player) safeSide[2] = true;
    if(f.neighbours[7] !== null && f.player === fields[f.neighbours[7]].player) safeSide[3] = true;

    let ta = [];
    safeSide.map((ss, index) => ss ? ta.push(index) : '');
    f.safeSidePlayer = ta;

    //--------------------
    ////Safe Sides для штриховки недоступности при ходе
    safeSide = [false, false, false, false];
    if(f.neighbours[1] !== null && f.availableForMove.available === fields[f.neighbours[1]].availableForMove.available) safeSide[0] = true;
    if(f.neighbours[3] !== null && f.availableForMove.available === fields[f.neighbours[3]].availableForMove.available) safeSide[1] = true;
    if(f.neighbours[5] !== null && f.availableForMove.available === fields[f.neighbours[5]].availableForMove.available) safeSide[2] = true;
    if(f.neighbours[7] !== null && f.availableForMove.available === fields[f.neighbours[7]].availableForMove.available) safeSide[3] = true;

    ta = [];
    safeSide.map((ss, index) => ss ? ta.push(index) : '');
    f.availableForMove.safeSideAvailable = ta;

    //--------------------
    ////Safe Sides для штриховки недоступности при перке
    safeSide = [false, false, false, false];
    if(f.neighbours[1] !== null && f.availableForPerk.available === fields[f.neighbours[1]].availableForPerk.available) safeSide[0] = true;
    if(f.neighbours[3] !== null && f.availableForPerk.available === fields[f.neighbours[3]].availableForPerk.available) safeSide[1] = true;
    if(f.neighbours[5] !== null && f.availableForPerk.available === fields[f.neighbours[5]].availableForPerk.available) safeSide[2] = true;
    if(f.neighbours[7] !== null && f.availableForPerk.available === fields[f.neighbours[7]].availableForPerk.available) safeSide[3] = true;

    ta = [];
    safeSide.map((ss, index) => ss ? ta.push(index) : '');
    f.availableForPerk.safeSideAvailable = ta;
}

function _checkInnerCornerExists(fields, f, pos, n1, n2, n3) {
    let result = {
        cornerPlayer: null,
        cornerAvailable: null
    };

    if(n1 === null || n2 === null || n3 === null)
        return;

    let f1 = fields[n1], f2 = fields[n2], f3 = fields[n3];


    if(f1.player !== null &&
        f1.player === f2.player && f1.player === f3.player &&
        f1.player !== f.player) {
        f.playerCorners.push({pos: pos, player: f1.player});
    }

    //круглый угол для недоступной зоны
    if(!f1.availableForMove.available && !f2.availableForMove.available && !f3.availableForMove.available && f.availableForMove.available) {
        //result.cornerAvailable = true;
        f.availableForMove.unavailableCorners.push(pos);
    }

    //круглый угол для недоступной зоны в режиме перков
    if(!f1.availableForPerk.available && !f2.availableForPerk.available && !f3.availableForPerk.available && f.availableForPerk.available) {
        //result.cornerAvailable = true;
        f.availableForPerk.unavailableCorners.push(pos);
    }

    return result;
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

function getRandomDid() {
    let did = "BROWSER";
    for(let i=0;i<20;i++) did += getRandomInt(10);
    return did;
}

function getWordTranslit(word, lang) {
    let alphaTranslit = ALPHABET[lang].TRANSLIT.split('');
    let alphaOriginal = ALPHABET[lang].ORIGINAL.split('');

    let or2tr = [];
    alphaOriginal.map((letter, index) => or2tr[letter] = alphaTranslit[index]);

    let translit = '';
    for(let i=0;i<StoreNewWord.text.length;i++) {
        translit += or2tr[word[i]];
    }
    return translit;
}

function sendPlayerCloseToKibana(eventType) {
    try {
        let watchTime = 0;
        if(StoreVideoPlayer.playerStartTime !== null) {
            watchTime = Math.round(((new Date).getTime() - StoreVideoPlayer.playerStartTime) / 1000);
            StoreVideoPlayer.setPlayerStartTime(null);
        }
        StoreApp.stvAppConnector.sendKibana(eventType, {
            watch_time: watchTime
        });
    } catch (e) {
    }
}


function getUrlParams(search) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');

    let result = {};
    hashes.forEach(hash => {
        const [key, val] = hash.split('=');
        result[key] = decodeURIComponent(val);
    });

    delete result[""];

    return result;
}



function betweenDates(date1, date2 = new Date()) {
    let delta = Math.floor(Math.abs(date2.getTime() - date1.getTime()) / 1000),
        minMs = 60,
        hourMs = minMs * 60;
    let hours = Math.floor(delta / hourMs),
        minutes = Math.floor((delta - hours * hourMs) / (minMs));

    return {h: hours, m: minutes, ds: delta};

}

function subscribeForSelfOnlineStatus() {
    // Fetch the current user's ID from Firebase Authentication.
    let uid = firebase.auth().currentUser.uid;

    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    let userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    let isOfflineForDatabase = {
        status: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    let isOnlineForDatabase = {
        status: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    firebase.database().ref('.info/connected').on('value', function(snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
            return;
        };

        // If we are currently connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once this
        // client has disconnected by closing the app,
        // losing internet, or any other means.
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
}


export {
    computeAdditionalFieldsData,
    gameReversePreparationIfNeed,
    getRightFieldI,
    getRightPlayerI,
    connectExistedGame,
    createNewLocalGame,
    remoteRequest,
    usePerk,
    eventFire,
    getRandomInt,
    getRandomDid,
    cancelPerkMutation,
    getWordTranslit,
    clearAdditionalGameData,
    getUrlParams,
    betweenDates,
    getErrorText,
    setTimeoutTokenRefresh,
    subscribeForSelfOnlineStatus,
    sendPlayerCloseToKibana,
    __,
    ___
}
