import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import CloseBtn from "./CloseBtn";
import Paginator from "./Paginator";
import StoreHowToPlay from "../../store/StoreHowToPlay";
import i18n from "../../common/i18n";
import StoreFocus from "../../focus/StoreFocus";
import {DICTIONARY, FOCUS_LAYER__HOW_TO_PLAY, FOCUS_LAYER__MENU} from "../../common/const";
import StoreApp from "../../store/StoreApp";

const HowToPlay = observer(class HowToPlay extends React.Component {

    componentDidMount() {
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__HOW_TO_PLAY);
    }

    render() {
        //console.log('render HowToPlay');

        if(!StoreHowToPlay.visible)
            return null;

        return (
            <div className="how-to-play">
                <CloseBtn focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__HOW_TO_PLAY]}/>
                <div className={"page page-0" + (StoreHowToPlay.activePage === 0 ? " show" : "")}>
                    <div className={"logo " + i18n.getLanguage()}></div>
                    <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page0}}></div>
                </div>



                <div className={"page page-1" + (StoreHowToPlay.activePage === 1 ? " show" : "")}>
                    <div className="title" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page1.title}}></div>
                    <div className="letters"></div>
                    <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page1.text}}></div>
                </div>

                <div className={"page page-2" + (StoreHowToPlay.activePage === 2 ? " show" : "")}>
                    <div className="light"></div>
                    <div className="score">164</div>
                    <div className="username c0">{i18n.playerName[0]}</div>
                    <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page2}}></div>
                </div>


                <div className={"page page-3" + (StoreHowToPlay.activePage === 3 ? " show" : "")}>
                    <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page3}}></div>
                </div>

                <div className={"page page-4" + (StoreHowToPlay.activePage === 4 ? " show" : "")}>
                    <div className="section-wr">
                        <div className="section">
                            <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page4[0]}}></div>
                        </div>
                        <div className="section">
                            <div className="icon block"></div>
                            <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page4[1]}}></div>
                        </div>
                        <div className="section">
                            <div className="icon mutation"></div>
                            <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page4[2]}}></div>
                        </div>
                        <div className="section">
                            <div className="text">...</div>
                        </div>
                    </div>
                </div>


                <div className={"page page-5" + (StoreHowToPlay.activePage === 5 ? " show" : "")}>
                    <div className="section-wr">
                        <div className="section">
                            <div className="icon capture"></div>
                            <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page5[0]}}></div>
                        </div>
                        <div className="section">
                          <div className="icon moretime"></div>
                          <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page5[1]}}></div>
                        </div>
                        <div className="section">
                            <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page5[2]}}></div>
                        </div>
                        <div className="section">
                            <div className="text" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page5[3]}}></div>
                        </div>
                    </div>
                </div>

                <div className={"page page-6" + (StoreHowToPlay.activePage === 6 ? " show" : "")}>
                    <div className="title" dangerouslySetInnerHTML={{__html: i18n.howToPlay.page6.title}}></div>
                    <div className="text"
                         dangerouslySetInnerHTML={{__html: i18n.howToPlay.page6.dic[StoreApp.dictionarySetting]}}></div>
                </div>

                <Paginator count={StoreHowToPlay.pageCount} active={StoreHowToPlay.activePage}/>

            </div>

        );
    }
})

export default HowToPlay;
