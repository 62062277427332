import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import Hls from "hls.js"
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";
import StoreVideoPlayer from "../../store/StoreVideoPlayer";
import StoreSounds from "../../store/StoreSounds";
import StoreFocus from "../../focus/StoreFocus";
import {
    FOCUS_LAYER__BADGES_BOARD,
    FOCUS_LAYER__GAME,
    FOCUS_LAYER__HOW_TO_PLAY,
    FOCUS_LAYER__MENU, FOCUS_LAYER__MESSAGE_SELECTOR, FOCUS_LAYER__NMENU,
    FOCUS_LAYER__VIDEO_PLAYER, GAME_MODE, KIBANA_EVENT_TYPE
} from "../../common/const";
import VideoPlayerOpenBtn from "./VideoPlayerOpenBtn";
import VideoPlayerControl from "./VideoPlayerControl";
import VideoPlayerFrame from "./VideoPlayerFrame";
import StoreGame from "../../store/StoreGame";
import {sendPlayerCloseToKibana} from "../../common/utils";


const VideoPlayer = observer(class VideoPlayer extends React.Component {
    constructor() {
        super();

    }

    componentDidMount() {
        if(StoreVideoPlayer.channels.length > 0) {
            StoreVideoPlayer.setChannel(StoreVideoPlayer.lastWatchedIndex !== null ? StoreVideoPlayer.lastWatchedIndex : 0);
        }
    }

    onControlClick = (type, event) => {
        StoreSounds.playPls("click");

        event.stopPropagation();
        if(type === 0) {
            StoreVideoPlayer.changeChannel(-1);

        } else if(type === 1) {
            StoreVideoPlayer.changeChannel(1);
        } else if(type === 2) {
            StoreApp.stvAppConnector.send('navigate', {href: "megogo://tvchannel/" + StoreVideoPlayer.channels[StoreVideoPlayer.currentChannelIndex].id});
            sendPlayerCloseToKibana(KIBANA_EVENT_TYPE.PLAYER_CLOSE_ON_OPEN_TV);
        } else if(type === 3) {

            //отправляем в кибану время работы плейера
            sendPlayerCloseToKibana(KIBANA_EVENT_TYPE.PLAYER_CLOSE);

            StoreVideoPlayer.setControlsVisible(false);
            StoreVideoPlayer.setVideoPlayerVisible(false);
            StoreGame.setTemporaryDoNotFocusGame(false);
            setTimeout(() => StoreFocus.setCurrentFocusLayer(null), 300);
        }
    }



    render() {

        if(!StoreVideoPlayer.videoPlayerVisible)    return null;

        let className = "video-player focusable" + (this.props.focused ? " focused" : "");

        if(StoreVideoPlayer.moveLeft)
            className += " left";

        let tvLogoStyle = {};
        if(StoreVideoPlayer.controlsVisible && StoreVideoPlayer.channels.length) {
            tvLogoStyle = {
                backgroundImage: "url("+StoreVideoPlayer.channels[StoreVideoPlayer.currentChannelIndex].image.color_logo+")",
                backgroundSize: "cover"
            }
        }


        return (
            <div className={className}>
                <VideoPlayerFrame focusStore={StoreFocus} stream={StoreVideoPlayer.currentChannelStream} focusLayers={[FOCUS_LAYER__GAME, FOCUS_LAYER__MENU, FOCUS_LAYER__NMENU]}/>
                {StoreVideoPlayer.controlsVisible && StoreVideoPlayer.channels.length > 0 && <div className={"tv-logo"} style={tvLogoStyle}></div>}
                {StoreVideoPlayer.controlsVisible && <VideoPlayerControl onClick={this.onControlClick}   type={0} defaultFocused={true} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__VIDEO_PLAYER]}/>}
                {StoreVideoPlayer.controlsVisible && <VideoPlayerControl onClick={this.onControlClick}   type={1} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__VIDEO_PLAYER]}/>}
                {StoreVideoPlayer.controlsVisible && <VideoPlayerControl onClick={this.onControlClick}   type={2} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__VIDEO_PLAYER]}/>}
                {StoreVideoPlayer.controlsVisible && <VideoPlayerControl onClick={this.onControlClick}   type={3} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__VIDEO_PLAYER]}/>}
            </div>
        );
    }
})

export default VideoPlayer;