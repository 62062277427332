import React from "react";
import {observer} from "mobx-react";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__KBOARD} from "../../common/const";
import StorePerkMutation from "../../store/StorePerkMutation";
import focusable from "../../focus/Focusable";
import StoreSounds from "../../store/StoreSounds";
import StoreApp from "../../store/StoreApp";

const KboardBtn = focusable(observer(class KboardBtn extends React.Component {

    onCLick = () => {
        StoreSounds.playPls('click');

        if(!StoreApp.clicksEnabled)    return;

        if(StorePerkMutation.letter === null) {
            StorePerkMutation.letter = this.props.letter;
        }
    }

    defaultFocused() {
        return this.props.letter === 'а';
    }

    render() {
        //console.log('render KboardBtn');

        return (<b className={"btn focusable" + (this.props.focused ? " focused" : "")} onClick={this.onCLick}>{this.props.letter}</b>);
    }
}))

export default KboardBtn;