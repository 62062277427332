import React from "react";
import "./index.css";
import focusable from "../../focus/Focusable";
import StoreSounds from "../../store/StoreSounds";
import StoreApp from "../../store/StoreApp";
import i18n from "../../common/i18n";
import {getRightPlayerI, remoteRequest} from "../../common/utils";
import StoreGame from "../../store/StoreGame";
import {FOCUS_LAYER__GAME, KIBANA_EVENT_TYPE, RESPONSE__ERROR, RESPONSE__SUCCESS} from "../../common/const";

import {showAlert} from "../popupmessage/PopupMessage";
import StoreFocus from "../../focus/StoreFocus";
import StoreChat from "../../store/StoreChat";

const MessageBtn = focusable(class MessageBtn extends React.Component {

    onCLick = (event) => {
        StoreSounds.playPls('click');

        if(!StoreApp.clicksEnabled)    return;

        remoteRequest('sendMessage',
            {
                chat: StoreGame.game.chat,
                player: getRightPlayerI(StoreGame.currentPlayerI),
                fraze: this.props.messageId
            }).then((result) => {
            if(result.data.status === RESPONSE__ERROR) {

            } else if(result.data.status === RESPONSE__SUCCESS) {
                try {
                    StoreApp.stvAppConnector.sendKibana(KIBANA_EVENT_TYPE.GAME_NEW_CHAT_MESSAGE, {});
                } catch (e) {
                }
            }
        }).catch(function(err) {
            showAlert(i18n.alertConnectionError);
        }).finally(function () {
        });

        StoreChat.setMessageSelectorVisible(false);
        StoreGame.setTemporaryDoNotFocusGame(false);
        setTimeout(() => StoreFocus.setCurrentFocusLayer(null), 300);

    }

    defaultFocused() {
        return this.props.defaultFocused;
    }

    render() {

        let className = "message focusable";

        if(this.props.focused)  className += " focused";

        return (
            <div className={className} onClick={this.onCLick}>{i18n.chat_messages[this.props.messageId]}</div>
        );
    }
})

export default MessageBtn;