import React from "react";
import {observer} from "mobx-react";
import i18n from "../common/i18n";
import StoreChat from "../store/StoreChat";
import StoreGame from "../store/StoreGame";
import {getRightPlayerI, remoteRequest} from "../common/utils";
import {GAME_STATUS__WAITING_MOVE_BOTH} from "../common/const";
import StoreApp from "../store/StoreApp";

const PlayerTimer = observer(class PlayerTimer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timer: 0
        }

        this.interval = null;
    }

    componentDidMount() {

        let spent = StoreGame.firebaseLastUpdateTimestamp - (StoreGame.game.timeLastAction.toDate().getTime() + StoreApp.timeCorrection);

        let userTimer = StoreGame.game.players[this.props.playerI].timer;


        if(StoreGame.game.status !== GAME_STATUS__WAITING_MOVE_BOTH && StoreGame.isPlayerTurn(this.props.playerI)) {
            if(userTimer  > spent) {
                this.setState({timer: userTimer - spent})
                this.interval = setInterval(() => {
                    let newTimerValue = this.state.timer - 1000;
                    if(newTimerValue <= 0) {
                        this.setState({timer: 0})
                        clearInterval(this.interval);
                        remoteRequest("checkGameTimer", {game: StoreGame.gameId});
                    }
                    else    this.setState({timer: newTimerValue})
                }, 1000)
            } else {
                remoteRequest("checkGameTimer", {game: StoreGame.gameId});
                this.setState({timer: 0})
            }
        } else {
            this.setState({timer: userTimer})
        }


    }

    static getTimerData(seconds) {
        let m = Math.floor(seconds / 60) + "";
        if(m.length === 1)  m = "0"+m;

        let s = seconds % 60 + "";
        if(s.length === 1)  s = "0"+s;

        return [m, s];
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        //console.log('render PlayerTimer');

        if(StoreGame.game === null) return null;

        let rightPlayerI = getRightPlayerI(this.props.playerI);
        let showMessage = StoreChat.lastMessagesVisible[rightPlayerI];
        let messageText = showMessage ? i18n.chat_messages[StoreChat.lastMessages[rightPlayerI].fraze] : "";

        let className = "timer-frame";
        if(StoreGame.isPlayerTurn(this.props.playerI))  className += " turn";
        let seconds = Math.round(this.state.timer / 1000);
        if(seconds < 60)  className += " low";

        let timerData = PlayerTimer.getTimerData(seconds);


        return (
            <div className="timer">
                <span className={className}>
                    {timerData[0]} : {timerData[1]}
                </span>
            </div>
        )
    }
});

export default PlayerTimer;
