import React from "react";
import "./index.css";
import StoreUser from "../../store/StoreUser";

class UserBlock extends React.Component {


    render() {
        //console.log('render Menu CloseBtn');

        return (
            <div className="user-block">
                <div className="user-name c0">{StoreUser.user && StoreUser.user.name}</div>
            </div>
        );

    }
}

export default UserBlock;