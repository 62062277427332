import React from "react";
import StoreGame from "../store/StoreGame";
import {observer} from "mobx-react";
import {
    FOCUS_LAYER__GAME, FOCUS_LAYER__MESSAGE_SELECTOR,
    GAME_MODE,
    GAME_STATUS__FINISHED, GAME_TIME_LIMITS_TYPES,
    PERK__BLOCK,
    PERK__CAPTURE, PERK__MORE_TIME,
    PERK__MUTATION
} from "../common/const";
import PerkButton from "./PerkButton";
import PlayerScore from "./PlayerScore";
import Surrender from "./Surrender";
import StoreFocus from "../focus/StoreFocus";
import BadgesButton from "./badges/BadgesBtn";
import ChatBtn from "./chat/ChatBtn";
import PlayerTimer from "./PlayerTimer";

const PlayerSide = observer(class PlayerSide extends React.Component {

    render() {
        //console.log('render PlayerSide');



        if(StoreGame.game === null)
            return null;

        if(StoreGame.game.status === GAME_STATUS__FINISHED)
            return null;

        let playerI = this.props.playerI;


        let className = "player-side";
        if(StoreGame.game.mode === GAME_MODE.LOCAL) {
            if(StoreGame.currentPlayerI === playerI) className += " turn";
        } else {
            if(StoreGame.currentPlayerI === playerI) className += " turn";
            if(StoreGame.otherPlayerI === playerI && !StoreGame.isMyTurn) className += " turn";
        }

        if(this.props.right) className += " right";

        let player = StoreGame.game.players[playerI];

        let usedWords = StoreGame.game.usedWords.reverse();

        let showSendMessage = StoreGame.game.chat && playerI === 0;

        return (
            <div className={className}>

                <div className="perks">
                    {player.perks[PERK__BLOCK]      && <PerkButton playerI={playerI} perkType={PERK__BLOCK}    focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>}
                    {player.perks[PERK__MUTATION]   && <PerkButton playerI={playerI} perkType={PERK__MUTATION} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>}
                    {player.perks[PERK__CAPTURE]    && <PerkButton playerI={playerI} perkType={PERK__CAPTURE}  focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>}
                    {player.perks[PERK__MORE_TIME]  &&
                        !!StoreGame.game.limits &&
                        StoreGame.game.limits.type !== GAME_TIME_LIMITS_TYPES.NONE &&
                    <PerkButton playerI={playerI} perkType={PERK__MORE_TIME}  focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>}

                    <div className={"chat-btn-wr"}>
                        {showSendMessage && <ChatBtn playerI={playerI} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME, FOCUS_LAYER__MESSAGE_SELECTOR]}/>}
                    </div>

                    {(playerI === 0 || StoreGame.game.mode === GAME_MODE.LOCAL) && <Surrender playerI={playerI} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>}
                </div>
                {!!StoreGame.game.limits && StoreGame.game.limits.type !== GAME_TIME_LIMITS_TYPES.NONE && <PlayerTimer timer={player.timer} playerI={playerI}/>}
                <PlayerScore score={player.score} playerI={playerI}/>
                <div className={"name-wr"}>
                    <div className={"name c" + playerI}>{StoreGame.getPlayerName(playerI)}</div>
                    {StoreGame.game.mode !== GAME_MODE.LOCAL && playerI === 1 && <BadgesButton playerI={playerI} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>}
                    {StoreGame.game.mode !== GAME_MODE.LOCAL && playerI === 0 && <BadgesButton playerI={playerI} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>}
                </div>
                {   (StoreGame.game.mode === GAME_MODE.NETWORK__WITH_FRIEND || StoreGame.game.mode === GAME_MODE.NETWORK__RANDOM) &&
                    this.props.right &&
                    <div className={"network-status " + StoreGame.networkOpponentStatus}>{StoreGame.networkOpponentStatus}</div>
                }
                {
                    StoreGame.showUsedWords &&
                    <ul className="words">
                        {usedWords.map(word => word.playerI === playerI && <li key={word.word}>{word.word}</li>)}
                    </ul>
                }
            </div>
        );
    }
})

export default PlayerSide;
