import React from "react";
import {observer} from "mobx-react";
import "../index.css";
import focusable from "../../../focus/_Focusable";
import StoreSounds from "../../../store/StoreSounds";
import {BADGES} from "../../../common/const";
import i18n from "../../../common/i18n";
import {__} from "../../../common/utils";
import StoreBadgeBoard from "../../../store/StoreBadgeBoard";
import StoreUser from "../../../store/StoreUser";
import StoreGame from "../../../store/StoreGame";

const Achieves = focusable(observer(class Achieves extends React.Component {

    componentDidMount() {

    }

    onCLick = () => {

        StoreSounds.playPls('click');

        StoreBadgeBoard.setUserData(StoreUser.user.name, StoreUser.user.badges);
        StoreBadgeBoard.show();

    }


    render() {


        let exists = 0;
        if(StoreUser.user && StoreUser.user.badges)
            Object.keys(StoreUser.user.badges).forEach(key => {
                if(StoreUser.user.badges[key] > 0)  exists++;
            });

        const width = 670 / 22 * exists;

        return (
            <div className={"achieves-wr focusable " + (this.props.focused ? "focused" : "")} onClick={this.onCLick}>
                <div className={"achieves-progress-wr"}>
                    <div className={"achieves-progress"} style={{width: width + "rem"}}>

                    </div>
                    <div className={"title"}>{i18n.nmenu_badges.title}</div>
                    <div className={"value"}>{__(i18n.nmenu_badges.value, {exists: exists, total: Object.keys(BADGES).length})}</div>
                </div>
            </div>

        );
    }
}))

export default Achieves;