import {extendObservable} from "mobx";
import {CHAT_MESSAGE_VISIBLE_TIME, FOCUS_LAYER__GAME, GAME_MODE, MENU_MODE__HIDE} from "../common/const";
import StoreApp from "./StoreApp";
import StoreGame from "./StoreGame";
import StoreFocus from "../focus/StoreFocus";

class StoreVideoPlayer {

    constructor() {
        extendObservable(this, {
            videoPlayerVisible: false,
            controlsVisible: false,
            currentChannelIndex: 0,
            currentChannelStream: null,
        });

        this.hls = null;

        /*this.streams = [
            "https://amssamples.streaming.mediaservices.windows.net/91492735-c523-432b-ba01-faba6c2206a2/AzureMediaServicesPromo.ism/manifest(format=m3u8-aapl-v3)",
            "https://b028.wpc.azureedge.net/80B028/Samples/a38e6323-95e9-4f1f-9b38-75eba91704e4/5f2ce531-d508-49fb-8152-647eba422aec.ism/Manifest(format=m3u8-aapl-v3)",
            "https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/index_0_av.m3u8",
        ];*/

        this.channels = [];
        this.lastWatchedIndex = null;

        this.playerStartTime = null;

    }

    setChannels(data) {
        if(data.channels.length > 0) {
            data.channels.forEach(channel => {
                if (channel.vod_channel) return;
                if (!channel.is_available) return;
                if (channel.parental_control_required) return;
                this.channels.push(channel);

                if(channel.id === data.lastWatchedId)
                    this.lastWatchedIndex = this.channels.length-1;
            });
        }
    }

    changeChannel(n) {
        let newChannelIndex = this.currentChannelIndex + n;
        if(newChannelIndex > this.channels.length-1)  newChannelIndex = 0;
        else if(newChannelIndex < 0) newChannelIndex = this.channels.length - 1;
        this.setChannel(newChannelIndex);
    }

    setChannel(n) {
        if(n > this.channels.length-1)  n = 0;
        this.currentChannelIndex = n;
        this.lastWatchedIndex = n;
        StoreApp.stvAppConnector.send('getStream', {objectId: this.channels[this.currentChannelIndex].id});
    }

    setNewStreamFromApp(stream) {
        this.currentChannelStream = stream;
    }

    setControlsVisible(f) {
        this.controlsVisible = f;
    }

    setVideoPlayerVisible(f) {
        this.videoPlayerVisible = f;
    }

    setPlayerStartTime(date) {
        this.playerStartTime = date;
    }

    get moveLeft() {
        let moveLeft = false;
        if(StoreGame.game && StoreGame.game.mode === GAME_MODE.LOCAL
            && StoreGame.currentPlayerI === 1
            && StoreApp.menuMode === MENU_MODE__HIDE)
            moveLeft = true;

        return moveLeft;
    }

}

export default new StoreVideoPlayer();