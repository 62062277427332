import React from "react";
import {observer} from "mobx-react";
import StoreNewWord from "../store/StoreNewWord";
import StoreGame from "../store/StoreGame";
import {BOARD_MODE__MOVES, BOARD_MODE__PERK, FOCUS_LAYER__GAME, GAME_MODE} from "../common/const";
import StorePerkCapture from "../store/StorePerkCapture";
import StorePerkBlock from "../store/StorePerkBlock";
import StorePerkMutation from "../store/StorePerkMutation";
import StoreFocus from "../focus/StoreFocus";
import StoreSounds from "../store/StoreSounds";
import focusable from "../focus/Focusable";
import StoreApp from "../store/StoreApp";

const Field = focusable(observer(class Field extends React.Component {
    constructor(props) {
        super(props);

        this.field = StoreGame.game.fields[this.props.fieldI];
    }

    onClick = () => {

        // если это сетевая игра и ход соперника - клик ничего не делает
        if(StoreGame.game.mode !== GAME_MODE.LOCAL && !StoreGame.isMyTurn) {
            StoreGame.setWrongTurn(true);
            setTimeout(() => StoreGame.setWrongTurn(false), 500);
            return;
        }

        if(!this.isFieldAvailable())   return;

        if(!StoreApp.clicksEnabled)    return;

        this._playSound();

        if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__GAME) return;

        if(StoreGame.boardMode === BOARD_MODE__MOVES) {
            StoreNewWord.addLetter(this.props.fieldI, this.field.letter, this.field.score, this.field.player);

            StoreGame.setFieldClicked(this.props.fieldI);
        }
        else if(StoreGame.boardMode === BOARD_MODE__PERK) {
            if(StorePerkBlock.trying) {
                StorePerkBlock.setFieldI(this.props.fieldI);
            } else if(StorePerkMutation.trying) {
                StorePerkMutation.setFieldI(this.props.fieldI);
            } else if(StorePerkCapture.trying) {
                StorePerkCapture.setFieldI(this.props.fieldI);
            }
        }
    }

    isFieldAvailable() {
        if(StoreGame.boardMode === BOARD_MODE__MOVES)
            return this.field.availableForMove.available;
        else if(StoreGame.boardMode === BOARD_MODE__PERK)
            return this.field.availableForPerk.available;
        return false;
    }

    focusable() {
        return this.isFieldAvailable();
    }

    _playSound() {
        console.log('_playSound');
        // for legacy browsers

        StoreSounds.playPls("click");
    }

    render() {
        //console.log('render Field');

        let letterClassName = "letter" + " " + this.props.fieldI;

        if(this.props.fieldI % 5 === 0 && this.field.player !== 0)                      letterClassName += " base0";
        else if((this.props.fieldI + 1) % 5 === 0 && this.field.player !== 1)           letterClassName += " base1";

        letterClassName += " bc"+this.field.player;

        let availabilityData = this.field.availableForMove;

        if(StoreGame.boardMode === BOARD_MODE__MOVES) {
            availabilityData = this.field.availableForMove;
        } else if(StoreGame.boardMode === BOARD_MODE__PERK) {
            availabilityData = this.field.availableForPerk;
        }

        if(this.field.clicked)  letterClassName += " clicked";

        if(this.props.focused)  letterClassName += " focused";

        if(availabilityData.available)  letterClassName += " focusable";

        if(this.field.pressed)      letterClassName += " pressed";
        //если это поле сейчас в режиме создания перка Мутаген
        if(StorePerkMutation.trying && StorePerkMutation.fieldI === this.props.fieldI)      letterClassName += " pressed";

        letterClassName += " ss";
        this.field.safeSidePlayer.map(ss => letterClassName += " ss"+ss);
        //-----------

        //визуализация внутренних уголков на ячейке
        let playerCornersBackgrounds = [];
        this.field.playerCorners.map(pc => playerCornersBackgrounds.push(<div className={"cb cb"+pc.pos+" bc"+pc.player} key={pc.pos}></div>));

        //визуализация штриховки на самой ячейке
        let unavailableClassName = "unavailable ss";
        availabilityData.safeSideAvailable.map(ss => unavailableClassName += " ss"+ss);

        //визуализация штриховки внутреннего угло на ячейке (сама ячейка доступна)
        let unavailableCornersBackgrounds = [];
        availabilityData.unavailableCorners.map(uc => unavailableCornersBackgrounds.push(<div className={"uac uac"+uc} key={uc}></div>));

        let perkBlockExists = false;
        if((StoreGame.boardMode === BOARD_MODE__PERK && this.props.focused) ||
            this.field.showPerkBlocked > 0 ||
            this.field.showPerkMutation > 0 ||
            this.field.showPerkCapture > 0
        )  perkBlockExists = true;

        let perkItemClassName = "show-perk";
        if(this.field.showPerkBlocked > 0) perkItemClassName += " block";
        else if(this.field.showPerkMutation > 0) perkItemClassName += " mutation";
        else if(this.field.showPerkCapture > 0) perkItemClassName += " capture";



        return (
            <div className="letter-wr focusable" onClick={this.onClick}>
                {playerCornersBackgrounds}
                <div className={letterClassName}>
                    <b>{this.field.letter}</b>
                    <i>{this.field.score}</i>
                    {unavailableCornersBackgrounds}
                    {!availabilityData.available && <div className={unavailableClassName}></div>}
                    {perkBlockExists && <div className={perkItemClassName}></div>}
                </div>
            </div>
        );
    }
}))

export default Field;