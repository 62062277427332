import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import {
    FOCUS_LAYER__POPUP_MESSAGE, KIBANA_EVENT_TYPE, NETWORK_CHALLENGE__WAITING_TIME
} from "../../common/const";
import PopupMessageBtn from "./PopupMessageBtn";
import StoreApp from "../../store/StoreApp";
import StoreFocus from "../../focus/StoreFocus";
import i18n from "../../common/i18n";
import {FOCUS_LAYER_DEFAULT_FOCUS} from "../../focus/StoreFocusBase";
import PopupMessageInput from "./PopupMessageInput";
import {__, sendPlayerCloseToKibana} from "../../common/utils";
import StoreGame from "../../store/StoreGame";
import StoreVideoPlayer from "../../store/StoreVideoPlayer";

const PopupMessage = observer(class PopupMessage extends React.Component {

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__POPUP_MESSAGE);
    }


    render() {
        //console.log('render PopupMessage');

        if(StoreApp.popupMessage === null)  return null;

        let buttons = [];

        //последняя кнопка в спике - буде автоматом реагирвать кликом на BACK
        let onBackButton = StoreApp.popupMessage.buttons.length - 1;

        StoreApp.popupMessage.buttons.forEach((button, i) => buttons.push(<PopupMessageBtn
            key={i}
            focusStore={StoreFocus}
            focusLayers={[FOCUS_LAYER__POPUP_MESSAGE]}
            btnCount={StoreApp.popupMessage.buttons.length}
            btnOrder={i+1}
            defaultFocused={i === 0}
            text={button.text}
            onBack={i === onBackButton}
            callback={() => {
                if(StoreApp.popupMessage.input && i === 0) {
                    button.callback(StoreApp.popupMessage.input.value);
                }
                else button.callback();
            }
            }/>));


        return (
            <div>
                <div className="popup-message-bg"></div>
                <div className="popup-message">
                    <div className="text" dangerouslySetInnerHTML={{__html: StoreApp.popupMessage.text}}></div>
                    {StoreApp.popupMessage.input && <PopupMessageInput ref={this.inputRef} value={StoreApp.popupMessage.input.value} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__POPUP_MESSAGE]}/>}
                    {buttons}
                    {StoreApp.popupMessage.loader && <div className="loader-popup"><div className="loadingio-spinner-dual-ball"><div className="ldio"><div></div><div></div><div></div></div></div></div>}
                </div>
            </div>


        );
    }
})

export default PopupMessage;

export function showAlert(text) {

    // чтобы фокус остался на попапе в случае обновления игры
    StoreGame.setTemporaryDoNotFocusGame(true);

    StoreApp.showPopupMessage(text, [
        {
            text: i18n.ok,
            callback: function() {
                StoreApp.hidePopupMessage();
                StoreGame.setTemporaryDoNotFocusGame(false);
                StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
            }
        }
    ]);
}

export function showConfirm(text, callback) {

    // чтобы фокус остался на попапе в случае обновления игры
    StoreGame.setTemporaryDoNotFocusGame(true);

    let close = () => {
        StoreApp.hidePopupMessage();
        StoreGame.setTemporaryDoNotFocusGame(false);
        StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
    };

    StoreApp.showPopupMessage(text, [
        {
            text: i18n.ok,
            callback: () => {
                callback();
                close();
            }
        },
        {
            text: i18n.cancel,
            callback: close
        }
    ]);
}

export function showPrompt(text, callback, startValue = '') {

    // чтобы фокус остался на попапе в случае обновления игры
    StoreGame.setTemporaryDoNotFocusGame(true);

    let close = () => {
        StoreApp.hidePopupMessage();
        StoreGame.setTemporaryDoNotFocusGame(false);
        StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
    };

    StoreApp.showPopupMessage(text, [
        {
            text: i18n.ok,
            callback: (newValue) => {
                callback(newValue);
                close();
            }
        },
        {
            text: i18n.cancel,
            callback: close
        }
    ], {
        value: startValue
    });
}

/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////

export function showExitConfirm() {
    // чтобы фокус остался на попапе в случае обновления игры
    StoreGame.setTemporaryDoNotFocusGame(true);

    StoreApp.showPopupMessage(i18n.exitConfirm.message, [
        {
            text: i18n.exitConfirm.exit,
            callback: function() {
                //отправляем в кибану время работы плейера, если он работает
                if(StoreVideoPlayer.videoPlayerVisible) {
                    sendPlayerCloseToKibana(KIBANA_EVENT_TYPE.PLAYER_CLOSE_ON_EXIT);
                }
                StoreApp.stvAppConnector.send('back');
                StoreGame.setTemporaryDoNotFocusGame(false);
            }
        },
        {
            text: i18n.exitConfirm.cancel,
            callback: function() {
                StoreApp.hidePopupMessage();
                StoreGame.setTemporaryDoNotFocusGame(false);
                StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
            }
        }
    ]);
}

export function showNetworkChallengeCode(code, onClose) {

    // чтобы фокус остался на попапе в случае обновления игры
    StoreGame.setTemporaryDoNotFocusGame(true);

    function onCloseCallback(){
        StoreApp.hidePopupMessage();
        StoreGame.setTemporaryDoNotFocusGame(false);
        StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
        onClose();
    }

    // создаем таймер для информирования юзера об истечении времени подключения к игре
    let tId = setTimeout(() => {
        onCloseCallback();
        showAlert(i18n.networkChallenge.expiredMessage);
    }, NETWORK_CHALLENGE__WAITING_TIME);

    StoreApp.showPopupMessage(__(i18n.networkChallenge.showCode, {code: code}), [

        {
            text: i18n.networkChallenge.cancel,
            callback: function() {
                clearTimeout(tId);
                onCloseCallback();
            }
        }
    ], null, true);
    return tId;
}

export function showWaitForRandomOpponent(onClose) {

    // чтобы фокус остался на попапе в случае обновления игры
    StoreGame.setTemporaryDoNotFocusGame(true);

    function onCloseCallback(){
        StoreApp.hidePopupMessage();
        StoreGame.setTemporaryDoNotFocusGame(false);
        StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
        onClose();
    }

    // создаем таймер для информирования юзера об истечении времени подключения к игре
    let tId = setTimeout(() => {
        onCloseCallback();
        showAlert(i18n.networkChallenge.expiredMessage);
    }, NETWORK_CHALLENGE__WAITING_TIME);

    StoreApp.showPopupMessage(__(i18n.networkChallenge.waitRandomOpponent, {dictionary: i18n.networkChallenge[StoreApp.dictionarySetting]}), [

        {
            text: i18n.networkChallenge.cancel,
            callback: function() {
                clearTimeout(tId);
                onCloseCallback();
            }
        }
    ],
        null, true);
    return tId;
}
