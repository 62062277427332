import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import CloseBtn from "./CloseBtn";
import i18n from "../../common/i18n";
import StoreFocus from "../../focus/StoreFocus";
import {
  FOCUS_LAYER__GAME,
  FOCUS_LAYER__GAME_TIMES_POPUP, GAME_MODE, GAME_TIME_LIMITS__GAME_DURATIONS,
  GAME_TIME_LIMITS__MOVE_DURATIONS,
  GAME_TIME_LIMITS_TYPES, MENU_MODE__COLD_START, MENU_MODE__HIDE, RESPONSE__ERROR, RESPONSE__SUCCESS
} from "../../common/const";
import {__, connectExistedGame, createNewLocalGame, getErrorText, remoteRequest} from "../../common/utils";
import * as mobx from "mobx"

import StoreApp from "../../store/StoreApp";
import StoreGame from "../../store/StoreGame";
import StoreGameTimeLimitsPopup from "../../store/StoreGameTimeLimitsPopup";
import GameTimeLimitsItem from "./GameTimeLimitsItem";
import StoreUser from "../../store/StoreUser";
import {showAlert} from "../popupmessage/PopupMessage";
import {FOCUS_LAYER_DEFAULT_FOCUS} from "../../focus/StoreFocusBase";

const GameTimeLimitsPopupMessage = observer(class GameTimeLimitsPopupMessage extends React.Component {

    constructor(props) {
      super(props);



      let waitingChallenges = {
        [GAME_TIME_LIMITS_TYPES.MOVE]: {},
        [GAME_TIME_LIMITS_TYPES.GAME]: {},
        [GAME_TIME_LIMITS_TYPES.NONE]: {},
      };

      GAME_TIME_LIMITS__MOVE_DURATIONS.forEach(duration => {
        waitingChallenges[GAME_TIME_LIMITS_TYPES.MOVE][duration] = false;
      });

      GAME_TIME_LIMITS__GAME_DURATIONS.forEach(duration => {
        waitingChallenges[GAME_TIME_LIMITS_TYPES.GAME][duration] = false;
      });

      waitingChallenges[GAME_TIME_LIMITS_TYPES.NONE] = false;

      this.state = {
        waitingChallenges: waitingChallenges
      };

    }

    componentDidMount() {

      // для случайного соперника - запрашиваем текущие активные челенджи
      if(StoreGameTimeLimitsPopup.gameMode === GAME_MODE.NETWORK__RANDOM) {
        StoreApp.setLoaderBigVisible(true);
        remoteRequest('getWaitingRandomChallenges', {
          lang: StoreApp.dictionarySetting,
        }).then(result => {
          StoreApp.setLoaderBigVisible(false);

          if (result.data.status === RESPONSE__SUCCESS) {
            let newWaitingChallenges = this.state.waitingChallenges;
            result.data.message.forEach(challenge => {
              if(typeof newWaitingChallenges[challenge.limits.type] !== 'undefined') {
                if(typeof challenge.limits.value !== 'undefined' && typeof newWaitingChallenges[challenge.limits.type][challenge.limits.value] !== 'undefined') {
                  newWaitingChallenges[challenge.limits.type][challenge.limits.value] = true;
                }
              }
            });
            this.setState({waitingChallenges: newWaitingChallenges});

          } else if (result.data.status === RESPONSE__ERROR) {

            showAlert(getErrorText(result.data.code));

          } else {
            throw "getWaitingRandomChallenges unknown response";
          }

        }).catch(function (err) {
          showAlert(i18n.alertConnectionError);
        }).finally(() => {
          StoreApp.enableUI();
        });
        //чтобы обновление текущей игры не отбирало фокус
        StoreGame.setTemporaryDoNotFocusGame(true);
      }

      //getWaitingRandomChallenges
      StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME_TIMES_POPUP);
    }

    componentWillUnmount() {
        StoreGame.setTemporaryDoNotFocusGame(false);
    }

    onClick = (limitType, limitValue) => {

      StoreGameTimeLimitsPopup.hide();
      StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);

      let limits = {type: limitType};
      if(!!limitValue)    limits.value = limitValue;

      StoreGameTimeLimitsPopup.continueCallback(limits);

    }



    render() {

        if(!StoreGameTimeLimitsPopup.visible)
            return null;

        return (
            <div className="game-times">
              <CloseBtn focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME_TIMES_POPUP]}/>

              <div className="title">{__(i18n.timeLimits.titles[StoreGameTimeLimitsPopup.gameMode])}</div>
              <div className="subtitle">{__(i18n.timeLimits.subtitles.default)}</div>

              <div className="game-times-items-list" style={{top: "260rem", left: "170rem"}}>
                  {GAME_TIME_LIMITS__MOVE_DURATIONS.map((duration, index) => {
                      return <GameTimeLimitsItem defaultFocused={index === 0} limitType={GAME_TIME_LIMITS_TYPES.MOVE} limitValue={duration} available={this.state.waitingChallenges[GAME_TIME_LIMITS_TYPES.MOVE][duration]} onClick={this.onClick} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME_TIMES_POPUP]}/>
                  })}
              </div>

              <div className="game-times-items-list" style={{top: "260rem", right: "170rem"}}>
                  {GAME_TIME_LIMITS__GAME_DURATIONS.map(duration => {
                      return <GameTimeLimitsItem limitType={GAME_TIME_LIMITS_TYPES.GAME} limitValue={duration} available={this.state.waitingChallenges[GAME_TIME_LIMITS_TYPES.GAME][duration]} onClick={this.onClick} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME_TIMES_POPUP]}/>
                  })}
              </div>

              <div className="game-times-items-list" style={{top: "570rem", right: "420rem"}}>
                  <GameTimeLimitsItem limitType={GAME_TIME_LIMITS_TYPES.NONE} available={this.state.waitingChallenges[GAME_TIME_LIMITS_TYPES.NONE]} onClick={this.onClick} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME_TIMES_POPUP]}/>
              </div>

            </div>

        );
    }
})

export default GameTimeLimitsPopupMessage;
