import {extendObservable} from "mobx";
import StoreApp from "./StoreApp";
import {DICTIONARY} from "../common/const";


class StoreHowToPlay {

    constructor() {
        extendObservable(this, {
            visible: false,
            activePage: 0,
            pageCount: 7
        });
    }



    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    setActivePage(n) {
        this.activePage = n;
    }

    nextPage() {
        if(this.activePage < this.pageCount-1)
            this.activePage++;
    }

    prevPage() {
        if(this.activePage > 0)
            this.activePage--;
    }

}

export default new StoreHowToPlay();