import React from "react";
import {observer} from "mobx-react";
import StoreNewWord from "../../store/StoreNewWord";
import StoreGame from "../../store/StoreGame";
import {
    FOCUS_LAYER__GAME, FOCUS_LAYER__KBOARD
} from "../../common/const";
import StoreFocus from "../../focus/StoreFocus";
import StoreSounds from "../../store/StoreSounds";
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";

const WordClearBtn = focusable(observer(class WordClearBtn extends React.Component {

    onClick = () => {
        if(!StoreApp.clicksEnabled)    return;

        StoreSounds.playPls("click");
        StoreNewWord.clear();
        StoreNewWord.setMessage();
        StoreGame.clearFieldsPressed();
        // если не сбросить фокус - из-за скрытия зафокушенной кнопки фокус на экране пропадает
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);

    }

    render() {
        //console.log('render WordClearBtn');



        return (
            <div className={"btn focusable cancel " + (this.props.focused ? " focused" : "")} onClick={this.onClick}></div>
        );
    }
}))

export default WordClearBtn;