import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import StoreFocus from "../../focus/StoreFocus";
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";
import {MOVE_FOCUS_DIRECTION} from "../../focus/StoreFocusBase";

const PopupMessageInput = focusable(observer(class PopupMessageInput extends React.Component {

    constructor(props) {
        super(props);

    }

    onKeydown = (e) => {
        if(e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.ENTER) {
            StoreFocus.moveFocus(MOVE_FOCUS_DIRECTION.DOWN);
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeydown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeydown);
    }

    handleChange(event) {
        StoreApp.popupMessage.input.value = event.target.value;
    }


    /*defaultFocused() {
        return this.props.defaultFocused;
    }*/

    render() {

        if(!StoreApp.popupMessage)  return null;

        let className = "";

        className += this.props.focused ? " focused" : "";

        return (
            <input type="text" className={className} value={StoreApp.popupMessage.input.value} onChange={this.handleChange.bind(this)}/>
        );
    }
}))

export default PopupMessageInput;