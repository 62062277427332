import {extendObservable} from "mobx";
import firebase from "firebase/app";
import "firebase/auth";

import {
    DICTIONARY,
    MENU_MODE__HIDE,
    RESPONSE__ERROR,
    RESPONSE__SUCCESS, LOCAL_STORAGE__LOCAL_GAME_ID, KIBANA_EVENT_TYPE, GAME_MODE, FOCUS_LAYER__VIDEO_PLAYER
} from "../common/const";
import i18n from "../common/i18n";
import StoreFocus from "../focus/StoreFocus";
import {StvAppConnector} from "../common/StvAppConnector";

import { version }  from '../../package.json'
import StoreHowToPlay from "./StoreHowToPlay";
import {getRandomDid, remoteRequest} from "../common/utils";
import StoreUser from "./StoreUser";
import {showAlert} from "../components/popupmessage/PopupMessage";
import StoreGame from "./StoreGame";
import StoreVideoPlayer from "./StoreVideoPlayer";
import {FOCUS_LAYER_DEFAULT_FOCUS} from "../focus/StoreFocusBase";


class StoreApp {

    stvAppConnector = null;
    version = version;
    firstStartApp = true;
    timeCorrection = 0;

    constructor() {
        extendObservable(this, {
            dictionarySetting: DICTIONARY.RU,

            menuMode: MENU_MODE__HIDE,
            prevMenuMode: MENU_MODE__HIDE,

            popupMessage: null,
            badgePopupMessage: null,

            clicksEnabled: true,

            loaderVisible: false,
            loaderBigVisible: false,

            waitingChallenges: {},

            did: 'empty',

            user: {},

            firebaseSubscriptions: {}
        });

        let did = localStorage.getItem('did');
        if(!did) {
            did = getRandomDid();
            localStorage.setItem('did', did);
        }
        this.did = did;

        this.changeLang(DICTIONARY.RU, true, true);

        this.stvAppConnector = new StvAppConnector(
            {
                name: 'Poleslov',
                env: process.env.REACT_APP_ENV,
                version: this.version
            }
        );

        if(localStorage.getItem('notFirstStartApp'))   this.firstStartApp = false;
        else localStorage.setItem('notFirstStartApp', '1');


    }

    changeLang(newLang, ui, dictionary) {
        //корректируем константу укр языка
        if(newLang === 'ua')  newLang = DICTIONARY.UA;

        //проверяем, корректный ли передан язык - есть ли в системе такой
        let good = false;
        Object.values(DICTIONARY).forEach(lang => {if(lang === newLang)  good = true});

        //если в системе есть язык - устаналиваем его. Иначе - остается дефолтовый
        if(good) {
            if(ui)          i18n.setLanguage(newLang);
            if(dictionary)  this.dictionarySetting = newLang;
        }
    }

    /*setUser(id, user) {
        this.user = Object.assign({id}, user);
    }*/

    setDid(did) {
        this.did = did;
    }

    readLocalGameId() {
        return localStorage.getItem(LOCAL_STORAGE__LOCAL_GAME_ID);
    }

    writeLocalGameId(v) {
        localStorage.setItem(LOCAL_STORAGE__LOCAL_GAME_ID, v);
    }

    removeLocalGameId() {
        localStorage.removeItem(LOCAL_STORAGE__LOCAL_GAME_ID);
    }

    setMenuMode(m=null) {
        if(m === null) {
            this.setMenuMode(this.prevMenuMode);
            return;
        }
        this.prevMenuMode = this.menuMode;
        this.menuMode = m;
    }

    addWaitingChallenge(id, code, timerId) {
        this.waitingChallenges[id] = {
            id: id,
            code: code,
            timerId: timerId
        }
    }

    removeWaitingChallenge(id) {
        if(this.waitingChallenges[id])
            clearTimeout(this.waitingChallenges[id].timerId);

        delete this.waitingChallenges[id];
    }

    showPopupMessage(text, buttons, input= null, loader = null) {
        /*
            buttons: [
                {
                    text: "aasd",
                    callback: function()
                },
                {
                    text: "aasd",
                    callback: function()
                }
            ]
        */
        this.popupMessage = {
            text: text,
            buttons: buttons,
            input: input,
            loader: loader
        }
    }

    hidePopupMessage() {
        this.popupMessage = null;
    }

    showBadgePopupMessage(badges) {
        this.badgePopupMessage = {
            badges: badges
        }
    }

    hideBadgePopupMessage() {
        this.badgePopupMessage = null;
    }


    enableClicks() {
        this.clicksEnabled = true;
    }

    disableClicks() {
        this.clicksEnabled = false;
    }

    disableUI() {
        StoreFocus.disableFocus();
        this.disableClicks();
    }

    enableUI() {
        StoreFocus.enableFocus();
        this.enableClicks();
    }

    setLoaderVisible(f) {
        this.loaderVisible = f;
    }

    setLoaderBigVisible(f) {
        this.loaderBigVisible = f;
    }

    doTimeCorrection(serverTime) {
        this.timeCorrection = Date.now() - serverTime;
        console.log('Time correction, ms: ', this.timeCorrection);
    }


    hideLoaders() {
        this.setLoaderVisible(false);
        this.setLoaderBigVisible(false);
    }

    firestoreSubscribe(path, callback) {
        if(!this.firebaseSubscriptions[path]) {
            this.firebaseSubscriptions[path] = firebase.app().firestore().doc(path)
                .onSnapshot(docSnapshot => callback(docSnapshot),
                    err => {
                        callback(null);
                        console.log(`Encountered error: ${err}`, path, new Date());
                    });
        } else {
        }
    }

    checkFirestoreSubscription(path) {
        return !!this.firebaseSubscriptions[path];
    }


    firestoreUnsubscribe(path) {
        if(typeof this.firebaseSubscriptions[path] === "function") {
            // отписываемся
            this.firebaseSubscriptions[path]();
            // удалеям отписывалку
            delete this.firebaseSubscriptions[path];
        } else {
        }
    }

    async loadPlayerFromFirebase(store) {

        try {
            let result = await remoteRequest('getUser', {
                did: store.did,
                name: StoreUser.megogoUserName ? StoreUser.megogoUserName : null
            });
            if(result.data.status === RESPONSE__ERROR) {

            } else if(result.data.status === RESPONSE__SUCCESS) {


                this.doTimeCorrection(result.data.serverTime);

                let idToken = null;

                try {
                    // авторизуем пользователя по токену, полученному с бека
                    await firebase.auth().signInWithCustomToken(result.data.message.token);
                    // получаем id токен для авторизованного юзера
                    idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh*/ true);

                    if(idToken) StoreUser.setToken(idToken);
                    else return null;
                } catch(e) {
                    console.log('Exeption:', e);
                    return null;
                }

                ////////////////////////////
                StoreUser.setId(result.data.message.id);

                this.firestoreSubscribe('users/'+StoreUser.id, docSnapshot => {
                    console.log('=== User updated ===');
                    if(docSnapshot && docSnapshot.data()) {
                        let user = docSnapshot.data();

                        if(StoreUser.user !== null) {
                            let newBadges = StoreUser.searchForNewBadges(StoreUser.user, user);
                            if(newBadges.length) {
                                StoreGame.setTemporaryDoNotFocusGame(true);

                                // если сейчас фокус на контролах плейера - сбрасываем его на предыдущий слой, иначе после скрытия попапа будут проблемы
                                if(StoreFocus.currentFocusLayer === FOCUS_LAYER__VIDEO_PLAYER) {
                                    StoreVideoPlayer.setControlsVisible(false);
                                    StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.DEFAULT)
                                }
                                this.showBadgePopupMessage(newBadges);

                                try {
                                    this.stvAppConnector.sendKibana(KIBANA_EVENT_TYPE.GAME_NEW_BADGE, {
                                        badges: Object.keys(user.badges).length
                                    });
                                } catch (e) {
                                }


                            }
                        }



                        StoreUser.setUser(user);
                        /*console.log("badges:", user.badges);
                        console.log("statistics:", user.statistics);*/
                    }

                });
            }

            console.log('Token OK, ', new Date());
            return true;
        }catch (e) {
            return null;
        }
    }


}

export default new StoreApp();
