import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import CloseBtn from "./CloseBtn";
import i18n from "../../common/i18n";
import StoreFocus from "../../focus/StoreFocus";
import {BADGES, FOCUS_LAYER__BADGES_BOARD} from "../../common/const";
import BadgeItem from "./BadgeItem";
import {__} from "../../common/utils";
import StoreBadgeBoard from "../../store/StoreBadgeBoard";
import * as mobx from "mobx"
import StoreGame from "../../store/StoreGame";

const BadgesBoard = observer(class BadgesBoard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visibleRowMin: 0,
            visibleRowMax: 1,
            gamesItemsScroll: 0
        }
    }

    componentDidMount() {
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__BADGES_BOARD);
        //чтобы обновление текущей игры не отбирало фокус у борды
        StoreGame.setTemporaryDoNotFocusGame(true);
    }

    componentWillUnmount() {
        StoreGame.setTemporaryDoNotFocusGame(false);
    }

    onBadgeFocused = (index) => {

        let height = 230;
        let rowIndex = Math.floor(index / 4);
        if(rowIndex > this.state.visibleRowMax) {
            this.setState({
                visibleRowMin: this.state.visibleRowMin + 1,
                visibleRowMax: this.state.visibleRowMax + 1,
                gamesItemsScroll: this.state.gamesItemsScroll - height
            });
        } else if(rowIndex < this.state.visibleRowMin) {
            this.setState({
                visibleRowMin: this.state.visibleRowMin - 1,
                visibleRowMax: this.state.visibleRowMax - 1,
                gamesItemsScroll: this.state.gamesItemsScroll + height
            });
        }
    }


    render() {

        if(!StoreBadgeBoard.visible)
            return null;

        let badgesData = [];
        let exists = 0;

        Object.keys(BADGES).forEach(key => {
            const count = (StoreBadgeBoard.badges && StoreBadgeBoard.badges[key]) ? StoreBadgeBoard.badges[key] : 0;
            badgesData.push({
                id: key,
                title: i18n.badges[key].title,
                description: i18n.badges[key].description,
                icon: BADGES[key].icon,
                order: BADGES[key].order,
                count: count
            });

            if(count) exists++;
        });



        badgesData.sort(function(a,b) {return a.order - b.order});


        return (
            <div className="badges-board">
                <CloseBtn focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__BADGES_BOARD]}/>
                <div className="username">{StoreBadgeBoard.username}</div>
                <div className="desc">{__(i18n.badges_exists_from_total, {exists: exists, total: badgesData.length})}</div>
                <div className="badges-list-wr">
                    <div className="badges-list"  style={{top: this.state.gamesItemsScroll+"rem"}}>
                        {badgesData.map((badgeData, index) => <BadgeItem onFocused={this.onBadgeFocused} key={index} index={index} opacity={Math.floor(index / 4) < this.state.visibleRowMin} badgeData={badgeData} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__BADGES_BOARD]}/>)}
                    </div>
                </div>

            </div>

        );
    }
})

export default BadgesBoard;