import React from "react";
import {observer} from "mobx-react";
import i18n from "../common/i18n";

const ButtonHint = observer(class PerkButtonHint extends React.Component {

    render() {
        //console.log('render PerkButtonHint');


        return (
            <div className="button-hint">
                <div className="title">{i18n.buttonHint[this.props.buttonType].title}</div>
                <div className="text">{i18n.buttonHint[this.props.buttonType].text}</div>
            </div>

        );
    }
})

export default ButtonHint;