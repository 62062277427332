import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";

const BadgePopupMessageBtn = focusable(observer(class BadgePopupMessageBtn extends React.Component {

    onKeydown = (e) => {
        if( e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.RETURN ||
            e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.HID_BACK) {
            this.props.callback();
        }
    }


    componentDidMount() {
        document.addEventListener('keydown', this.onKeydown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeydown);
    }


    defaultFocused() {
        return true;
    }

    render() {

        let className = "btn focusable badge-popup-btn";


        className += this.props.focused ? " focused" : "";

        return (
            <div className={className} onClick={this.props.callback}>{this.props.text}</div>
        );
    }
}))

export default BadgePopupMessageBtn;