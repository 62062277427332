import React from "react";
import {observer} from "mobx-react";
import {
    FOCUS_LAYER__GAME,
    FOCUS_LAYER__MENU,
    MENU_MODE__IN_GAME
} from "../common/const";
import StoreFocus from "../focus/StoreFocus";
import StoreSounds from "../store/StoreSounds";
import focusable from "../focus/Focusable";
import StoreApp from "../store/StoreApp";

const MenuButton = focusable(observer(class MenuButton extends React.Component {

    onClick = () => {
        if(!StoreApp.clicksEnabled)    return;

        StoreSounds.playPls("click");
        StoreApp.setMenuMode(MENU_MODE__IN_GAME);
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MENU);
    }

    defaultFocused() {
        return true;
    }

    render() {
        //console.log('render MenuButton');

        return (
            <div className={"menu-button focusable " + (this.props.focused ? " focused" : "") +" focusable"} onClick={this.onClick}></div>
        );
    }
}))

export default MenuButton;