import {extendObservable} from "mobx";
import StoreGame from "./StoreGame";

class StoreNewWord {

    constructor() {
        extendObservable(this, {
            word: [],
            message: ''
        });

    }

    get text() {
        let text = '';
        this.word.map(e => text += e.letter);
        return text;
    }

    get score() {
        let score = 0;
        this.word.map(e => score += e.score);
        return score;
    }

    clear() {

        this.word.clear();

       /* if(this.game !== null)
        for(let i=0;i<this.game.fields.length;i++) {
            if(this.game.fields[i].selected)
                this.game.fields[i].selected = false;
        }*/
    }

    setMessage(s = "") {
        this.message = s;
        if(s !== "") {
            setTimeout(() => this.setMessage(""), 3000);
        }
    }

    addLetter(fieldI, letter, score, player) {
        let foundLetterIndex = this.word.findIndex(e => e.fieldI === fieldI);
        if(this.word.length > 0 && foundLetterIndex === this.word.length-1) {
            this.removeLastLetter();
            return true;
        } else if(foundLetterIndex !== -1){
            return false;
        };

        this.word.push({
            fieldI: fieldI,
            letter: letter,
            score: score,
            player: player
        });

        StoreGame.setFieldPressed(fieldI);

        return true;
    }


    removeLastLetter() {
        if(this.word.length === 0)  return;
        StoreGame.setFieldUnPressed(this.word[this.word.length-1].fieldI);
        this.word.pop();
    }



}

export default new StoreNewWord();