import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../store/StoreGame";
import {GAME_STATUS__WAITING_MOVE_0, GAME_STATUS__WAITING_MOVE_1} from "../common/const";

const Light = observer(class Light extends React.Component {

    render() {
        //console.log('render Light');

        if(StoreGame.game === null)
            return null;


        let light0ClassName = '', light1ClassName = '';
        if(StoreGame.game.status === GAME_STATUS__WAITING_MOVE_0) {
            light0ClassName = 'light0 show';
            light1ClassName = 'light1 hide';
        }
        else if(StoreGame.game.status === GAME_STATUS__WAITING_MOVE_1) {
            light0ClassName = 'light0 hide';
            light1ClassName = 'light1 show';
        }
        else
            return null;

        return (
            <div className="light">
                <div className={light0ClassName}></div>
                <div className={light1ClassName}></div>
            </div>

        );
    }
})

export default Light;