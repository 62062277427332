export const GAME_MODE = {
    LOCAL: 0,
    NETWORK__RANDOM: 1,
    NETWORK__WITH_FRIEND: 2
};

export const GAME_STATUS__WAITING_MOVE_0 = "WAITING_MOVE_0";
export const GAME_STATUS__WAITING_MOVE_1 = "WAITING_MOVE_1";
export const GAME_STATUS__WAITING_MOVE_BOTH = "WAITING_MOVE_BOTH";
export const GAME_STATUS__FINISHED = "FINISHED";

export const BOARD_MODE__MOVES = 0;
export const BOARD_MODE__PERK = 1;

export  const PERK__CAPTURE = 0;
export  const PERK__MUTATION = 1;
export  const PERK__BLOCK = 2;
export  const PERK__MORE_TIME = 3;

export  const NETWORK_OPPONENT_STATUS = {
    ONLINE: "online",
    OFFLINE: "offline"
};

export  const MESSAGE_ERROR = 'error';
export  const MESSAGE_SUCCESS = 'success';

export  const RESPONSE__ERROR = 'error';
export  const RESPONSE__SUCCESS = 'success';

export  const RESPONSE__ERROR_CODES = {
    UNKNOWN: -1,
    GAME_NOT_CREATED: 0,
    WRONG_GAME: 1,
    GAME_ERROR__WRONG_TURN: 20,
    GAME_ERROR__WRONG_LETTERS: 21,
    GAME_ERROR__USED_WORD: 22,
    GAME_ERROR__WRONG_WORD: 23,
    GAME_ERROR__PERK_VALIDATION: 24,
    GAME_ERROR__NETWORK_CHALLENGE_NOT_CREATED: 100,
    GAME_ERROR__NETWORK_CHALLENGE_NOT_ACCEPTED_OR_CREATED: 101,
    GAME_ERROR__NETWORK_CHALLENGE_WRONG_CODE: 102,

    NOT_AUTHORIZED_REQUEST: 200,
    WRONG_USER_ID: 201,

};

export  const DICTIONARY = {
    RU: 'ru',
    UA: 'uk',
    EN: 'en',
};

export  const ALPHABET = {
    [DICTIONARY.RU]: {
        ORIGINAL: 'абвгдежзийклмнопрстуфхцчшщъыьэюя',
        TRANSLIT: 'abvgdejziyklmnoprstufxchw01234q5'
    },
    [DICTIONARY.UA]: {
        ORIGINAL: 'абвгґдеєжзиіїйклмнопрстуфхцчшщьюя\'',
        TRANSLIT: 'abvg0de1jz2i34klmnoprstufxchw56qy7'
    },
    [DICTIONARY.EN]: {
        ORIGINAL: 'abcdefghijklmnopqrstuvwxyz',
        TRANSLIT: 'abcdefghijklmnopqrstuvwxyz'
    },
};

export  const DICTIONARY_URL = {
    [DICTIONARY.RU]: 'https://s8.vcdn.biz/static/138132781/dic-ru.txt',
    [DICTIONARY.UA]: 'https://s6.vcdn.biz/static/95021921/dic-uk.txt',
    [DICTIONARY.EN]: 'https://s6.vcdn.biz/static/143963231/merged-prepared.txt'
}

export  const DICTIONARY_URL_WEBOS = {
    [DICTIONARY.RU]: 'http://s8.vcdn.biz/static/138132781/dic-ru.txt',
    [DICTIONARY.UA]: 'http://s6.vcdn.biz/static/95021921/dic-uk.txt',
    [DICTIONARY.EN]: 'http://s6.vcdn.biz/static/143963231/merged-prepared.txt'
}

export  const LOCAL_STORAGE__LOCAL_GAME_ID = 'gameId';


export  const MENU_MODE__HIDE = 0;
export  const MENU_MODE__COLD_START = 1;
export  const MENU_MODE__IN_GAME = 2;
export  const MENU_MODE__VICTORY = 3;
export  const MENU_MODE__NETWORK = 4;

export  const FOCUS_LAYER__GAME = 0;
export  const FOCUS_LAYER__MENU = 1;
export  const FOCUS_LAYER__KBOARD = 2;
export  const FOCUS_LAYER__POPUP_MESSAGE = 3;
export  const FOCUS_LAYER__HOW_TO_PLAY = 4;
export  const FOCUS_LAYER__NMENU = 5;
export  const FOCUS_LAYER__BADGES_BOARD = 6;
export  const FOCUS_LAYER__MESSAGE_SELECTOR = 7;
export  const FOCUS_LAYER__VIDEO_PLAYER = 8;
export  const FOCUS_LAYER__GAME_TIMES_POPUP = 9;

export  const FOCUS_LAYER_DEFAULT_FOCUS = {
    DEFAULT: 0,
    SAVED: 1
};

export  const NETWORK_CHALLENGE__TYPE = {
    RANDOM: 0,
    WITH_FRIEND: 1,
};

export  const NETWORK_CHALLENGE__STATUS = {
    WAITING: 0,
    ACCEPTED: 1,
    REJECTED: 2,
};

export  const GAME_TIME_LIMITS_TYPES = {
    NONE: 'none',
    MOVE: 'move',
    GAME: 'game',
};

export  const GAME_TIME_LIMITS__MOVE_DURATIONS = [
    1 * 60 * 1000,
    2 * 60 * 1000,
    5 * 60 * 1000
];

export  const GAME_TIME_LIMITS__GAME_DURATIONS = [
    5 * 60 * 1000,
    15 * 60 * 1000,
    60 * 60 * 1000
];



export  const BADGES = {
    "pervoe_slovo": {order: 0, icon: "first-word.png"},
    "pervaya_pobeda": {order: 1, icon: "first-win.png"},
    "sprinter": {order: 2, icon: "sprinter.png"},
    "znatok": {order: 3, icon: "nerd.png"},
    "umnik": {order: 4, icon: "smart.png"},
    "genii": {order: 5, icon: "genius.png"},
    "combo": {order: 6, icon: "combo.png"},
    "super_combo": {order: 7, icon: "super-combo.png"},
    "bez_shansov": {order: 8, icon: "no-chances.png"},
    "urojai": {order: 9, icon: "harvest.png"},
    "super_urojai": {order: 10, icon: "super-harvest.png"},
    "skriaga": {order: 11, icon: "scroodge.png"},
    "zolotoj_schet": {order: 12, icon: "golden-score.png"},
    "marafonets": {order: 13, icon: "marathon.png"},
    "bronzovaya_pobeda": {order: 14, icon: "bronze-win.png"},
    "serebryanaya_pobeda": {order: 15, icon: "silver-win.png"},
    "zolotaya_pobeda": {order: 16, icon: "golden-win.png"},
    "samurai": {order: 17, icon: "samurai.png"},
    "strateg": {order: 18, icon: "strategy.png"},
    "desperado": {order: 19, icon: "desperado.png"},
    "chempion": {order: 20, icon: "campion.png"},
    "triumfator": {order: 21, icon: "triumf.png"}
};

export  const CHAT_MESSAGE_VISIBLE_TIME = 1000 * 10;

//======================
export  const NETWORK_CHALLENGE__WAITING_TIME = 1000 * 60 * 5;// milliseconds, 5 minutes
export  const FUNCS_REGION = process.env.REACT_APP_REGION;
export  const USE_EMULATOR = false;


export  const USE_KIBANA = process.env.REACT_APP_ENV === 'prod';

export  const KIBANA_EVENT_TYPE = {
    GAME_START: 'GAME_START',
    GAME_CONTINUE: 'GAME_CONTINUE',
    GAME_FINISH: 'GAME_FINISH',
    GAME_ERROR: 'GAME_ERROR',

    GAME_NEW_BADGE: "GAME_NEW_BADGE",
    GAME_NEW_CHAT_MESSAGE: "GAME_NEW_CHAT_MESSAGE",

    PLAYER_START: "PLAYER_START",
    PLAYER_CLOSE: "PLAYER_CLOSE",
    PLAYER_CLOSE_ON_OPEN_TV: "PLAYER_CLOSE_ON_OPEN_TV",
    PLAYER_CLOSE_ON_EXIT: "PLAYER_CLOSE_ON_EXIT",


}

//======================





