import React from "react";
import "./index.css";

class Paginator extends React.Component {

    render() {
        //console.log('render HowToPlay');

        let points = [];
        for(let i=0;i<this.props.count;i++) {
            points.push(<div className={"point " + (this.props.active === i ? " active" : "")}></div>);
        }

        return (
            <div className="paginator">
                <div className="points-wr">
                    {points}
                </div>
            </div>

        );
    }
};

export default Paginator;