import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import StoreGame from "../../store/StoreGame";
import {connectExistedGame, createNewLocalGame} from "../../common/utils";
import StoreFocus from "../../focus/StoreFocus";
import {
    FOCUS_LAYER__GAME,
    FOCUS_LAYER__MENU, GAME_MODE, KIBANA_EVENT_TYPE,
    MENU_MODE__COLD_START,
    MENU_MODE__HIDE,
    MENU_MODE__NETWORK
} from "../../common/const";
import focusable from "../../focus/Focusable";
import i18n from "../../common/i18n";
import StoreSounds from "../../store/StoreSounds";
import StoreApp from "../../store/StoreApp";
import StoreBadgeBoard from "../../store/StoreBadgeBoard";
import StoreUser from "../../store/StoreUser";
import StoreGameTimeLimitsPopup from "../../store/StoreGameTimeLimitsPopup";

const ItemLocalGame = focusable(observer(class ItemLocalGame extends React.Component {


    onCLick = () => {

        if(!StoreApp.clicksEnabled)    return;

        if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__MENU) return;

        StoreSounds.playPls('click');

        const localGameId = StoreApp.readLocalGameId();

        // если есть локальная игра - переходим в нее
        if(localGameId !== null) {
            //если игра есть в localStorage - пытаемся в нее подключится
            //на вреям подключения - висит сплеш
            StoreApp.setMenuMode(MENU_MODE__HIDE);
            StoreApp.setLoaderBigVisible(true);
            connectExistedGame(localGameId);
            StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
        } else {
            // иначе - стартуем новую игру

            //StoreSounds.playPls('click');

            /*StoreGameTimeLimitsPopup.setGameMode(GAME_MODE.LOCAL);
            StoreGameTimeLimitsPopup.setCall
            StoreGameTimeLimitsPopup.show();*/
            StoreGameTimeLimitsPopup.askUserTimeLimitsAndContinue(GAME_MODE.LOCAL, (limits) => {
                StoreGame.setGame(null);
                StoreApp.setMenuMode(MENU_MODE__HIDE);
                StoreApp.setLoaderBigVisible(true);
                StoreApp.disableUI();
                createNewLocalGame(limits).then(function(value) {
                    StoreApp.setLoaderBigVisible(false);
                    StoreApp.enableUI();
                    // выполнение
                    if(value !== null) {
                        StoreGame.setGameJustStarted();
                        StoreApp.writeLocalGameId(value);
                        connectExistedGame(value);
                        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
                    } else {
                        StoreApp.setMenuMode(MENU_MODE__COLD_START);
                    }
                });
            })

            /*StoreGame.setGame(null);
            StoreApp.setMenuMode(MENU_MODE__HIDE);
            StoreApp.setLoaderBigVisible(true);
            StoreApp.disableUI();
            createNewLocalGame().then(function(value) {
                StoreApp.setLoaderBigVisible(false);
                StoreApp.enableUI();
                // выполнение
                if(value !== null) {
                    StoreGame.setGameJustStarted();
                    StoreApp.writeLocalGameId(value);
                    connectExistedGame(value);
                    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
                } else {
                    StoreApp.setMenuMode(MENU_MODE__COLD_START);
                }
            });*/
        }


    }

    defaultFocused() {
        return true;
    }

    render() {
        //console.log('render ItemLocalGame');


        let className = StoreApp.menuMode === MENU_MODE__NETWORK ? '' : 'focusable ';

        if(this.props.focused) className += ' focused';

        return (<li className={className} onClick={this.onCLick}>{i18n.mainMenu.newGame}</li>);
    }
}))

export default ItemLocalGame;
