import React from "react";
import {observer} from "mobx-react";
import StoreNewWord from "../../store/StoreNewWord";
import StoreGame from "../../store/StoreGame";
import {getRightFieldI, getRightPlayerI, getWordTranslit, remoteRequest} from "../../common/utils"
import {
    FOCUS_LAYER__GAME,
    RESPONSE__ERROR, RESPONSE__ERROR_CODES,
    RESPONSE__SUCCESS
} from "../../common/const";
import StoreFocus from "../../focus/StoreFocus";
import StoreSounds from "../../store/StoreSounds";
import focusable from "../../focus/Focusable";
import i18n from "../../common/i18n";
import StoreApp from "../../store/StoreApp";
import {showAlert} from "../popupmessage/PopupMessage";

const WordOkBtn = focusable(observer(class WordOkBtn extends React.Component {

    onCLick = () => {


        if(!StoreApp.clicksEnabled)    return;

        let translit = getWordTranslit(StoreNewWord.text, StoreGame.getGameDictionaryLang());

        let wordHashFromDic = StoreGame.dictionary.data[translit];
        if(typeof wordHashFromDic === "undefined") {
            StoreSounds.playPls("word-is-not-ok");
            StoreNewWord.setMessage(i18n.responseErrors[RESPONSE__ERROR_CODES.GAME_ERROR__WRONG_WORD]);
            return;
        }

        StoreSounds.playPls("click");

        let fieldsI = [];
        StoreNewWord.word.map(f => {
            fieldsI.push(getRightFieldI(f.fieldI));
        });

        StoreApp.setLoaderVisible(true);
        StoreApp.disableUI();
        remoteRequest('makeMove',
            {
                game: StoreGame.gameId,
                player: getRightPlayerI(StoreGame.currentPlayerI),
                word: fieldsI.join(':'),
                wordhash: wordHashFromDic
            }).then((result) => {
                if(result.data.status === RESPONSE__ERROR) {
                    StoreSounds.playPls("word-is-not-ok");
                    StoreNewWord.setMessage(i18n.responseErrors[result.data.code]);
                } else if(result.data.status === RESPONSE__SUCCESS) {
                    StoreNewWord.setMessage();
                    StoreNewWord.clear();
                    StoreGame.clearFieldsPressed();
                }
        }).catch(function(err) {
            showAlert(i18n.alertConnectionError);
        }).finally(function () {
            StoreApp.setLoaderVisible(false);
            StoreApp.enableUI();
        });
    }

    render() {
        //console.log('render WordOkBtn');

        return (
            <div className={"btn focusable ok " + (this.props.focused ? " focused" : "")} onClick={this.onCLick}></div>
        );
    }
}))

export default WordOkBtn;