import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__MENU, MENU_MODE__NETWORK} from "../../common/const";
import focusable from "../../focus/Focusable";
import i18n from "../../common/i18n";
import StoreHowToPlay from "../../store/StoreHowToPlay";
import StoreSounds from "../../store/StoreSounds";
import StoreApp from "../../store/StoreApp";

const ItemRules = focusable(observer(class ItemRules extends React.Component {

    onCLick = () => {

        if(!StoreApp.clicksEnabled)    return;

        if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__MENU) return;

        StoreSounds.playPls('click');

        StoreHowToPlay.show();
        StoreHowToPlay.setActivePage(0);

    }

    render() {
        //console.log('render ItemRules');
        let className = StoreApp.menuMode === MENU_MODE__NETWORK ? '' : 'focusable ';

        if(this.props.focused) className += 'focused';

        return (<li className={className} onClick={this.onCLick}>{i18n.mainMenu.rules}</li>);
    }
}))

export default ItemRules;