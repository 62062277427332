import React from "react";
import StoreFocus from "../../focus/StoreFocus";
import {
    FOCUS_LAYER__MENU,
    FOCUS_LAYER_DEFAULT_FOCUS,
} from "../../common/const";
import focusable from "../../focus/Focusable";
import StoreSounds from "../../store/StoreSounds";
import StoreApp from "../../store/StoreApp";
import StoreBadgeBoard from "../../store/StoreBadgeBoard";

const CloseBtn = focusable(class CloseBtn extends React.Component {


    onCLick = () => {
        StoreSounds.playPls('click');

        if(!StoreApp.clicksEnabled)    return;

        StoreBadgeBoard.hide();
        StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
    }

    defaultFocused() {
        return true;
    }

    render() {
        //console.log('render CloseBtn');

        return (
            <div className={"btn focusable close " + (this.props.focused ? " focused" : "")} onClick={this.onCLick}></div>
        );

    }
})

export default CloseBtn;