import "./index.css";
import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../../store/StoreGame";
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__MENU} from "../../common/const";
import StoreSounds from "../../store/StoreSounds";
import StoreHowToPlay from "../../store/StoreHowToPlay";

const BadgeItem = focusable(observer(class BadgeItem extends React.Component {
    constructor(props) {
        super(props);

    }

    onClick = () => {
        console.log('click!');
        if(!StoreApp.clicksEnabled)    return;

        //if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__MENU) return;

        StoreSounds.playPls('click');

    }

    componentDidUpdate() {

    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if(this.props.focused && !prevProps.focused) {
            this.props.onFocused(this.props.index);
        }
        return null;
    }

    render() {
        //console.log('render PerkButton');

        //if(StoreGame.game === null) return null;

        let style = this.props.badgeData.count > 0 ? {background: "url(/img/badges/1x/"+this.props.badgeData.icon+")", backgroundSize: "cover"} : {}


        return (
            <div className={"badge-item focusable " + (this.props.focused ? "focused" : "") + (this.props.opacity ? "opacity" : "")}>
                <div className={"icon"} style={style}>
                    {this.props.badgeData.count > 1 && <div className="count">{this.props.badgeData.count}</div>}
                </div>
                <div className="title">{this.props.badgeData.title}</div>
                {this.props.focused && <div className="description">{this.props.badgeData.description}</div>}
            </div>
        );
    }
}))

export default BadgeItem;