import "./index.css";
import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../../store/StoreGame";
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__MENU, GAME_TIME_LIMITS_TYPES} from "../../common/const";
import StoreSounds from "../../store/StoreSounds";
import StoreHowToPlay from "../../store/StoreHowToPlay";
import {__} from "../../common/utils";
import i18n from "../../common/i18n";

const GameTimeLimitsItem = focusable(observer(class GameTimeLimitsItem extends React.Component {
    constructor(props) {
        super(props);

    }

    defaultFocused() {
        return this.props.defaultFocused;
    }

    onClick = () => {
        console.log('click!');
        if(!StoreApp.clicksEnabled)    return;

        this.props.onClick(this.props.limitType, this.props.limitValue);

        //if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__MENU) return;

        StoreSounds.playPls('click');

    }

    componentDidUpdate() {

    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if(this.props.focused && !prevProps.focused) {
            //this.props.onFocused(this.props.index);
        }
        return null;
    }


    render() {

        //console.log('render GameTimeLimitsItem');

        let minutes = typeof this.props.limitValue !== 'undefined' ? Math.round(this.props.limitValue / 60 / 1000) : '';
        let text = __(i18n.timeLimits.variants[ this.props.limitType], {minutes: minutes});


            return (
            <div className={"game-times-item focusable " + (this.props.focused ? "focused " : "") + (this.props.available? "available " : "")} onClick={this.onClick}>
                {text}
            </div>
        );
    }
}))


export default GameTimeLimitsItem;
