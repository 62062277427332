import {extendObservable} from "mobx";
import {CHAT_MESSAGE_VISIBLE_TIME} from "../common/const";

class StoreChat {

    constructor() {
        extendObservable(this, {
            lastMessages: {
                0: null,
                1: null
            },

            lastMessagesVisible: {
                0: false,
                1: false
            },

            messageSelectorVisible: false,
        });

        this.lastViwedMessagesDate = {
            0: null,
            1: null
        }


    }

    showLastMessage(playerI) {
        this.lastMessagesVisible[playerI] = true;
        setTimeout(() => this.lastMessagesVisible[playerI] = false, CHAT_MESSAGE_VISIBLE_TIME);
    }

    clear() {
        this.lastMessages = {0: null, 1: null};
        this.lastMessagesVisible = {0: false, 1: false};
        this.lastViwedMessagesDate = {0: null, 1: null};
    }

    setMessageSelectorVisible(f) {
        console.log("setMessageSelectorVisible:", f);
        this.messageSelectorVisible = f;
    }

}

export default new StoreChat();