import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import focusable from "../../focus/Focusable";

const VideoPlayerControl = focusable(observer(class VideoPlayerControl extends React.Component {

    componentDidMount() {

    }

    onClick = (event) => {
        this.props.onClick(this.props.type, event);
    }

    defaultFocused() {
        return this.props.defaultFocused === true;
    }

    render() {
        //console.log('render Loader');

        let className = "";
        if(this.props.type === 0)   className = "prev";
        else if(this.props.type === 1)   className = "next";
        else if(this.props.type === 2)   className = "tv";
        else if(this.props.type === 3)   className = "close";

        className += " control focusable " + (this.props.focused ? " focused" : "");

        return (
            <div className={className} onClick={this.onClick} dangerouslySetInnerHTML={{__html: this.props.text}}></div>
        );
    }
}))

export default VideoPlayerControl;