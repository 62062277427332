import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import Hls from "hls.js"
import focusable from "../../focus/Focusable";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__GAME, FOCUS_LAYER__VIDEO_PLAYER} from "../../common/const";
import StoreVideoPlayer from "../../store/StoreVideoPlayer";
import StoreSounds from "../../store/StoreSounds";
import StoreGame from "../../store/StoreGame";

const VideoPlayerFrame = focusable(observer(class VideoPlayer extends React.Component {

    componentDidMount() {
        console.log("VideoPlayerFrame:componentDidMount", this.props.stream);
        if(this.props.stream !== null)
            this._loadAndPlayStream(this.props.stream.src);


    }

    componentDidUpdate(prevProps) {
        //console.log("VideoPlayerFrame:componentDidUpdate", this.props.stream);
        if(this.props.stream !== null && (prevProps.stream === null || this.props.stream.src !== prevProps.stream.src))
            this._loadAndPlayStream(this.props.stream.src);
    }

    _loadAndPlayStream(url) {
        console.log("_loadAndPlayStream", url);
        if(url === null)    return;
        if (Hls.isSupported() && this.player) {

            if(StoreVideoPlayer.hls) {
                StoreVideoPlayer.hls.destroy();
                console.log("this.Hls.destroy();");
            }
            StoreVideoPlayer.hls = null;

            if(StoreVideoPlayer.hls === null) {
                StoreVideoPlayer.hls = new Hls({
                    maxMaxBufferLength: 10,

                    capLevelToPlayerSize: false,
                    nudgeOffset: 1,
                    enableWorker: false,
                    startLevel: 1,

                });
                StoreVideoPlayer.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    try {
                        let lowestIndex = -1;
                        StoreVideoPlayer.hls.levels.forEach((level, index) => {
                            if(lowestIndex === -1)  lowestIndex = index;
                            else if(StoreVideoPlayer.hls.levels[lowestIndex].bitrate > StoreVideoPlayer.hls.levels[index].bitrate)
                                lowestIndex = index;
                        });
                        StoreVideoPlayer.hls.currentLevel = lowestIndex
                    } catch (e) {
                    }

                    this.player.play();
                });
            }


            url = url.replace("http://", "https://");
            StoreVideoPlayer.hls.loadSource(url);
            StoreVideoPlayer.hls.attachMedia(this.player);
        }
    }

    onClick = (event) => {
        console.log("onClick", event);
        if(StoreFocus.currentFocusLayer === FOCUS_LAYER__VIDEO_PLAYER)  return;
        StoreSounds.playPls("click");
        StoreVideoPlayer.setControlsVisible(true);
        setTimeout(() => {
            StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__VIDEO_PLAYER);
            // чтобы фокус остался на плейере в случае обновления игры
            StoreGame.setTemporaryDoNotFocusGame(true);
        }, 300);
    }



    render() {
        //console.log('render Loader');

        if(!StoreVideoPlayer.videoPlayerVisible)    return null;

        let className = "video-player-frame focusable" + (this.props.focused ? " focused" : "");

        return (
            <div className={className} onClick={this.onClick}>
                <video
                    preload="none"
                    className="videoCanvas"
                    ref={player => (this.player = player)}
                    autoPlay={true}
                />
            </div>
        );
    }
}))

export default VideoPlayerFrame;