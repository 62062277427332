import "./index.css";
import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../../store/StoreGame";
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";
import {RESPONSE__SUCCESS} from "../../common/const";
import StoreSounds from "../../store/StoreSounds";
import {remoteRequest} from "../../common/utils";
import StoreBadgeBoard from "../../store/StoreBadgeBoard";

const BadgesButton = focusable(observer(class BadgesButton extends React.Component {


    onClick = async () => {
        if(!StoreApp.clicksEnabled)    return;

        StoreSounds.playPls('click');

        StoreApp.setLoaderBigVisible(true);
        await this.getDataAndShowBoard();
        StoreApp.setLoaderBigVisible(false);


    }

    async getDataAndShowBoard() {
        let result = await remoteRequest("getUserBadges", {docId: StoreGame.game.players[this.props.playerI].user.id});

        if(result && result.data.status === RESPONSE__SUCCESS) {
            StoreBadgeBoard.setUserData(StoreGame.getPlayerName(this.props.playerI), result.data.message.badges);
            StoreBadgeBoard.show();
        }
    }

    render() {

        if(StoreGame.game === null) return null;

        return (
            <div className={"badges-btn focusable " + (this.props.focused ? " focused" : "")} onClick={this.onClick}></div>
        );
    }
}))

export default BadgesButton;