import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../store/StoreGame";
import StoreApp from "../store/StoreApp";

const LoaderBig = observer(class LoaderBig extends React.Component {

    render() {
        //console.log('render LoaderBig');

        if(!StoreApp.loaderBigVisible)
            return null;

        return (
            <div className="loader-big-wr">
                <div className="loader-big">
                    <div className="loadingio-spinner-dual-ball">
                        <div className="ldio">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
})

export default LoaderBig;