import React from "react";
import Light from "./Light";
import Kboard from "./kboard/Kboard";
import TopMessage from "./TopMessage";
import WordBar from "./wordbar/WordBar";
import PlayerSide from "./PlayerSide";
import Board from "./Board";
import StoreGame from "../store/StoreGame";
import {
    FOCUS_LAYER__GAME, FOCUS_LAYER__MENU,
    GAME_STATUS__FINISHED
} from "../common/const";
import {observer} from "mobx-react";
import MenuButton from "./MenuButton";
import Confeti from "./Confeti";
import StoreFocus from "../focus/StoreFocus";
import StoreChat from "../store/StoreChat";

const Game = observer(class Game extends React.Component {

    render() {

        if(!StoreGame.game) return null;

        let middleClassName = "middle";
        let headerClassName = "header";
        if(StoreGame.game !== null && StoreGame.game.status === GAME_STATUS__FINISHED) {
            middleClassName += " victory";
            headerClassName += " victory";
        }

        return (
            <div className={"game"}>
                {   StoreGame.game !== null &&
                    StoreGame.game.status === GAME_STATUS__FINISHED &&
                    StoreGame.game.victory.player === StoreGame.currentPlayerI
                    ? <Confeti/> : ''}
                <Light/>
                <Kboard/>
                {StoreChat.messageSelectorVisible && <div className="message-selector-bg"></div>}

                <div id="top">
                    <MenuButton focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__GAME]}/>
                    <div className={headerClassName}>
                        <TopMessage/>
                        <WordBar/>
                    </div>


                </div>
                <div id="main">
                    <PlayerSide playerI={0}/>
                    <div className={middleClassName}>
                        <Board/>
                    </div>
                    <PlayerSide playerI={1} right={true}/>
                </div>

            </div>
        );
    }
})

export default Game;