import React from "react";
import {observer} from "mobx-react";
import "../index.css";
import focusable from "../../../focus/_Focusable";
import StoreSounds from "../../../store/StoreSounds";

const NMenuBtn = focusable(observer(class NMenuBtn extends React.Component {

    onCLick = () => {

        StoreSounds.playPls('click');
        this.props.onClick();

    }

    defaultFocused() {
        return this.props.defaultFocused;
    }

    render() {
        return (
            <div className={"btn focusable " + this.props.btnClass + (this.props.focused ? " focused" : "")} onClick={this.onCLick}>

            </div>
        );
    }
}))

export default NMenuBtn;