import {extendObservable, reaction} from "mobx";

import {BOARD_MODE__MOVES, FOCUS_LAYER__GAME, FOCUS_LAYER__KBOARD} from "../common/const";
import {PERK__MUTATION} from "../common/const";
import {BOARD_MODE__PERK} from "../common/const";
import * as utils from "../common/utils";
import StoreGame from './StoreGame'
import StoreFocus from "../focus/StoreFocus";
import StoreApp from "./StoreApp";
import {getRightFieldI, getRightPlayerI} from "../common/utils";

class StorePerkMutation {

    constructor() {
        extendObservable(this, {
            trying: false,
            fieldI: null,
            letter: null,
            showMutationKeyboard: false
        });

        this.needField = reaction(
            () => this.trying,
            trying => {
                console.log('needField');
                if(trying)
                    StoreGame.setBoardMode(BOARD_MODE__PERK);
                else {
                    StoreGame.setBoardMode(BOARD_MODE__MOVES);
                    this.setShowMutationKeyboard(false);
                }
            }
        );

        this.needChooseLetter = reaction(
            () => this.fieldI,
            fieldI => {
                console.log('needChooseLetter');
                if(fieldI !== null) {
                    StoreGame.setBoardMode(BOARD_MODE__MOVES);
                    this.setShowMutationKeyboard(true);
                    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__KBOARD);
                }
            }
        );

        this.needSendPerk = reaction(
            () => this.letter,
            letter => {
                if(this.letter !== null) {
                    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
                    StoreApp.setLoaderVisible(true);
                    utils.usePerk(StoreGame.gameId, getRightPlayerI(StoreGame.currentPlayerI), {
                        id: PERK__MUTATION,
                        field: getRightFieldI(this.fieldI),
                        letter: this.letter
                    }, this);

                    this.setShowMutationKeyboard(false);
                }
            }
        );
    }

    toggleTrying() {
        let temp = !this.trying;
        StoreGame.clearAllPerks();
        this.trying = temp;
    }

    setFieldI(f) {
        this.fieldI = f;
    }

    setLetter(l) {
        this.letter = l;
    }

    setShowMutationKeyboard(f) {
        this.showMutationKeyboard = f;
    }

    clear() {
        this.trying = false;
        this.fieldI = null;
        this.letter = null;
    }

}

export default new StorePerkMutation();