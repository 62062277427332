import StoreApp from "../store/StoreApp";
import {KIBANA_EVENT_TYPE, USE_KIBANA} from "./const";
import {getUrlParams} from "./utils";
import { get }         from "lodash";
import StoreUser from "../store/StoreUser";
import i18n from "./i18n";

export class StvAppConnector {
    kibana = {}
    events = {}
    config = {}

    startWithGame = null

    parentAppData = {
        keys: {
            UP: 38,
            RIGHT: 39,
            DOWN: 40,
            LEFT: 37,
            RETURN: 8,
            ENTER: 13,

            NUMERIC_ZERO: 96,
            NUMERIC_ONE: 97,
            NUMERIC_TWO: 98,
            NUMERIC_THREE: 99,
            NUMERIC_FOUR: 100,
            NUMERIC_FIVE: 101,
            NUMERIC_SIX: 102,
            NUMERIC_SEVEN: 103,
            NUMERIC_EIGHT: 104,
            NUMERIC_NINE: 105,

            ZERO: 48,
            ONE: 49,
            TWO: 50,
            THREE: 51,
            FOUR: 52,
            FIVE: 53,
            SIX: 54,
            SEVEN: 55,
            EIGHT: 56,
            NINE: 57,

        }
    }

    constructor(config) {
        const urlParams = getUrlParams(window.location.search);
        this.kibana = Object.assign(urlParams, config);

        if(urlParams.gameId)    this.startWithGame = urlParams.gameId;

        window.addEventListener('error', (err) => this.sendError('window.onerror', err), false)
        window.addEventListener('message', ({data}) => {
            if (data.method && data.method.substr(0, 8) === 'landing:') {
                const eventName = data.method.substr(8);
                this.emit(eventName, data.data);
            }
        }, false);
    }

    setParentAppData = (data)  => {
        this.parentAppData = data;
        // если это первый вход - устанавливаем умолчательный язык на основе языка мегого
        //if(StoreApp.firstStartApp && data.lang)
        StoreApp.changeLang(data.lang, true, true);

        // записываем DID устройства
        if(data.info.deviceID)
            StoreApp.setDid(data.info.deviceID);
        // если из мегого приехал ник - сохраняем его
        if(this.parentAppData.nickname && this.parentAppData.nickname !== 'Guest')
            StoreUser.setMegogoUserName(this.parentAppData.nickname);
    }

    send = (method, payload) =>  {
        if (method !== 'state:set' && method !== 'kibana:log') {
            this.sendKibana('method:' + method, { payload });
        }

        console.log("postMessage:", 'landing:' + method);
        window.parent.postMessage({
            method: 'landing:' + method,
            data: payload,
        }, '*');
    };

    emit = (name, ...args) => {
        if (this.events && this.events[name]) {
            this.events[name].forEach((cb) => cb.apply(this, args));
        }
        return this;
    };


    on = (name, cb) => {
        if (!this.events) this.events = {};
        if (!this.events[name]) this.events[name] = [];
        this.events[name].push(cb);
        return cb;
    };

    sendKibana = (type, payload = {}) => {
        if (!USE_KIBANA) return;
        payload.rnd = Math.random();
        let sendData = {};
        Object.assign(sendData, this.kibana);
        Object.assign(sendData, payload);
        if(StoreApp.did && payload) Object.assign(sendData, {device_id: StoreApp.did});

        this.send('kibana:log', {
            method: 'post',
            data: { text: type, data: JSON.stringify(sendData) }
        });
    };

    sendError = (prefix, err, payload = {}) => {
        return this.sendKibana(KIBANA_EVENT_TYPE.GAME_ERROR, {
            name: this.kibana.name,
            scope: {
                prefix,
                payload,
            },
            errorContent: get(err, 'stack') || get(err, 'error.stack'),
            errorLine:    get(err, 'lineno'),
            errorCol:     get(err, 'colno'),
            errorMessage: get(err, 'message', err),
        });
    };

    //getLanguage = () => Tr[this.kibana.lang] || Tr;

}
