import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import StoreFocus from "../../focus/StoreFocus";
import {
    FOCUS_LAYER__GAME,
    FOCUS_LAYER__MENU,
    FOCUS_LAYER_DEFAULT_FOCUS,
    MENU_MODE__HIDE
} from "../../common/const";
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";
import StoreSounds from "../../store/StoreSounds";

const MenuCloseBtn = focusable(observer(class MenuCloseBtn extends React.Component {


    onCLick = () => {

        if(!StoreApp.clicksEnabled)    return;

        if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__MENU) return;

        StoreSounds.playPls('click');

        StoreApp.setMenuMode(MENU_MODE__HIDE)
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
    }

    render() {
        //console.log('render Menu CloseBtn');

        return (
            <div className={"btn focusable close " + (this.props.focused ? " focused" : "")} onClick={this.onCLick}></div>
        );

    }
}))

export default MenuCloseBtn;