import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import StoreGame from "../../store/StoreGame";
import {
    FOCUS_LAYER__GAME,
    FOCUS_LAYER__MESSAGE_SELECTOR,
} from "../../common/const";
import StoreFocus from "../../focus/StoreFocus";
import StoreSounds from "../../store/StoreSounds";
import focusable from "../../focus/Focusable";
import ButtonHint from "../ButtonHint";
import StoreApp from "../../store/StoreApp";
import StoreChat from "../../store/StoreChat";

const ChatBtn = focusable(observer(class ChatBtn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
            showMessagesSelector: false
        }
    }

    onClick = () => {

        //if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__GAME) return;

        if(!StoreApp.clicksEnabled)    return;

        if(!this.focusable())   return;

        StoreSounds.playPls("click");

        StoreChat.setMessageSelectorVisible(!StoreChat.messageSelectorVisible);

        if(StoreChat.messageSelectorVisible) {
            setTimeout(() => StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__MESSAGE_SELECTOR), 300);
            // чтобы фокус остался на плейере в случае обновления игры
            StoreGame.setTemporaryDoNotFocusGame(true);
        }
        else {
            StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
            StoreGame.setTemporaryDoNotFocusGame(false);
        }

    }

    focusable() {
        return true;
    }

    render() {
        //console.log('render Surrender');

        if(StoreGame.game === null) return null;

        let className = 'chat-btn focusable';

        if(this.props.focused)  className += ' focused';

        //if(this.state.clicked)  className += " clicked";
        if(StoreChat.messageSelectorVisible)    className += " pressed";


        return (
            <div className={className} onClick={this.onClick} >
                {this.props.focused && <ButtonHint buttonType="send-message"/>}
            </div>
        );
    }
}))

export default ChatBtn;