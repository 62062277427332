import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import {__} from "../../common/utils";
import StoreFocus from "../../focus/StoreFocus";
import {
    DICTIONARY,
    FOCUS_LAYER__MENU, MENU_MODE__NETWORK
} from "../../common/const";
import focusable from "../../focus/Focusable";
import i18n from "../../common/i18n";
import StoreSounds from "../../store/StoreSounds";
import StoreApp from "../../store/StoreApp";
import {showAlert} from "../popupmessage/PopupMessage";

const ItemDictionary = focusable(observer(class ItemDictionary extends React.Component {


    onCLick = () => {

        if(!StoreApp.clicksEnabled)    return;

        if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__MENU) return;

        StoreSounds.playPls('click');

        let newLang = DICTIONARY.RU;
        if(StoreApp.dictionarySetting === DICTIONARY.RU)    newLang = DICTIONARY.UA;
        else if(StoreApp.dictionarySetting === DICTIONARY.UA)    newLang = DICTIONARY.EN;
        else if(StoreApp.dictionarySetting === DICTIONARY.EN)    newLang = DICTIONARY.RU;

        StoreApp.changeLang(newLang, false, true);
        showAlert(__(i18n.changeDictionaryAlert.message, {lang: i18n.changeDictionaryAlert[newLang]}));
    }

    render() {
        //console.log('render ItemDictionary');

        let className = StoreApp.menuMode === MENU_MODE__NETWORK ? '' : 'focusable ';
        let langTitle = i18n.mainMenu.dictionaryRu;
        if(StoreApp.dictionarySetting === DICTIONARY.RU)    langTitle = i18n.mainMenu.dictionaryRu;
        else if(StoreApp.dictionarySetting === DICTIONARY.UA)    langTitle = i18n.mainMenu.dictionaryUa;
        else if(StoreApp.dictionarySetting === DICTIONARY.EN)    langTitle = i18n.mainMenu.dictionaryEn;

        if(this.props.focused) className += ' focused';

        return (<li className={className} onClick={this.onCLick}>

                {i18n.mainMenu.dictionary} <span className="on">{langTitle}</span>
            </li>
        );
    }
}))

export default ItemDictionary;
