import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../store/StoreGame";
import {FOCUS_LAYER__GAME, GAME_MODE, PERK__BLOCK, PERK__CAPTURE, PERK__MUTATION} from "../common/const";
import StorePerkMutation from "../store/StorePerkMutation";
import StoreFocus from "../focus/StoreFocus";
import StorePerkBlock from "../store/StorePerkBlock";
import StorePerkCapture from "../store/StorePerkCapture";
import StoreSounds from "../store/StoreSounds";
import focusable from "../focus/Focusable";
import ButtonHint from "./ButtonHint";
import StoreApp from "../store/StoreApp";
import {showConfirm, showExitConfirm} from "./popupmessage/PopupMessage";
import i18n from "../common/i18n";
import {getRightPlayerI, remoteRequest} from "../common/utils";

const Surrender = focusable(observer(class Surrender extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false
        }
    }

    onClick = () => {

        if(StoreFocus.currentFocusLayer !== FOCUS_LAYER__GAME) return;

        if(!StoreApp.clicksEnabled)    return;

        if(!this.focusable())   return;

        StoreSounds.playPls("click");

        showConfirm(i18n.surrenderConfirm, () => {
            StoreApp.setLoaderVisible(true);
            StoreApp.disableUI();
            remoteRequest('surrender', {
                game: StoreGame.gameId,
                player: getRightPlayerI(StoreGame.currentPlayerI)
            }).finally(() => {
                StoreApp.setLoaderVisible(false);
                StoreApp.enableUI();
            })
        });
    }

    focusable() {
        if(StoreGame.game === null) return false;
        let player = StoreGame.game.players[this.props.playerI];
        return StoreGame.currentPlayerI === this.props.playerI
    }

    render() {
        //console.log('render Surrender');

        if(StoreGame.game === null) return null;


        let player = StoreGame.game.players[this.props.playerI];

        let className = 'surrender';

        if(StoreGame.currentPlayerI === this.props.playerI) className += ' focusable';
        if(this.props.focused)  className += ' focused';

        if(this.state.clicked)  className += " clicked";


        return (
            <div className={className} onClick={this.onClick}>
                {this.props.focused && <ButtonHint buttonType="surrender"/>}
            </div>
        );
    }
}))

export default Surrender;
