import {extendObservable, reaction} from "mobx";

import {BOARD_MODE__MOVES, PERK__BLOCK, PERK__MORE_TIME} from "../common/const";
import {BOARD_MODE__PERK} from "../common/const";
import * as utils from "../common/utils";
import StoreGame from './StoreGame'
import StoreApp from "./StoreApp";
import {getRightFieldI, getRightPlayerI} from "../common/utils";

class StorePerkMoreTime {

    constructor() {
        extendObservable(this, {
            trying: false,
        });


        this.needSendPerk = reaction(
        () => this.trying,
        field => {
                if (this.trying) {
                    StoreApp.setLoaderVisible(true);
                    utils.usePerk(StoreGame.gameId, getRightPlayerI(StoreGame.currentPlayerI), {
                        id: PERK__MORE_TIME
                    }, this);
                }
            }
        );
    }

    toggleTrying() {
        let temp = !this.trying;
        StoreGame.clearAllPerks();
        this.trying = temp;
    }

    setFieldI(f) {
        return;
    }


    clear() {
        this.trying = false;
    }
}

export default new StorePerkMoreTime();
