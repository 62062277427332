import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import MessageBtn from "./MessageBtn";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__MESSAGE_SELECTOR} from "../../common/const";
import i18n from "../../common/i18n";
import StoreGame from "../../store/StoreGame";

const MessageSelector = observer(class MessageSelector extends React.Component {

    constructor(props) {
        super(props);


    }

    componentDidMount() {

    }

    getMessageItems() {
        return i18n.chat_messages.map((value, index) => <MessageBtn key={index} defaultFocused={index === 0} focusStore={StoreFocus} messageId={index} focusLayers={[FOCUS_LAYER__MESSAGE_SELECTOR]}/>);
    }


    render() {
        //console.log('render PopupMessage');


        return (

            <div className="messages-wr">
                {this.getMessageItems()}
            </div>
        );
    }
})

export default MessageSelector;
