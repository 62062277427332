import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import focusable from "../../focus/Focusable";
import StoreVideoPlayer from "../../store/StoreVideoPlayer";
import StoreFocus from "../../focus/StoreFocus";
import {FOCUS_LAYER__VIDEO_PLAYER, KIBANA_EVENT_TYPE} from "../../common/const";
import StoreSounds from "../../store/StoreSounds";
import StoreGame from "../../store/StoreGame";
import StoreApp from "../../store/StoreApp";

const VideoPlayerOpenBtn = focusable(observer(class VideoPlayerOpenBtn extends React.Component {

    componentDidMount() {

    }

    onClick = () => {
        StoreSounds.playPls("click");
        StoreVideoPlayer.setVideoPlayerVisible(true);
        StoreVideoPlayer.setControlsVisible(true);
        StoreVideoPlayer.setPlayerStartTime(new Date());
        //отправляем в кибану событие открытия плейера
        try {
            StoreApp.stvAppConnector.sendKibana(KIBANA_EVENT_TYPE.PLAYER_START);
        } catch (e) {
        }

        // чтобы фокус остался на плейере в случае обновления игры
        StoreGame.setTemporaryDoNotFocusGame(true);
        setTimeout(() => StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__VIDEO_PLAYER), 300);
    }


    render() {
        console.log('render VideoPlayerOpenBtn');

        if(StoreVideoPlayer.videoPlayerVisible) return null;

        let className = "open-player-btn focusable" + (this.props.focused ? " focused" : "");

        if(StoreVideoPlayer.moveLeft)
            className += " left";

        return (
            <div className={className} onClick={this.onClick}></div>
        );
    }
}))

export default VideoPlayerOpenBtn;