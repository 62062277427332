import {extendObservable} from "mobx";


class StoreBadgeBoard {

    constructor() {
        extendObservable(this, {
            visible: false,
            username: "",
            badges: {}
        });
    }


    setUserData(username, badges) {
        this.username = username;
        this.badges = badges;
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }



}

export default new StoreBadgeBoard();