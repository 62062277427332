import React from "react";
import {observer} from "mobx-react";
import "../index.css";
import NMenuBtn from "./NMenuBtn";
import NMenuGameItem from "./NMenuGameItem";
import StoreUser from "../../../store/StoreUser";
import {
    connectExistedGame, getErrorText,
    remoteRequest
} from "../../../common/utils";
import i18n from "../../../common/i18n";
import {
    showAlert,
    showNetworkChallengeCode,
    showPrompt,
    showWaitForRandomOpponent
} from "../../popupmessage/PopupMessage";
import StoreApp from "../../../store/StoreApp";
import {
    FOCUS_LAYER__GAME,
    FOCUS_LAYER__NMENU,
    GAME_MODE,
    GAME_STATUS__FINISHED,
    GAME_STATUS__WAITING_MOVE_0, GAME_STATUS__WAITING_MOVE_1,
    GAME_STATUS__WAITING_MOVE_BOTH,
    KIBANA_EVENT_TYPE,
    NETWORK_CHALLENGE__STATUS,
    NETWORK_CHALLENGE__TYPE,
    RESPONSE__ERROR,
    RESPONSE__SUCCESS
} from "../../../common/const";
import StoreFocus from "../../../focus/StoreFocus";
import {FOCUS_LAYER_DEFAULT_FOCUS} from "../../../focus/StoreFocusBase";
import StoreGame from "../../../store/StoreGame";
import Achieves from "./Achieves";
import StoreGameTimeLimitsPopup from "../../../store/StoreGameTimeLimitsPopup";

const NMenu = observer(class NMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visibleMin: 0,
            visibleMax: 1,
            gamesItemsScroll: 10
        }
    }


    onGameItemFocused = (index) => {
        let height = 90;
        if(index > this.state.visibleMax) {
            this.setState({
                visibleMin: this.state.visibleMin + 1,
                visibleMax: this.state.visibleMax + 1,
                gamesItemsScroll: this.state.gamesItemsScroll - height
            });
        } else if(index < this.state.visibleMin) {
            this.setState({
                visibleMin: this.state.visibleMin - 1,
                visibleMax: this.state.visibleMax - 1,
                gamesItemsScroll: this.state.gamesItemsScroll + height
            });
        }

    }

    _waitForOpponent = (newChallenge) => {
        const challengePath = 'networkChallenges/'+newChallenge.id;

        let timerId = null;
        // показываем алерт с кодом
        if(newChallenge.type === NETWORK_CHALLENGE__TYPE.RANDOM) {
            timerId = showWaitForRandomOpponent(() => {
                // юзер сам отказался от вызова - отменяем подписки и реджектим челендж
                StoreApp.firestoreUnsubscribe(challengePath);
                StoreApp.removeWaitingChallenge(newChallenge.id);
                this.rejectFriendNetworkGame(newChallenge.id);
            });
        } else if(newChallenge.type === NETWORK_CHALLENGE__TYPE.WITH_FRIEND) {
            timerId = showNetworkChallengeCode(newChallenge.code, () => {
                // юзер сам отказался от вызова - отменяем подписки и реджектим челендж
                StoreApp.firestoreUnsubscribe(challengePath);
                StoreApp.removeWaitingChallenge(newChallenge.id);
                this.rejectFriendNetworkGame(newChallenge.id);
            });
        }

        StoreApp.addWaitingChallenge(newChallenge.id, newChallenge.code, timerId);
        StoreApp.firestoreSubscribe(challengePath, docSnapshot => {
            console.log('=== networkChallenge updated ===');
            let challenge = docSnapshot.data();
            if(challenge) {
                if (challenge.status === NETWORK_CHALLENGE__STATUS.ACCEPTED) {
                    // закрываем попап
                    StoreApp.hidePopupMessage();
                    StoreGame.setTemporaryDoNotFocusGame(false);
                    StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);

                    // отписываемся от челенджа
                    StoreApp.firestoreUnsubscribe(challengePath);
                    StoreApp.removeWaitingChallenge(newChallenge.id);

                    // отправляем юзера в игру
                    connectExistedGame(challenge.gameId);
                    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);

                    try {
                        StoreApp.stvAppConnector.sendKibana(KIBANA_EVENT_TYPE.GAME_START, {
                            lang: challenge.lang,
                            username: StoreUser.megogoUserName,
                            game_mode: challenge.type === NETWORK_CHALLENGE__TYPE.WITH_FRIEND ? GAME_MODE.NETWORK__WITH_FRIEND : GAME_MODE.NETWORK__RANDOM
                        });
                    } catch(e) {}
                } else if (challenge.status === NETWORK_CHALLENGE__STATUS.REJECTED) {
                    // закрываем попап с кодом
                    StoreApp.hidePopupMessage();
                    StoreGame.setTemporaryDoNotFocusGame(false);
                    StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);

                    // отписываемся от челенджа
                    StoreApp.firestoreUnsubscribe(challengePath);
                    StoreApp.removeWaitingChallenge(newChallenge.id);

                    // показываем алерт о том что игра была отменеа
                    showAlert(i18n.networkChallenge.rejected);
                }
            }
        });
    }

    createFriendNetworkGame = () => {
        StoreGameTimeLimitsPopup.askUserTimeLimitsAndContinue(GAME_MODE.NETWORK__WITH_FRIEND, (limits) => {
            StoreApp.setLoaderBigVisible(true);
            StoreApp.disableUI();
            remoteRequest('createFriendNetworkGame', {
                lang: StoreApp.dictionarySetting,
                limits: limits,
                user: {
                    id: StoreUser.id,
                    name: StoreUser.user.name
                }
            }).then(result => {
                StoreApp.setLoaderBigVisible(false);

                if (result.data.status === RESPONSE__SUCCESS) {

                    this._waitForOpponent(result.data.message.newChallenge);

                } else if (result.data.status === RESPONSE__ERROR) {

                    showAlert(getErrorText(result.data.code));

                } else {
                    throw "createFriendNetworkGame unknown response";
                }

            }).catch(function (err) {
                showAlert(i18n.alertConnectionError);
            }).finally(() => {
                StoreApp.enableUI();
            });
        });
    }

    acceptFriendNetworkGame = () => {
        showPrompt(i18n.networkChallenge.enterCode, (val) => {
            StoreApp.setLoaderBigVisible(true);
            StoreApp.disableUI();
            remoteRequest('makeDecisionToFriendNetworkGame', {
                challengeInfo: {code: val},
                decision: NETWORK_CHALLENGE__STATUS.ACCEPTED,
                user: {
                    id: StoreUser.id,
                    name: StoreUser.user.name
                }
            }).then(function (res) {
                StoreApp.setLoaderBigVisible(false);
                if(res.data.status === RESPONSE__SUCCESS) {
                    let gameId = res.data.message.newGameId;
                    connectExistedGame(gameId);
                    StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
                } else if(res.data.status === RESPONSE__ERROR){
                    showAlert(getErrorText(res.data.code));
                } else {
                    throw "acceptFriendNetworkGame unknown response";
                }
            }).catch(function(err) {
                showAlert(i18n.alertConnectionError);
            }).finally(function () {
                StoreApp.setLoaderBigVisible(false);
                StoreApp.enableUI();
            });
        });
        return;

    }

    rejectFriendNetworkGame = (id) => {
        remoteRequest('makeDecisionToFriendNetworkGame', {
            challengeInfo: {id: id},
            decision: NETWORK_CHALLENGE__STATUS.REJECTED,
        })
    }

    randomNetworkGame = () => {

        StoreGameTimeLimitsPopup.askUserTimeLimitsAndContinue(GAME_MODE.NETWORK__RANDOM, (limits) => {
            StoreApp.setLoaderBigVisible(true);
            StoreApp.disableUI();

            remoteRequest('connectExistOrCreateNewRandomChallenge', {
                lang: StoreApp.dictionarySetting,
                limits: limits,
                user: {
                    id: StoreUser.id,
                    name: StoreUser.user.name
                }
            })
                .then(result => {
                    if(result.data.status === RESPONSE__ERROR) {
                        showAlert(getErrorText(result.data.code));
                    } else if(result.data.message.newChallenge) {

                        this._waitForOpponent(result.data.message.newChallenge);

                    } else if(result.data.message.newGameId) {
                        connectExistedGame(result.data.message.newGameId);
                        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__GAME);
                    }
                })
                .catch(e => {

                })
                .finally(() => {
                    StoreApp.setLoaderBigVisible(false);
                    StoreApp.enableUI();
                });
        })

    }

    render() {

        if(!StoreUser.user) return null;

        let ongoingGgames = [], finishedGames = [];
        const now = new Date();
        Object.keys(StoreUser.user.networkGames).forEach(gameDocId => {

            // если это завершенная и заэкспайренная игра - не показываем ее (она будет стерта на беке)
            if(StoreUser.user.networkGames[gameDocId].status === GAME_STATUS__FINISHED &&
                StoreUser.user.networkGames[gameDocId].expiredTime.seconds < now.getTime() / 1000)
                return;

            const networkGame = StoreUser.user.networkGames[gameDocId];
            const g = {
                id: gameDocId,
                youTurn: networkGame.status === GAME_STATUS__WAITING_MOVE_BOTH ||
                    networkGame.status === GAME_STATUS__WAITING_MOVE_0 && networkGame.user.player === 1 ||
                    networkGame.status === GAME_STATUS__WAITING_MOVE_1 && networkGame.user.player === 0,
                data: networkGame,
            };

            // собираем отдельно актуальные и оконченные игры, чтобы правильно отсортировать
            if(StoreUser.user.networkGames[gameDocId].status === GAME_STATUS__FINISHED)
                finishedGames.push(g);
            else
                ongoingGgames.push(g);
        });

        function sortFunc(a, b) {
            if(a.youTurn && !b.youTurn) return -1;
            if(!a.youTurn && b.youTurn) return 1;
            return a.data.expiredTime.seconds - b.data.expiredTime.seconds
        }

        ongoingGgames.sort(sortFunc);
        finishedGames.sort(sortFunc);

        const allGames = ongoingGgames.concat(finishedGames);
        let gameItems = [];
        for(let i=0;i<allGames.length;i++) {
            if(!allGames[i].data.user)  continue;
            gameItems.push(<NMenuGameItem
                networkGame={allGames[i]}
                onFocused={this.onGameItemFocused}
                index={i}
                // айтем не должен фокусится, если выходит за пределы области видимости
                unfocusable={i < this.state.visibleMin-1 || i > this.state.visibleMax+1}
                key={allGames[i].id}
                focusStore={StoreFocus}
                focusLayers={[FOCUS_LAYER__NMENU]}
            />);
        }

        return (
            <div className="nmenu">
                <div className="buttons">
                    <div className="btn-wr">
                        <NMenuBtn defaultFocused={true}
                                  btnClass="random"
                                  onClick={this.randomNetworkGame}
                                  focusStore={StoreFocus}
                                  focusLayers={[FOCUS_LAYER__NMENU]}/>
                        <div className="text" dangerouslySetInnerHTML={{__html: i18n.networkButtonsDesc.random}}></div>
                    </div>
                    <div className="btn-wr">
                        <NMenuBtn btnClass="with-friend"
                                  onClick={this.createFriendNetworkGame}
                                  focusStore={StoreFocus}
                                  focusLayers={[FOCUS_LAYER__NMENU]}/>
                        <div className="text" dangerouslySetInnerHTML={{__html: i18n.networkButtonsDesc.withFriend}}></div>
                    </div>
                    <div className="btn-wr">
                        <NMenuBtn btnClass="connect"
                                  onClick={this.acceptFriendNetworkGame}
                                  focusStore={StoreFocus}
                                  focusLayers={[FOCUS_LAYER__NMENU]}/>
                        <div className="text" dangerouslySetInnerHTML={{__html: i18n.networkButtonsDesc.connect}}></div>
                    </div>
                </div>
                <Achieves focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__NMENU]}/>
                <div className="games">
                    {gameItems.length ?
                        <div>
                            <div className="title">{i18n.networkGamesTitle}</div>
                            <div className="list-wr">
                                <ul style={{top: this.state.gamesItemsScroll+"rem"}}>
                                    {gameItems}
                                </ul>
                            </div>
                        </div>
                        :
                        <div className="text" dangerouslySetInnerHTML={{__html: i18n.networkGamesText}}></div>
                    }


                </div>
            </div>
        );
    }
})

export default NMenu;
