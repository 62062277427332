import React from "react";
import {observer} from "mobx-react";
import i18n from "../common/i18n";
import StoreChat from "../store/StoreChat";
import {getRightPlayerI} from "../common/utils";

const PlayerScore = observer(class PlayerScore extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            score: 0
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        //console.log('render PlayerScore');

        let rightPlayerI = getRightPlayerI(this.props.playerI);
        let showMessage = StoreChat.lastMessagesVisible[rightPlayerI];
        let messageText = showMessage ? i18n.chat_messages[StoreChat.lastMessages[rightPlayerI].fraze] : "";

        return (
            <div className="score">
                <div className={"message" + (showMessage ? " show" : "")}>{messageText}</div>
                {this.props.score}

            </div>
        )
    }
});

export default PlayerScore;