import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import {
    BADGES,
    FOCUS_LAYER__POPUP_MESSAGE
} from "../../common/const";
import StoreApp from "../../store/StoreApp";
import StoreFocus from "../../focus/StoreFocus";

import BadgePopupMessageBtn from "./BadgePopupMessageBtn";
import {FOCUS_LAYER_DEFAULT_FOCUS} from "../../focus/StoreFocusBase";
import i18n from "../../common/i18n";
import {__} from "../../common/utils";
import StoreGame from "../../store/StoreGame";
import StoreSounds from "../../store/StoreSounds";

const BadgePopupMessage = observer(class PopupMessage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentBadgeIndex: 0
        }
    }

    lastBadge() {
        return this.state.currentBadgeIndex === StoreApp.badgePopupMessage.badges.length-1
    }

    componentDidMount() {
        StoreSounds.playPls("new-badge");
        StoreFocus.setCurrentFocusLayer(FOCUS_LAYER__POPUP_MESSAGE);
    }

    click = () => {

        if(this.lastBadge()) {
            StoreApp.hideBadgePopupMessage();
            StoreGame.setTemporaryDoNotFocusGame(false);
            StoreFocus.setCurrentFocusLayer(null, FOCUS_LAYER_DEFAULT_FOCUS.SAVED);
        }
        else {
            this.setState({
                currentBadgeIndex: this.state.currentBadgeIndex + 1
            });
        }
    }


    render() {
        console.log('render BadgePopupMessage');

        if(StoreApp.badgePopupMessage === null)  return null;

        const badgeData = StoreApp.badgePopupMessage.badges[this.state.currentBadgeIndex];
        const badgeInfo = i18n.badges[badgeData.id];
        const badgeIcon = BADGES[badgeData.id].icon;
        const otherBadgesCount = StoreApp.badgePopupMessage.badges.length - 1 - this.state.currentBadgeIndex;

        const i18nKey = badgeData.count === 1 ? "badges_popup_new" : "badges_popup_more";
        console.log(i18nKey, badgeData.count);
        const title = __(i18n[i18nKey].title, {title: badgeInfo.title, description: badgeInfo.description});
        const description = __(i18n[i18nKey].text, {title: badgeInfo.title, description: badgeInfo.description});

        return (
            <div>
                <div className="badge-popup-message-bg"></div>
                <div className="badge-popup-message">
                    <div className="icon" style={{background: "url(/img/badges/2x/"+badgeIcon+")", backgroundSize: "cover"}}></div>
                    <div className="title">{title}</div>
                    <div className="description" dangerouslySetInnerHTML={{__html: description}}></div>
                    <BadgePopupMessageBtn callback={this.click} text={this.lastBadge() ? i18n.ok : i18n.more + " " + otherBadgesCount} focusStore={StoreFocus} focusLayers={[FOCUS_LAYER__POPUP_MESSAGE]}/>
                </div>
            </div>


        );
    }
})

export default BadgePopupMessage;