import React from "react";
import {observer} from "mobx-react";
import StoreGame from "../store/StoreGame";
import StoreApp from "../store/StoreApp";

const Loader = observer(class Loader extends React.Component {

    render() {
        //console.log('render Loader');

        if(!StoreApp.loaderVisible)
            return null;

        return (
            <div className="loader corner">
                <div className="loadingio-spinner-dual-ball">
                    <div className="ldio">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
})

export default Loader;