import React from "react";
import {observer} from "mobx-react";
import "./index.css";
import StoreFocus from "../../focus/StoreFocus";
import {
    FOCUS_LAYER__POPUP_MESSAGE
} from "../../common/const";
import focusable from "../../focus/Focusable";
import StoreApp from "../../store/StoreApp";
import StoreSounds from "../../store/StoreSounds";

const PopupMessageBtn = focusable(observer(class PopupMessageBtn extends React.Component {

    onKeydown = (e) => {
        if( e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.RETURN ||
            e.keyCode === StoreApp.stvAppConnector.parentAppData.keys.HID_BACK) {
            this.props.callback();
        }
    }

    componentDidMount() {
        if(this.props.onBack)
            document.addEventListener('keydown', this.onKeydown);
    }

    componentWillUnmount() {
        if(this.props.onBack)
            document.removeEventListener('keydown', this.onKeydown);
    }


    defaultFocused() {
        return this.props.defaultFocused;
    }

    render() {

        let className = "btn focusable btn-"+this.props.btnCount+"-"+this.props.btnOrder;


        className += this.props.focused ? " focused" : "";

        return (
            <div className={className} onClick={() => {StoreSounds.playPls('click'); this.props.callback()}}>{this.props.text}</div>
        );
    }
}))

export default PopupMessageBtn;